<template>
  <div class="info__banner">
    <div class="title">{{ title }}</div>
    <div class="value">{{ value === 'Скоро' ? value : Math.floor(+value).toLocaleString('ru') }}</div>
    <div class="row__container">
      <span
        v-if="profit !== '' && title !== 'Остатки, шт'"
        class="profit"
        :class="+profit > 0 ? 'green__value' : +profit === 0 ? 'grey__value' : 'red__value'"
      >
        {{ formatProfit }}%
      </span>
      <div class="info__icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
        <x-icon name="info" size="xs"></x-icon>
        <tooltip ref="tooltip">
          <div class="info__icon__text">{{ text }}</div>
        </tooltip>
      </div>
    </div>
    <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
  </div>
</template>

<style lang="stylus" scoped>
.info__banner
  position relative
  padding 18px
  box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
  border-radius 8px
  border 1px solid $colors.gray-light
  background #fff

.info__banner .x-loader--overlay
  border-radius 8px

.tooltip
  bottom 28px !important

.title
  color $colors.gray-darkest-2
  font-size $font-size-s
  line-height 18px
  margin-bottom 8px

.value
  color $colors.gray-darkest-2
  font-size $font-size-xxl
  line-height  29px
  margin-bottom 3px
  font-weight bold
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.row__container
  display flex
  align-items center

.profit
  font-size $font-size-l
  line-height 18px
  font-weight bold
  margin-right 4px
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.green__value
  color $colors.success

.red__value
  color $colors.error

.grey__value
  color $colors.gray-dark

.info__icon
  position relative
  height 16px
  width 16px
  transform translateY(-1px)
  color: $colors.gray
  z-index 1
  // &:hover
  //   .tooltip
  //     visibility visible
  //     opacity 1

.info__icon__text
  white-space nowrap

.x-loader--overlay
  z-index 5
</style>

<script>
export default {
  props: {
    title: {
      type: String
    },
    value: {
      type: [Number, String]
    },
    profit: {
      type: [Number, String]
    },
    preloader: {
      type: Boolean,
      default: false
    },
    text: {
      type: String
    }
  },
  computed: {
    formatProfit() {
      return this.profit > 0 ? `+${this.profit.toLocaleString('ru')}` : this.profit.toLocaleString('ru')
    }
  },
  methods: {
    showTooltip() {
      const tooltip = this.$refs.tooltip.$el
      const tooltipLeftPos = tooltip.getBoundingClientRect().left
      const pageLeftPos = document.querySelector('.x-card').getBoundingClientRect().left
      const tooltipRightPos = tooltip.getBoundingClientRect().right
      const pageRightPos = document.querySelector('.x-card').getBoundingClientRect().right

      if (this.$el.classList.contains('tooltip__slightly__right')) {
        if (tooltipLeftPos < pageLeftPos) tooltip.classList.add('tooltip__not__match__right')
      } else {
        if (tooltipLeftPos < pageLeftPos) tooltip.classList.add('tooltip__adaptive_left')
      }

      if (tooltipRightPos > pageRightPos) tooltip.classList.add('tooltip__adaptive_right')

      tooltip.classList.add('tooltip_active')
    },
    hideTooltip() {
      const tooltip = this.$refs.tooltip.$el
      tooltip.classList.remove('tooltip__not__match__right')
      tooltip.classList.remove('tooltip__adaptive_left')
      tooltip.classList.remove('tooltip__adaptive_right')
      this.$refs.tooltip.$el.classList.remove('tooltip_active')
    }
  }
}
</script>
