<template>
  <x-page title="Бренды" card>
    <div class="page__brands">
      <div class="tabs__container">
        <x-tabs :tabs="marketplacesTabs" size="l" @change="selectTab" />
      </div>
      <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
        <div class="row__item">
          <x-label :size="'xs'">Категория</x-label>
          <div :class="{ disabled: preloader }" @click="categoriesModal = true">
            <category-button v-model="filters.category" />
          </div>
        </div>
        <div class="row__item">
          <x-label :size="'xs'">Показать</x-label>
          <date-select
            :preloader="preloader"
            :period-action="defaultPickerAction"
            :period="filters.period"
            @selectPeriod="onSelectPeriod"
          ></date-select>
          <!-- <div :class="{ disabled: preloader }" @click="$refs.picker.$modal.show('datepicker-modal')">
            <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
          </div> -->
        </div>
        <div class="row__item">
          <x-label :size="'xs'">Поиск</x-label>
          <x-input v-model.trim="searchValue" prepend-icon="search" placeholder="По наименованию" />
        </div>
        <div class="row__item"></div>
        <div class="row__item">
          <div class="label__fake"></div>
          <fbs-switcher v-model="filters.fbs" :disabled="preloader" @input="sendEvent" />
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <filters-button
            :opened="isOpened"
            :changes="slidersChanged"
            @click.native="openFiltersToggle"
            @resetFilters="resetRangeFilters"
          />
        </div>
      </div>
      <div class="filters__range" :class="{ disabled: !isPermitByTariff }">
        <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
          <div class="range__grid">
            <range-slider
              v-for="item in rangesFilters"
              :key="item.key"
              :label="item.label"
              :filter-key="item.key"
              :disabled="item.disabled || preloader"
              :max="item.max"
              :value="item.value"
              @rangefilterchanged="onRangeFiltersValue"
            />
            <div class="apply__button__row__item">
              <div class="apply__button__row">
                <div class="label__fake"></div>
                <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
                  Применить
                </x-btn>
              </div>
            </div>
          </div>
          <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
        </div>
        <!-- <page-hint>
          <p class="page__hint__text">
            Вы узнаете всё о продажах любого продавца!<br />Найдёте ТОП-лидеров продаж в любой категории, оцените объемы и
            стратегии продаж своих конкурентов, а также узнаете какие товары не пользуются спросом.
          </p>
        </page-hint> -->
      </div>
      <div v-if="isPermitByTariff" class="tracked__container">
        <div class="total">
          Всего: <span>{{ brandsData.total.toLocaleString('ru') }}</span>
        </div>
        <!-- <div class="tracked">
          <Switcher v-model="filters.favoritesOnly" :disabled="preloader || !brandsData.data.length" />
          <div class="tracked__label">Только отслеживаемые: 1</div>
        </div> -->
        <selected-period :period="filters.period"></selected-period>
        <div class="download__btn__container">
          <!-- <x-btn
            class="download__btn"
            color="gray"
            :disabled="preloader || !brandsData.data.length || downloadPreloader"
            @click="downloadXLSX"
          >
            <x-icon class="download__icon" name="download" size="s"></x-icon>
            Скачать в XLSX
            <x-loader v-if="downloadPreloader" size="xs"></x-loader>
          </x-btn> -->
          <tooltip class="arrow__offset">Максимальный размер скачивания 1 000 строк.</tooltip>
        </div>
      </div>
      <!-- :prev-page-name="'Бренды'" -->
      <brands-list
        :brands-data="brandsData"
        :preloader="preloader"
        :code="filters.code"
        :sort-options="sortOptions"
        :period="filters.period"
        :fbs="filters.fbs"
        :category="filters.category"
        :default-picker-action="defaultPickerAction"
        @sort="sortList"
      >
      </brands-list>
      <div class="row__container">
        <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" />
      </div>
      <transition name="fade" mode="out-in" appear>
        <keep-alive>
          <x-categories-popup
            v-if="categoriesModal"
            v-model="filters.category"
            key-id="external_id"
            key-name="name"
            :code="filters.code"
            @close="categoriesModal = false"
          />
        </keep-alive>
      </transition>
    </div>
  </x-page>
</template>

<style lang="stylus" scoped>
.disabled
  pointer-events none
  opacity .5

.page__brands
  display flex
  flex-direction column
  flex-grow 1

.x-label
  pointer-events none

.grid__row
  display grid
  grid-template-columns repeat(2, calc(25% - 9px)) 1fr auto auto
  grid-auto-rows auto
  grid-gap 12px
  margin-bottom 12px

.row__item
  &:nth-child(4)
    display none

@media (max-width 1280px)
  .grid__row
    grid-template-columns repeat(3, minmax(254px, 1fr))

  .row__item:last-of-type
    grid-column span 2
    display flex
    flex-direction column
    align-items flex-end

@media (max-width 1470px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .row__item:last-of-type
    grid-column span 2
    display flex
    flex-direction column
    align-items flex-end

@media (max-width 1050px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed  .row__item
    &:nth-child(4)
      display block

  .x-content--collapsed  .row__item:last-of-type
    grid-column span 1

@media (max-width 900px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

  .x-content--collapsed  .row__item
    &:nth-child(4)
      display none

  .x-content--collapsed  .row__item:last-of-type
    align-items start
    grid-column span 1

@media (max-width 992px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

  .row__item
    &:nth-child(4)
      display block

  .row__item:last-of-type
    grid-column span 1

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

  .row__item:nth-child(4)
    display none

  .row__item:last-of-type
    align-items: start

.label__fake
  margin-bottom 7px
  height 14px

.filters__range
  border-bottom 1px solid $colors.gray-light

.filters__range__animate
  position relative
  overflow hidden
  transition .3s

.range__grid
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 12px
  padding 0 2px 12px

.apply__button__row__item
  grid-column span 2
  display flex

@media (max-width 1470px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 3

@media (max-width 1200px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 2

@media (max-width 992px)
  .x-content--collapsed .range__grid
    grid-template-columns 1fr

  .x-content--collapsed .apply__button__row__item
    grid-column span 1

@media (max-width 1280px)
  .range__grid
    grid-template-columns repeat(3, 1fr)

  .apply__button__row__item
    grid-column span 3

@media (max-width 992px)
  .range__grid
    grid-template-columns repeat(2, 1fr)

  .apply__button__row__item
    grid-column span 2

@media (max-width 768px)
  .range__grid
    grid-template-columns 1fr

  .apply__button__row__item
    grid-column span 1

.apply__button__row
  margin-left auto

.apply__button
  width 120px

.x-loader--overlay
  z-index 10

.tracked__container
  display flex
  align-items center
  height 36px
  margin 12px 0

.total
  border-right 1px solid #ededed
  padding-right 12px
  height 100%
  font-size $font-size-s
  color $colors.gray-darkest-2
  line-height 36px
  & span
    font-weight bold

.tracked
  display flex
  align-items center
  padding-left 16px

.tracked__label
  color $colors.gray-darkest-1
  font-size $font-size-s
  margin-left 4px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn__container
  position relative
  margin-left auto
  &:hover
    .tooltip
      visibility visible
      opacity 1

.download__btn__container .tooltip
  left -7px
  bottom 46px
  white-space nowrap

.download__btn
  position relative
  &:disabled .download__icon
    color inherit

.x-btn:disabled + .tooltip
  visibility hidden
  opacity 0

.download__btn .x-loader
  transform translateX(6px)

.limit__dropdown
  width 56px
  margin-right 8px

.row__container
  display flex
  align-items center

.disabled
  pointer-events none
  opacity .5
</style>

<script>
import filtersToggle from '@/mixins/filtersToggle.js'
import permissions from '@/mixins/permissions.js'
import BrandsList from '@/components/BrandsList.vue'
import changeDocumentTitle from '@/mixins/changeDocumentTitle.js'
import { getBrandsRangeFiltersMaxValue, getBrandsList } from '@/api/brands/index.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

const start = new Date()
const end = new Date()
start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [changeDocumentTitle, filtersToggle, permissions],
  components: {
    BrandsList
  },
  data() {
    return {
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      rangePreloader: false,
      preloader: false,
      downloadPreloader: false,
      searchValue: this.$route.query.name || '',
      searchTimeout: null,
      categoriesModal: false,
      brandsData: {
        data: [],
        total: 0
      },
      filters: {
        code: this.$route.query.mp_code || 'wb',
        category: {
          external_id: this.$route.query.category_id || '',
          name: this.$route.query.category_name || ''
        },
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        search: this.$route.query.name || '',
        favoritesOnly: this.$route.query.favorites_only === 'true',
        fbs: this.$route.query.is_fbs === 'true'
      },
      defaultFilters: {},
      rangeFiltersPrevVal: {},
      rangesFilters: {
        products: {
          label: 'Товары',
          key: 'products',
          value: [+this.$route.query.products_cnt_from || 0, +this.$route.query.products_cnt_to || 0],
          max: +this.$route.query.products_max || 0,
          disabled: !+this.$route.query.products_max
        },
        sellers: {
          label: 'Продавцы',
          key: 'sellers',
          value: [+this.$route.query.sellers_cnt_from || 0, +this.$route.query.sellers_cnt_to || 0],
          max: +this.$route.query.sellers_max || 0,
          disabled: !+this.$route.query.sellers_max
        },
        cheque: {
          label: 'Средний чек, руб',
          key: 'cheque',
          value: [+this.$route.query.avg_check_from || 0, +this.$route.query.avg_check_to || 0],
          max: +this.$route.query.cheque_max || 0,
          disabled: !+this.$route.query.cheque_max
        },
        sales: {
          label: 'Кол-во продаж, шт',
          key: 'sales',
          value: [+this.$route.query.sales_cnt_from || 0, +this.$route.query.sales_cnt_to || 0],
          max: +this.$route.query.sales_max || 0,
          disabled: !+this.$route.query.sales_max
        },
        remains: {
          label: 'Остатки, шт',
          key: 'remains',
          value: [+this.$route.query.quantity_cnt_from || 0, +this.$route.query.quantity_cnt_to || 0],
          max: +this.$route.query.remains_max || 0,
          disabled: !+this.$route.query.remains_max
        },
        revenue: {
          label: 'Выручка, руб',
          key: 'revenue',
          value: [+this.$route.query.proceeds_cnt_from || 0, +this.$route.query.proceeds_cnt_to || 0],
          max: +this.$route.query.revenue_max || 0,
          disabled: !+this.$route.query.revenue_max
        }
        // lostRevenue: {
        //   label: 'Упущенная выручка, руб',
        //   key: 'lostRevenue',
        //   value: [0, 0],
        //   max: 0,
        //   disabled: true
        // }
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: +this.$route.query.limit || 10,
        offset: 0,
        total: 0,
        page: +this.$route.query.page || 1
      }
    }
  },
  watch: {
    filters: {
      handler(filters) {
        this.paginationOptions.page = 1
        this.loadSliders(filters)
      },
      deep: true
    },
    searchValue(val) {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        if (val.length > 2 || !val.length) this.filters.search = val
      }, 700)
    },
    paginationOptions() {
      this.setUrlParams()
      this.loadBrands({ ...this.filters, sortOptions: this.sortOptions, ...this.rangeFiltersPrevVal, ...this.paginationOptions })
    }
  },
  computed: {
    ...mapState(['marketplacesTabs']),
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
  },
  created() {
    this.saveDefaultRangeFilters()
    if (this.isPermitByTariff) {
      this.saveRangeFiltersValues()

      if (!this.rangesFilters.products.max) {
        this.loadSliders({ ...this.filters, search: this.searchValue })
      } else {
        this.rangePreloader = false
        this.loadBrands({ ...this.filters, ...this.rangesFilters, sortOptions: this.sortOptions, ...this.paginationOptions })
      }
    }
  },
  activated() {
    setTimeout(() => {
      const meniItem = document.querySelector('.x-nav-item--active')
      if (meniItem) meniItem.classList.add('disabled__item')
    }, 500)
    this.setUrlParams()

    this.$metrics.hit('orm_brands_view')
  },
  deactivated() {
    document.querySelector('.x-nav-item--active').classList.remove('disabled__item')
  },
  methods: {
    sendEvent() {
      this.$metrics.hit(this.filters.fbs ? 'orm_brands_fbs' : 'orm_brands_fbo', {
        marketplace_code: this.filters.code
      })
    },
    selectTab(idx) {
      this.filters.code = this.marketplacesTabs[idx].mp_code
    },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )
      this.filters.period = { ...this.filters.period, start: currentDate, end }
      this.defaultPickerAction = val.id
    },
    saveDefaultRangeFilters() {
      this.defaultFilters = {
        ...this.rangesFilters,
        ...JSON.parse(
          JSON.stringify({
            products: {
              label: 'Товары',
              key: 'products',
              value: [0, this.rangesFilters.products.max],
              max: this.rangesFilters.products.max,
              disabled: this.rangesFilters.products.max === 0
            },
            sellers: {
              label: 'Продавцы',
              key: 'sellers',
              value: [0, this.rangesFilters.sellers.max],
              max: this.rangesFilters.sellers.max,
              disabled: this.rangesFilters.sellers.max === 0
            },
            cheque: {
              label: 'Средний чек, руб',
              key: 'cheque',
              value: [0, this.rangesFilters.cheque.max],
              max: this.rangesFilters.cheque.max,
              disabled: this.rangesFilters.cheque.max === 0
            },
            sales: {
              label: 'Кол-во продаж, шт',
              key: 'sales',
              value: [0, this.rangesFilters.sales.max],
              max: this.rangesFilters.sales.max,
              disabled: this.rangesFilters.sales.max === 0
            },
            remains: {
              label: 'Остатки, шт',
              key: 'remains',
              value: [0, this.rangesFilters.remains.max],
              max: this.rangesFilters.remains.max,
              disabled: this.rangesFilters.remains.max === 0
            },
            revenue: {
              label: 'Выручка, руб',
              key: 'revenue',
              value: [0, this.rangesFilters.revenue.max],
              max: this.rangesFilters.revenue.max,
              disabled: this.rangesFilters.revenue.max === 0
            }
          })
        )
      }
    },
    saveRangeFiltersValues() {
      this.rangeFiltersPrevVal = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    applyRangeFilters() {
      this.saveRangeFiltersValues()
      this.paginationOptions.page = 1
      this.setUrlParams()
      this.loadBrands({ ...this.filters, ...this.rangesFilters, sortOptions: this.sortOptions, ...this.paginationOptions })
    },
    resetRangeFilters() {
      this.rangesFilters = JSON.parse(JSON.stringify(this.defaultFilters))
    },
    onRangeFiltersValue({ val, key }) {
      this.rangesFilters[key].value = val
    },
    sortList(titleId) {
      if (this.sortOptions.id === titleId) {
        this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
      } else {
        this.sortOptions.direction = '1'
      }
      this.sortOptions.id = titleId
      this.setUrlParams()
      this.loadBrands({ ...this.filters, ...this.rangeFiltersPrevVal, sortOptions: this.sortOptions, ...this.paginationOptions })
    },
    async loadSliders(filters) {
      this.preloader = true
      this.rangePreloader = true

      try {
        const { data } = await this.$axios.get(...getBrandsRangeFiltersMaxValue(filters))
        const res = data.data

        if (res) {
          for (const k in res) res[k] = Math.ceil(res[k])
        }

        this.rangesFilters.products.value = [0, res.products_max]
        this.rangesFilters.products.disabled = res.products_max === 0

        this.rangesFilters.sellers.value = [0, res.sellers_max]
        this.rangesFilters.sellers.disabled = res.sellers_max === 0

        this.rangesFilters.cheque.value = [0, res.avg_check_max]
        this.rangesFilters.cheque.disabled = res.avg_check_max === 0

        this.rangesFilters.sales.value = [0, res.sales_max]
        this.rangesFilters.sales.disabled = res.sales_max === 0

        this.rangesFilters.remains.value = [0, res.quantity_max]
        this.rangesFilters.remains.disabled = res.quantity_max === 0

        this.rangesFilters.revenue.value = [0, res.proceeds_max]
        this.rangesFilters.revenue.disabled = res.proceeds_max === 0

        // this.rangesFilters.lostRevenue.value = [0, res.lost_proceeds_max]
        // this.rangesFilters.lostRevenue.disabled = true

        setTimeout(() => {
          this.rangesFilters.products.max = res.products_max
          this.rangesFilters.sellers.max = res.sellers_max
          this.rangesFilters.cheque.max = res.avg_check_max
          this.rangesFilters.sales.max = res.sales_max
          this.rangesFilters.remains.max = res.quantity_max
          this.rangesFilters.revenue.max = res.proceeds_max
          // this.rangesFilters.lostRevenue.max = Math.ceil(res.lost_proceeds_max)
          this.saveDefaultRangeFilters()
          this.saveRangeFiltersValues()
          this.loadBrands({
            ...this.filters,
            search: this.searchValue,
            ...this.rangesFilters,
            sortOptions: this.sortOptions,
            ...this.paginationOptions
          })
          this.setUrlParams()
        })
      } catch (err) {
        this.preloader = false
        console.log(err)
      } finally {
        this.rangePreloader = false
      }
    },
    async loadBrands(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getBrandsList(filters))
        this.brandsData.data = data.data || []
        this.brandsData.total = data.paging?.total || 0

        this.paginationOptions.total = data.paging?.total
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    // downloadXLSX() {}
    setUrlParams() {
      if (this.$route.name === 'brands') {
        this.$router
          .replace(
            `?${this.filters.category.name ? `category_name=${this.filters.category.name}` : ''}&default_picker_action=${
              this.defaultPickerAction
            }&${getBrandsList({
              ...this.filters,
              ...this.rangesFilters,
              sortOptions: this.sortOptions,
              ...this.paginationOptions
            })[1].params.toString()}`
          )
          .catch(() => {})
      }
    }
  }
}
</script>
