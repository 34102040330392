import axios from 'axios'

const token = document.cookie.split(';').find(el => /xw_token=\w/.test(el))?.split('=')[1]

const sellerInstance = axios.create({
  baseURL: process.env.VUE_APP_SELLER_API,
  headers: {
    'x-xway-auth': token
  }
})

export const sellerApi = {
  orderRecommendations (page) {
    const url = 'seller_cabinet/v1/sme_click_order'
    return sellerInstance.post(url, { page })
  },
  getUser () {
    return sellerInstance.get('seller_cabinet/v1/me/')
  },
  initTinkoffPayment ({
    amount,
    // eslint-disable-next-line camelcase
    tariff_id,
    term
  }) {
    const url = '/api/v1/payment/tinkoff/init/'
    return sellerInstance.post(url, {
      amount,
      tariff_id,
      term,
      is_recurrent: true
    })
  }
}
