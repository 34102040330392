import CalendarPeriods from './calendar-periods'

export default [{
  label: '7 дней',
  value: CalendarPeriods.SEVEN
}, {
  label: '14 дней',
  value: CalendarPeriods.FOURTEEN
},
{
  label: '30 дней',
  value: CalendarPeriods.THIRTY
},
//   {label: '90 дней',
//   value: CalendarPeriods.NINETY
// },
//  {
//   label: 'вчера',
//   value: CalendarPeriods.YESTERDAY
// }, {
//   label: 'сегодня',
//   value: CalendarPeriods.TODAY
// }, {
//   label: 'текущая неделя',
//   value: CalendarPeriods.CURRENT_WEEK
// }, {
//   label: 'прошлая неделя',
//   value: CalendarPeriods.LAST_WEEK
// }, {
//   label: 'текущий месяц',
//   value: CalendarPeriods.CURRENT_MONTH
// }, {
//   label: 'прошедший месяц',
//   value: CalendarPeriods.LAST_MONTH
// }, {
//   label: 'текущий квартал',
//   value: CalendarPeriods.CURRENT_QUARTER
// }, {
//   label: 'прошедший квартал',
//   value: CalendarPeriods.LAST_QUARTER
// }, {
//   label: 'текущий год',
//   value: CalendarPeriods.CURRENT_YEAR
// }, {
//   label: 'прошедший год',
//   value: CalendarPeriods.LAST_YEAR
// },
{
  label: 'Другой период',
  value: CalendarPeriods.OTHER
}
]
