<template>
  <x-page ref="XPage" card :breadcrumbs="[{ to: '/categories', text: 'Категории' }]">
    <!-- :class="{ disabled: isLoading }" -->
    <!-- show-back-btn -->
    <template #title>
      <div class="title__row">
        <div class="back__icon" @click="toPrevPage">
          <x-icon :name="'chevron-left'" size="s" />
        </div>
        <x-icon :name="marketPlaceValues[filters.code].icon" />
        <page-title>{{ $route.query.title }}</page-title>
      </div>
    </template>
    <div class="tabs" :class="{ disabled: isLoading || !isPermitByTariff }">
      <x-tabs :tabs="tabs" size="l" :selected="tabs.findIndex(el => el.id === pageState)" @change="selectTab" />
    </div>
    <template v-if="getCondition">
      <keep-alive>
        <component
          :is="pageState"
          :filters="filters"
          :default-picker-action="defaultPickerAction"
          @loadingStarted="isLoading = true"
          @loadingFinished="isLoading = false"
          @periodChanged="onSelectPeriod"
          @switchFbs="switchFbs"
        ></component>
      </keep-alive>
    </template>
    <div v-else class="plug">
      <x-placeholder title="Доступно для VIP пользователей" icon="table-vip">
        <template #desc>
          Для получения доступа пишите <br />на <x-link href="mailto:support@xway.ru" primary>support@xway.ru</x-link>
        </template>
        <template #bottom>
          <x-btn>Подключить VIP опции</x-btn>
        </template>
      </x-placeholder>
    </div>
  </x-page>
</template>

<style lang="stylus" scoped>
.back__icon
  color: $colors.gray-dark
  width 36px
  height 36px
  border-radius 5px
  display flex
  align-items center
  justify-content center
  cursor pointer
  transition .2s
  margin-right 12px
  flex-shrink 0
  &:hover
    color: $colors.gray-darkest-2
    background $colors.gray-lighter

.title__row
  display flex
  align-items center
  width 100%

.x-icon--m
  margin-right 4px
  flex-shrink 0

.plug
  position relative
  height 100%
  margin-top -20px

.disabled
  pointer-events none
</style>

<script>
import toBackPage from '@/mixins/toBackPage.js'
import permissions from '@/mixins/permissions.js'
import CategoryProducts from './CategoryProducts.vue'
import CategorySubcategories from './CategorySubcategories.vue'
import CategorySellers from './CategorySellers.vue'
import CategoryBrands from './CategoryBrands.vue'
import CategoryDynamics from './CategoryDynamics.vue'
import { marketPlaceValues } from '@/constants/index.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

const start = new Date()
const end = new Date()
start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [toBackPage, permissions],
  components: {
    CategoryProducts,
    CategorySubcategories,
    CategorySellers,
    CategoryBrands,
    CategoryDynamics
  },
  data() {
    return {
      isLoading: false,
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      marketPlaceValues,
      pageState:
        this.$route.query.state &&
        ['categoryProducts', 'categorySubcategories', 'categorySellers', 'categoryBrands', 'categoryDynamics'].includes(
          this.$route.query.state
        )
          ? this.$route.query.state
          : 'categoryProducts',
      filters: {
        code: this.$route.query.mp_code,
        category: {
          external_id: this.$route.params.id
        },
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        favoritesOnly: false,
        fbs: this.$route.query.is_fbs === 'true'
      }
    }
  },
  watch: {
    '$route.params.id': {
      handler(categoryId) {
        document.title = this.$route.query.title
        this.filters = { ...this.filters, ...{ category: { external_id: categoryId } } }
        if (this.$route.query.child === false && this.pageState === 'categorySubcategories') this.selectTab(0)
      }
    },
    '$route.query.state': {
      handler(state) {
        state && state !== 'undefined' ? (this.pageState = state) : this.setUrlParams(this.pageState)
      }
    }
  },
  computed: {
    ...mapState(['isVIP']),
    tabs() {
      return [
        {
          id: 'categoryProducts',
          title: 'Товары',
          eventName: 'orm_categories_category_products'
        },
        {
          id: 'categorySubcategories',
          title: 'Подкатегории',
          disabled: this.$route.query.child === 'false',
          eventName: 'orm_categories_category_subcategories'
        },
        {
          id: 'categorySellers',
          title: 'Продавцы',
          eventName: 'orm_categories_category_sellers'
        },
        {
          id: 'categoryBrands',
          title: 'Бренды',
          eventName: 'orm_categories_category_brands'
        },
        {
          id: 'categoryDynamics',
          title: 'Динамика',
          eventName: 'orm_categories_category_dynamics'
        }
      ]
    },
    getCondition() {
      if (this.isVIP) {
        return true
      } else {
        if (this.pageState === 'categorySellers') return false
      }
      return true
    }
  },
  created() {
    document.title = this.$route.query.title
    if (
      (this.$route.query.child === 'false' && this.pageState === 'categorySubcategories') ||
      !this.tabs.some(el => el.id === this.pageState)
    ) {
      this.selectTab(0)
    } else {
      if (!this.$route.query.title) this.setUrlParams(this.pageState)

      const tab = this.tabs.find(tab => tab.id === this.pageState)
      if (tab) {
        this.$metrics.hit(tab.eventName, {
          category: this.filters.category.external_id,
          marketplace_code: this.filters.code
        })
      }
    }
  },
  methods: {
    selectTab(idx) {
      this.setUrlParams(this.tabs[idx].id)

      this.$metrics.hit(this.tabs[idx].eventName, {
        category: this.filters.category.external_id,
        marketplace_code: this.filters.code
      })
    },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )

      // 6 : 13
      const period = {
        start: currentDate,
        end
      }

      this.defaultPickerAction = val.id
      this.filters = { ...this.filters, period }
    },
    switchFbs(fbs) {
      this.filters = { ...this.filters, fbs }
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    },
    setUrlParams(state) {
      if (this.$route.name === 'category_page') {
        const params = new URLSearchParams()
        params.append('mp_code', this.filters.code)
        params.append('title', this.$route.query.title)
        params.append('child', this.$route.query.child)
        params.append('state', state)
        // params.append('crumbs', this.$route.query.crumbs)
        params.append('is_fbs', this.filters.fbs)
        params.append('default_picker_action', this.defaultPickerAction)
        params.append('date_from', this.formatDate(this.filters.period.start, '-'))
        params.append('date_to', this.formatDate(this.filters.period.end, '-'))

        // if (this.$route.query.crumbs_text) params.append('crumbs_text', this.$route.query.crumbs_text)

        this.$router.replace(`?${params.toString()}`).catch(() => {})
      }
    }
  }
}
</script>
