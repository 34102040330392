<template>
  <div class="periods__picker">
    <div class="periods__picker__value">{{ datePeriod }}</div>
    <div class="calendar__icon" @click.stop>
      <x-icon name="calendar" size="s"></x-icon>
    </div>
  </div>
</template>

<style lang="stylus">

.periods__picker
  position relative
  background #FFFFFF
  border 1px solid $colors.gray
  line-height 36px
  font-size $font-size-s
  color $colors.gray-darkest-2
  border-radius 4px
  height 36px
  display flex
  user-select none

.periods__picker__value
  flex-grow 1
  padding 0 8px
  cursor pointer
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.calendar__icon
  width 36px
  height 100%
  display flex
  align-items center
  justify-content center
  flex-shrink 0
  border-left 1px solid $colors.gray
  color: $colors.gray-dark
</style>

<script>
export default {
  props: {
    period: {
      type: Object
    },
    periodAction: {
      type: String
    }
  },
  computed: {
    datePeriod() {
      switch (this.periodAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${Intl.DateTimeFormat('ru').format(this.period?.start)} - ${Intl.DateTimeFormat('ru').format(this.period?.end)}`
      }
      return ''
    }
  }
}
</script>
