<template>
  <x-page title="Панель управления">
    <div class="summary">
      <div class="promo__banners mb-6">
        <div class="banner" data-event="orm_tools_sellers" @click.capture="sendEvent">
          <div class="banner__image">
            <x-icon name="eye-on" size="m"></x-icon>
          </div>
          <div class="banner__text">Отслеживайте конкурентов</div>
          <div class="banner__icon"></div>
          <router-link to="/sellers"></router-link>
        </div>
        <div class="banner" data-event="orm_tools_brands" @click.capture="sendEvent">
          <div class="banner__image">
            <x-icon name="search" size="m"></x-icon>
          </div>
          <div class="banner__text">Изучайте бренды</div>
          <div class="banner__icon"></div>
          <router-link to="/brands"></router-link>
        </div>
        <div class="banner" data-event="orm_tools_categories" @click.capture="sendEvent">
          <div class="banner__image">
            <x-icon name="trending-up" size="m"></x-icon>
          </div>
          <div class="banner__text">Находите прибыльные ниши</div>
          <div class="banner__icon"></div>
          <router-link to="/categories"></router-link>
        </div>
        <div class="banner" data-event="orm_tools_products" @click.capture="sendEvent">
          <div class="banner__image">
            <x-icon name="circle-pie" size="m"></x-icon>
          </div>
          <div class="banner__text">Анализируйте товары</div>
          <div class="banner__icon"></div>
          <router-link to="/products"></router-link>
        </div>
      </div>

      <!-- УСЛУГИ  -->
      <!-- <Services  :user="user" /> -->

      <x-card size="xl">
        <div class="favorites__title">Отслеживаемые</div>
        <div class="tabs">
          <x-tabs :tabs="tabs" :selected="tabs.findIndex(el => el.id === pageState)" size="l" @change="selectTab" />
        </div>
        <div class="grid__row">
          <div class="row__item">
            <x-label :size="'xs'">Показать</x-label>
            <date-select
              :preloader="!sellersFavoritesList.length || isLoading"
              :period-action="defaultPickerAction"
              :period="filters.period"
              @selectPeriod="onSelectPeriod"
            ></date-select>
          </div>
          <div class="row__item">
            <div class="label__fake"></div>
            <fbs-switcher :value="filters.fbs" :disabled="!sellersFavoritesList.length || isLoading" @input="switchFbs" />
          </div>
        </div>
        <keep-alive>
          <component
            :is="pageState"
            :filters="filters"
            :default-picker-action="defaultPickerAction"
            @loadingStarted="isLoading = true"
            @loadingFinished="isLoading = false"
          />
        </keep-alive>
      </x-card>
    </div>
  </x-page>
</template>

<style lang="stylus" scoped>

.summary
  flex-grow 1
  display flex
  flex-direction column

.summary .x-card
  min-height: 400px
  flex-grow 1
  display flex
  flex-direction column
  & .favorites__title
    color: $colors.gray-darkest-2
    line-height 24px
    font-size $font-sizes.headline-4
    font-weight $font-weights.bold
    margin-bottom 22px

.promo__banners
  display grid
  grid-template-columns repeat(4, minmax(230px, 1fr))
  grid-gap 24px

@media (max-width 1200px)
  .promo__banners
    grid-template-columns repeat(2, minmax(240px, 1fr))

@media (max-width 1400px)
  .x-content--collapsed .promo__banners
    grid-template-columns repeat(2, minmax(240px, 1fr))

@media (max-width 920px)
  .x-content--collapsed .promo__banners
    grid-template-columns 1fr

@media (max-width 768px)
  .promo__banners
    grid-template-columns 1fr

.banner
  position relative
  background #FFFFFF
  box-shadow 0px 24px 16px rgba(5, 2, 55, 0.02)
  border-radius 8px
  padding 27px 20px
  display flex
  align-items center
  & a
    position absolute
    top 0
    left 0
    width 100%
    height 100%

.banner__image
  display flex
  align-items center
  justify-content center
  flex-shrink 0
  width 40px
  height 40px
  margin-right 12px
  border-radius 50%
  color: $colors.primary
  border 2px solid $colors.primary

.banner__text
  line-height 18px
  font-size $font-size-m
  font-weight $font-weights.semibold

.banner__icon
  width 14px
  height 14px
  border-right 2.5px solid $colors.gray-darkest-2
  border-bottom 2.5px solid $colors.gray-darkest-2
  border-radius 2.5px
  transform rotateZ(-45deg)
  margin-left auto
  flex-shrink 0
  background #fff

.tabs
  pointer-events none
  flex-shrink 0

.grid__row
  display grid
  grid-template-columns calc(25% - 7px) auto
  grid-gap 12px
  grid-auto-rows auto
  // margin-bottom 24px
  padding-bottom 12px

.label__fake
  margin-bottom 7px
  height 14px

@media (max-width 1660px)
  .x-content--collapsed .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) auto

@media (max-width 1500px)
  .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) auto

@media (max-width 1360px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 1200px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 992px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

.plug__link
  text-decoration none

.disabled
  pointer-events none
  opacity .5
</style>

<script>
import changeDocumentTitle from '@/mixins/changeDocumentTitle.js'
// import Services from './Services.vue'
// import SummaryProducts from './SummaryProducts.vue'
import SummarySellers from './SummarySellers.vue'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

const start = new Date()
const end = new Date()
start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [changeDocumentTitle],
  components: {
    // Services,
    // SummaryProducts,
    SummarySellers
  },
  data() {
    return {
      pageState: 'summarySellers',
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      isLoading: false,
      filters: {
        code: 'wb',
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        fbs: false
      },
      tabs: [
        {
          id: 'summarySellers',
          title: 'Продавцы',
          event: 'orm_tools_sellers_selected'
        },
        {
          id: 'summaryProducts',
          title: 'Товары',
          badge: 'скоро',
          event: 'orm_tools_products_selected',
          disabled: true
        },
        {
          id: 'brands',
          title: 'Бренды',
          badge: 'скоро',
          event: 'orm_tools_brands_selected',
          disabled: true
        }
      ]
    }
  },
  computed: {
    ...mapState(['sellersFavoritesList', 'user'])
  },
  created() {
    this.$metrics.hit('orm_tools_view')

    const selectedTab = this.tabs.find(tab => tab.id === this.pageState)
    if (selectedTab) this.$metrics.hit(selectedTab.event)
  },
  methods: {
    sendEvent(e) {
      this.$metrics.hit(e.currentTarget.dataset.event)
    },
    selectTab(idx) {
      this.pageState = this.tabs[idx].id
      this.$metrics.hit(this.tabs[idx].event)
    },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )

      // 6 : 13
      const period = {
        start: currentDate,
        end
      }

      this.defaultPickerAction = val.id
      this.filters = { ...this.filters, period }
    },
    switchFbs(fbs) {
      this.filters = { ...this.filters, fbs }
      this.$metrics.hit(fbs ? 'orm_tools_sellers_selected_fbs' : 'orm_tools_sellers_selected_fbo')
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    }
  }
}
</script>
