<template>
  <div class="brand__page">
    <x-page card :breadcrumbs="breadCrumbs">
      <template #title>
        <div class="title__row">
          <div class="back__icon" @click="toPrevPage">
            <x-icon :name="'chevron-left'" size="s" />
          </div>
          <!-- <x-icon :name="marketPlaceValues[filters.code] ? marketPlaceValues[filters.code].icon : 'wildberries'" /> -->
          <page-title>
            <a
              class="title__link"
              :class="{ title__link__disabled: !headerData.mp_seller_url }"
              :href="headerData.mp_seller_url"
              target="_blank"
            >
              {{ headerData.brand_name }}
            </a>
          </page-title>
          <!-- <div class="tracked__icon" :class="{ tracked__icon_active: isInFavorites }" @click="toggleTrack">
            <x-icon name="bookmark" size="xs"></x-icon>
          </div> -->
        </div>
      </template>
      <div class="info__banner__container">
        <info-banner
          :title="'Товары, шт'"
          :value="headerData.products_cnt"
          :profit="headerData.products_delta"
          :preloader="bannersPreloader"
          :text="`Товары, которые были в продаже за ${datePeriod}`"
        />
        <info-banner
          :title="'Кол-во продавцов'"
          :value="headerData.sellers_cnt"
          :profit="headerData.sellers_delta"
          :preloader="bannersPreloader"
          :text="'Кол-во продавцов'"
        />
        <info-banner
          :title="'Продажи, шт'"
          :value="headerData.sales_cnt"
          :profit="headerData.sales_delta"
          :preloader="bannersPreloader"
          :text="`Количество продаж за ${datePeriod}`"
        />
        <info-banner
          :title="'Остатки, шт'"
          :value="headerData.quantity_cnt"
          :profit="headerData.quantity_delta"
          :preloader="bannersPreloader"
          text="Товарные остатки на текущий момент"
        />
        <info-banner
          :title="'Выручка, руб'"
          :value="headerData.proceeds"
          :profit="headerData.proceeds_delta"
          :preloader="bannersPreloader"
          :text="`Общая выручка за ${datePeriod}`"
        />
        <info-banner
          :title="'Упущенная выручка, руб'"
          :value="'Скоро'"
          :profit="''"
          :preloader="bannersPreloader"
          text="Упущенная выручка на текущий момент"
        />
      </div>
      <div class="tabs" :class="{ disabled: isLoading || !isPermitByTariff }" >
        <x-tabs :tabs="tabs" size="l" :selected="tabs.findIndex(el => el.id === pageState)" @change="selectTab" />
      </div>
      <keep-alive>
        <component
          :is="pageState"
          :filters="filters"
          :default-picker-action="defaultPickerAction"
          :header-data="headerData"
          @loadingStarted="isLoading = true"
          @loadingFinished="isLoading = false"
          @periodChanged="onSelectPeriod"
          @clearCategory="clearCategory"
          @categoriesModalOpen="categoriesModal = true"
          @switchFbs="switchFbs"
        >
        </component>
      </keep-alive>
      <transition name="fade" mode="out-in" appear>
        <keep-alive>
          <x-categories-popup
            v-if="categoriesModal"
            :value="filters.category"
            key-id="external_id"
            key-name="name"
            :selected-tab="filters.code"
            :code="filters.code"
            @input="selectCategory"
            @close="categoriesModal = false"
          />
        </keep-alive>
      </transition>
    </x-page>
  </div>
</template>

<style lang="stylus" scoped>
.brand__page
  display flex
  flex-direction column
  flex-grow 1

.back__icon
  color: $colors.gray-dark
  width 36px
  height 36px
  border-radius 5px
  display flex
  align-items center
  justify-content center
  cursor pointer
  transition .2s
  margin-right 10px
  flex-shrink 0
  &:hover
    color: $colors.gray-darkest-2
    background $colors.gray-lighter

.title__row
  display flex
  align-items center
  width 100%

// .x-icon--m
//   margin-right 4px
//   flex-shrink 0

.info__banner__container
  display grid
  grid-template-columns repeat(6, minmax(200px, 1fr))
  grid-gap 12px
  margin-bottom 20px

@media (max-width 1500px)
  .info__banner__container
    grid-template-columns repeat(3, minmax(200px, 1fr))

@media (max-width 1670px)
  .x-content--collapsed .info__banner__container
    grid-template-columns repeat(3, minmax(200px, 1fr))

@media (max-width 1050px)
  .x-content--collapsed .info__banner__container
    grid-template-columns repeat(2, minmax(200px, 1fr))

@media (max-width 876px)
  .x-content--collapsed .info__banner__container
    grid-template-columns minmax(200px, 1fr)

@media (max-width 992px)
  .info__banner__container
    grid-template-columns repeat(2, minmax(200px, 1fr))

@media (max-width 768px)
  .info__banner__container
    grid-template-columns minmax(200px, 1fr)

.disabled
  pointer-events none
</style>

<script>
import toBackPage from '@/mixins/toBackPage.js'
import permissions from '@/mixins/permissions.js'
import BrandProducts from './BrandProducts'
import BrandSellers from './BrandSellers'
import BrandDynamics from './BrandDynamics'
import { getHeaderData } from '@/api/brandPage/index.js'
import { marketPlaceValues } from '@/constants/index.js'

const start = new Date()
const end = new Date()

start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [toBackPage, permissions],
  components: {
    BrandProducts,
    BrandSellers,
    BrandDynamics
  },
  data() {
    return {
      prevPagePath: '',
      bannersPreloader: false,
      categoriesModal: false,
      isLoading: false,
      marketPlaceValues,
      pageState:
        this.$route.query.state && ['BrandProducts', 'BrandSellers', 'BrandDynamics'].includes(this.$route.query.state)
          ? this.$route.query.state
          : 'BrandProducts',
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      headerData: {
        brand_name: '',
        products_cnt: 0,
        products_delta: 0,
        sellers_cnt: 0,
        sellers_delta: 0,
        sales_cnt: 0,
        sales_delta: 0,
        quantity_cnt: 0,
        quantity_delta: 0,
        proceeds: 0,
        proceeds_delta: 0,
        lost_proceeds: 0,
        lost_proceeds_delta: 0
      },
      filters: {
        code: 'wb',
        brand: {
          brand_id: this.$route.params.id
        },
        category: {
          external_id: this.$route.query.category_id || '',
          name: this.$route.query.category_name || ''
        },
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        fbs: this.$route.query.is_fbs === 'true'
      },
      tabs: [
        {
          id: 'BrandProducts',
          title: 'Товары',
          eventName: 'orm_brands_brand_products'
        },
        {
          id: 'BrandSellers',
          title: 'Продавцы',
          eventName: 'orm_brands_brand_sellers'
        }
        // {
        //   id: 'BrandDynamics',
        //   title: 'Динамика'
        // }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevPagePath = from.fullPath
      next()
    })
  },
  computed: {
    datePeriod() {
      switch (this.defaultPickerAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${Intl.DateTimeFormat('ru').format(this.filters.period?.start)} - ${Intl.DateTimeFormat('ru').format(
            this.filters.period?.end
          )}`
      }
      return ''
    },
    breadCrumbs() {
      // let path
      // if (this.prevPagePath) {
      //   path = this.prevPagePath.replace(/crumbs_text=.{1,100000}/g, `crumbs_text=${this.headerData.brand_name}`)
      //   if (this.$route.query.crumbs_text === 'Бренды') path = '/brands'
      // }

      // if (!this.prevPagePath || this.prevPagePath === '/' || path.match('brands')) {
      //   path = '/'
      // }

      // return [
      //   {
      //     to: path && path !== '/' ? path : '/brands',
      //     text: path && path !== '/' ? this.$route.query.crumbs_text : 'Бренды'
      //   }
      // ]
      return [
        {
          to: '/brands',
          text: 'Бренды'
        }
      ]
    }
  },
  created() {
    if (this.isPermitByTariff) {
      this.setUrlParams()
      this.loadHeaderData(this.filters)

      const tab = this.tabs.find(tab => tab.id === this.pageState)
      if (tab) {
        this.$metrics.hit(tab.eventName, {
          brand: this.filters.brand.brand_id,
          marketplace_code: this.filters.code,
          category: this.filters.category.external_id
        })
      }
    }
  },
  methods: {
    selectTab(idx) {
      this.pageState = this.tabs[idx].id
      this.setUrlParams()

      this.$metrics.hit(this.tabs[idx].eventName, {
        brand: this.filters.brand.brand_id,
        marketplace_code: this.filters.code,
        category: this.filters.category.external_id
      })
    },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )

      // 6 : 13
      const period = {
        start: currentDate,
        end
      }

      this.filters = { ...this.filters, period }

      this.defaultPickerAction = val.id

      this.loadHeaderData(this.filters)
    },
    switchFbs(fbs) {
      this.filters = { ...this.filters, fbs }
      this.loadHeaderData(this.filters)
    },
    selectCategory(category) {
      this.filters = { ...this.filters, category }
    },
    clearCategory(category) {
      this.selectCategory(category)
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    },
    async loadHeaderData(filters) {
      this.bannersPreloader = true
      try {
        const { data } = await this.$axios.get(...getHeaderData(filters))
        if (data && data.data) document.title = data.data.brand_name
        this.headerData = data.data || {
          ...this.headerData,
          ...{
            products_cnt: 0,
            products_delta: 0,
            sellers_cnt: 0,
            sellers_delta: 0,
            sales_cnt: 0,
            sales_delta: 0,
            quantity_cnt: 0,
            quantity_delta: 0,
            proceeds: 0,
            proceeds_delta: 0,
            lost_proceeds: 0,
            lost_proceeds_delta: 0
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.bannersPreloader = false
      }
    },
    setUrlParams() {
      if (this.$route.name === 'brand_page') {
        const params = new URLSearchParams()
        params.append('mp_code', this.filters.code)
        params.append('state', this.pageState)

        if (this.filters.category.name) {
          params.append('category_name', this.filters.category.name)
          params.append('category_id', this.filters.category.external_id)
        }

        params.append('default_picker_action', this.defaultPickerAction)
        params.append('date_from', this.formatDate(this.filters.period.start, '-'))
        params.append('date_to', this.formatDate(this.filters.period.end, '-'))

        if (this.$route.query.crumbs_text) params.append('crumbs_text', this.$route.query.crumbs_text)

        this.$router.replace(`?${params.toString()}`).catch(() => {})
      }
    }
  }
}
</script>
