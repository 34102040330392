<template>
  <div class="category__button" :class="{ dark: value.name }" @mouseenter="showRemove" @mouseleave="hideRemove">
    <span>{{ value.name || 'Не выбрано' }}</span>
    <div
      v-if="value.name"
      ref="remove"
      class="delete__icon"
      :class="{ show: value.name && $isMobile }"
      @click.stop="$emit('input', { external_id: '', name: '' })"
    >
      <x-icon name="clear" size="xs"></x-icon>
    </div>
    <div class="chevron__icon">
      <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M4 10.5L8 6.5L4 2.5" stroke="#A3A3AC" stroke-width="1.5" />
      </svg>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.category__button
  position relative
  cursor pointer
  display flex
  align-items center
  position relative
  height 36px
  font-size $font-size-s
  color $colors.gray-dark
  border-radius 5px
  border 1px solid $colors.gray
  padding 0px 22px 0 8px
  & span
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
    padding-right 5px

.dark
  color $colors.gray-darkest-2

.chevron__icon
  width 12px
  height 12px
  position absolute
  top: 50%;
  right: 10px;
  transform translateY(-50%)

.delete__icon
  width 16px
  height 16px
  top: 50%;
  right: 26px;
  flex-shrink 0
  margin-left auto
  margin-right 4px
  visibility hidden
  color: $colors.gray

.show
  visibility visible
</style>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    showRemove() {
      if (this.value.name) this.$refs.remove.classList.add('show')
    },
    hideRemove() {
      if (this.value.name && !this.$isMobile) this.$refs.remove.classList.remove('show')
    }
  }
}
</script>
