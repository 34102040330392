<template>
  <button type="button" class="filter__button">
    <div class="filter__button__name__row">
      <div class="filter__button__icon">
        <x-icon name="filter" size="s"></x-icon>
      </div>
      <div class="filters__button__name">
        {{ opened ? 'Свернуть' : 'Все фильтры' }}
      </div>
    </div>
    <div v-if="changes" class="filters__button__reset" title="Сбросить фильтры" @click.stop="$emit('resetFilters')">
      <x-icon name="x" size="s"></x-icon>
    </div>
  </button>
</template>

<style lang="stylus" scoped>

.filter__button
  border 1px solid $colors.primary
  color $colors.primary
  font-weight 600
  font-size $font-size-s
  border-radius 4px
  background #fff
  cursor pointer
  display flex
  align-items center
  padding-left 9.7px
  height 36px
  outline none

.filter__button__name__row
  display flex
  align-items center

.filter__button__icon
  width 20px
  height 20px
  flex-shrink 0

.filters__button__name
  padding-left 5.7px
  padding-right 10px
  white-space nowrap

.filters__button__reset
  width 36px
  height 100%
  border-left 1px solid $colors.primary
  display flex
  align-items center
  justify-content center
</style>

<script>
export default {
  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    changes: {
      type: Boolean,
      default: () => false
    }
  }
}
</script>
