export default {
  user: {},
  isLoading: false,
  isVIP: false,
  showAlertModal: false,
  marketplacesTabs: [{
    prependIcon: 'wildberries',
    mp_code: 'wb',
    title: 'Wildberries'
  },
  {
    prependIcon: 'ozon',
    mp_code: 'oz',
    badge: 'скоро',
    title: 'Ozon',
    disabled: true
  },
  {
    prependIcon: 'yandex-market',
    mp_code: 'ym',
    badge: 'скоро',
    title: 'Яндекс.Маркет',
    disabled: true
  },
  {
    prependIcon: 'aliexpress',
    mp_code: 'ae',
    badge: 'скоро',
    title: 'AliExpress',
    disabled: true
  },
  {
    prependIcon: 'sber-mega-market',
    mp_code: 'smm',
    badge: 'скоро',
    title: 'СберМегаМаркет',
    disabled: true
  }],
  sellersFavoritesList: []
}
