<template>
  <div class="switcher" :class="{ switcher__active: value, disabled }" @click="$emit('input', !value)" />
</template>

<style lang="stylus" scoped>

.switcher
  position relative
  cursor pointer
  width 28px
  height 16px
  border-radius 16px
  background $colors.gray
  transition .1s
  &:after
    content ''
    position absolute
    transition .1s
    top: 50%;
    left: 2px;
    transform: translateY(-50%);
    width 12px
    height 12px
    border-radius 50%
    background #fff
    box-shadow 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 3px 1px rgba(0, 0, 0, 0.06)
  &__active
    background $colors.primary
  &__active:after
    left 14px

.disabled
  pointer-events none
  opacity .5
</style>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
