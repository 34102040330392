<template>
  <div class="statistics">
    <div v-if="!isPermitByTariff" class="not-permit-tariff">
      <not-permit-by-tariff />
    </div>
    <template v-else>
      <div class="statistics__row">
        <chart-labels :chart-data="chartData.datasets" :class="{ disabled: preloader }" @hideChart="hideChart"></chart-labels>
        <selected-period :period="filters.period"></selected-period>
      </div>
      <Chart :chart-data="chartDataFiltered" :options="options" :preloader="preloader" />
      <div class="title__row">
        <!-- <x-btn class="download__btn" color="gray">
          <x-icon class="download__icon" name="download" size="s"></x-icon>
          Скачать в XLSX
        </x-btn> -->
      </div>
      <dynamics-list :period-list="chartData.labels" :datasets="chartData.datasets" :preloader="preloader"></dynamics-list>
    </template>
  </div>
</template>

<style lang="stylus" scoped>

.statistics__row
  display flex
  align-items center
  margin-bottom 5px

.chart__labels
  padding-right 12px
  border-right 1px solid #ededed
  height 26px

@media (max-width 860px)
  .x-content--collapsed .statistics__row
    display block

  .x-content--collapsed .chart__labels
    border none

  .x-content--collapsed .statistics__row .current__period
    padding 0

@media (max-width 650px)
  .statistics__row
    display block

  .chart__labels
    border none

  .statistics__row .current__period
    padding 0

@media (max-width 630px)
  .x-content--collapsed .chart__labels
    border none
    display grid
    grid-template-columns 1fr
    grid-auto-rows 30px
    height auto

@media (max-width 420px)
  .chart__labels
    border none
    display grid
    grid-template-columns 1fr
    grid-auto-rows 30px
    height auto

.sales__scale
  margin-top 20px
  padding 0 12px 0 8px
  border-right 1px solid $colors.gray-light

.sales__scale__value
  position relative
  font-size 10px
  line-height 13px
  margin-bottom 9.4px
  text-align right
  font-weight bold
  &:after
    content: ''
    position absolute
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    width 8px
    height 1px
    background $colors.gray-light

.title__row
  display flex
  align-items center
  margin 20px 0 12px
  height 12px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit
    cursor inherit

.not-permit-tariff
  position relative
  min-height 370px

.disabled
  opacity .5
  pointer-events none
</style>

<script>
import Chart from '@/ui/chart/Chart'
import DynamicsList from '@/components/DynamicsList.vue'
import ChartLabels from '@/ui/chart/ChartLabels.vue'
import calcPeriod from '@/mixins/calcPeriod.js'
import permissions from '@/mixins/permissions.js'
import { getRemainsSales } from '@/api/productPage/index.js'

export default {
  mixins: [calcPeriod, permissions],
  components: {
    ChartLabels,
    Chart,
    DynamicsList
  },
  props: {
    filters: {
      type: Object
    }
  },
  data() {
    return {
      preloader: false,
      downloadPreloader: false,
      chartData: {
        type: 'line',
        labels: [],
        datasets: [
          {
            id: 'remains',
            delta_id: 'remains_delta_percent',
            label: 'Остатки, шт',
            selected: true,
            // borderDash: [5],
            data: [],
            backgroundColor: '#FD8E39',
            borderColor: ['#FD8E39'],
            borderWidth: 2,
            // pointRadius: 5,
            yAxisID: 'remains'
          },
          {
            id: 'sales_cnt',
            delta_id: 'sales_cnt_percent',
            label: 'Продажи, шт',
            selected: true,
            data: [],
            backgroundColor: ['#3E66FB'],
            borderColor: ['#3E66FB'],
            borderWidth: 2,
            yAxisID: 'sales_cnt'
          }
        ]
      },
      options: {
        spanGaps: true,
        interaction: {
          intersect: false
        },
        plugins: {
          tooltip: {
            boxPadding: 5,
            interaction: {
              mode: 'index'
            }
          },
          legend: {
            display: false
            // labels: {
            //   usePointStyle: true,
            //   pointStyle: 'rect'
            // }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3,
        scales: {
          remains: {
            id: 'remains',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: true
            },
            ticks: {
              color: '#FD8E39'
            }
          },
          sales_cnt: {
            id: 'sales_cnt',
            type: 'linear',
            beginAtZero: true,
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#3E66FB'
            }
          }
        }
      }
    }
  },
  watch: {
    filters(filters) {
      this.loadDynamics(filters)
    }
  },
  computed: {
    chartDataFiltered() {
      return { ...this.chartData, datasets: this.chartData.datasets.filter(el => el.selected) }
    }
  },
  created() {
    if (this.isPermitByTariff) this.loadDynamics(this.filters)
  },
  methods: {
    hideChart(item) {
      this.options.scales[item.id].display = item.selected
    },
    async loadDynamics(filters) {
      this.$emit('startLoading')
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getRemainsSales(filters))
        this.chartData.labels = this.calcPeriod

        if (data && data.data) {
          if (data.data.length > this.calcPeriod.length) data.data.pop()
          this.chartData.datasets.forEach(el => {
            el.rows = data.data.map(item => {
              return {
                value: item[el.id],
                difference: item[el.delta_id]
              }
            })

            el.data = data.data.map(item => item[el.id])
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.$emit('endLoading')
        this.preloader = false
      }
    }
  }
}
</script>
