<template>
  <div class="categories">
    <div
      v-for="(item, index) in sortedList"
      :key="item.category_id"
      :class="{ not__active: !item.has_children }"
      class="categories__item__container"
      @click.stop="openSubList(item, index)"
    >
      <div class="categories__item">
        <div class="categories__item__row" :style="{ paddingLeft: `${padding}px` }">
          <x-icon
            :style="{ visibility: item.has_children ? 'visible' : 'hidden' }"
            class="categories__arrow"
            :class="{ arrow__active: item.opened }"
            name="expand-arrow"
            size="xxs"
          >
          </x-icon>
          <x-icon :name="marketPlaceValues[item.mp_code].icon" size="s"></x-icon>
          <div @click.capture="sendEvent(item)">
            <x-link
              :to="{
                name: 'category_page',
                params: { id: item.category_id },
                query: {
                  mp_code: item.mp_code,
                  title: item.category_name,
                  child: item.has_children,
                  is_fbs: fbs.toString(),
                  default_picker_action: defaultPickerAction,
                  date_from: formatDate(period.start),
                  date_to: formatDate(period.end)
                }
              }"
              class="categories__name"
            >
              {{ item.category_name }}
            </x-link>
          </div>
        </div>
        <categories-names
          v-if="item.opened"
          :list="item.sublist"
          :sort-options="sortOptions"
          :get-sublist="getSublist"
          :padding="padding + 10"
          :code="code"
          :period="period"
          :fbs="fbs"
          :default-picker-action="defaultPickerAction"
          @startLoading="$emit('startLoading')"
          @endLoading="$emit('endLoading')"
        ></categories-names>
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.categories__item__container
  cursor pointer

.categories__item__row
  display flex
  height 53px
  padding 8px
  color: $colors.gray-dark
  border-bottom: 1px solid $colors.gray-light
  &:hover
    background-color: $colors.gray-lightest-2
  .x-icon--xxs
    width 8px
    height 8px
    margin-right 4px

.categories__arrow
  transform translateY(5px)
  transition .2s

.arrow__active
  transform translateY(5px) rotateZ(90deg)

.categories__name
  margin-left 4px
  color: $colors.gray-darkest-2
  font-size $font-size-s
  line-height 20px
  text-decoration underline
  cursor pointer
  display -webkit-box
  -webkit-line-clamp 2
  overflow hidden
  -webkit-box-orient vertical

.not__active
  cursor default
</style>

<script>
import { marketPlaceValues } from '@/constants/index.js'

export default {
  name: 'CategoriesNames',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    getSublist: {
      type: Function
    },
    code: {
      type: String
    },
    padding: {
      type: Number,
      default: 8
    },
    period: {
      type: Object
    },
    fbs: Boolean,
    defaultPickerAction: {
      type: String
    },
    sortOptions: {
      type: Object
    }
  },
  data() {
    return {
      marketPlaceValues,
      isLoading: false
      // parentsCollection: [],
      // parentsList: []
    }
  },
  computed: {
    sortedList() {
      return [...this.list].sort((a, b) =>
        this.sortOptions.direction === '1'
          ? b[this.sortOptions.id] - a[this.sortOptions.id]
          : a[this.sortOptions.id] - b[this.sortOptions.id]
      )
    }
  },
  methods: {
    sendEvent(item) {
      if (this.$route.meta.metricsEvents?.category_select) {
        this.$metrics.hit(this.$route.meta.metricsEvents?.category_select, {
          category: item.category_id,
          marketplace_code: this.code
        })
      }
    },
    // toCategoryPage(item) {
    //   const currentCategory = this.$route.query.title
    //     ? { category_id: this.$route.params.id, name: this.$route.query.title, child: Boolean(this.$route.query.child) }
    //     : ''

    //   // this.parentsList = this.parentsList.map(el => JSON.stringify(el))
    //   const arr = this.$route.query.crumbs ? [...new Set(JSON.parse(this.$route.query.crumbs).map(el => JSON.stringify(el)))] : []
    //   console.log(arr)
    //   this.parentsList = this.findParents(this.$parent, item.parent_id)
    //     ? [...arr, ...this.findParents(this.$parent, item.parent_id)]
    //     : arr
    //   this.parentsList = this.parentsList.map(el => JSON.parse(el))
    //   this.parentsList = currentCategory ? [...this.parentsList, currentCategory] : [...this.parentsList]

    //   this.$router.push({
    //     name: 'category_page',
    //     params: { id: item.category_id },
    //     query: {
    //       mp_code: item.mp_code,
    //       title: item.category_name,
    //       child: item.has_children,
    //       is_fbs: this.fbs.toString(),
    //       crumbs: JSON.stringify(this.parentsList),
    //       default_picker_action: this.defaultPickerAction,
    //       date_from: this.formatDate(this.period.start),
    //       date_to: this.formatDate(this.period.end)
    //     }
    //   })
    // },
    // findParents(parent, parentId) {
    //   if (parent.list) {
    //     this.parentsCollection.push(
    //       parent.list.find(el => {
    //         if (el.category_id === parentId) {
    //           return el
    //         }

    //         if (parent.$parent && parent.$parent.list) this.findParents(parent.$parent, el.parent_id)
    //       })
    //     )

    //     return [
    //       ...new Set(
    //         this.parentsCollection.map(el =>
    //           JSON.stringify({ category_id: el.category_id, name: el.category_name, child: el.has_children })
    //         )
    //       )
    //     ]
    //   }
    // },
    async openSubList(item, index) {
      if (!item.sublist && item.has_children) {
        this.isLoading = true
        this.$emit('startLoading')
        try {
          const { data } = await this.getSublist(item.category_id)
          if (data && data.data.length) {
            // data.data.forEach(el => {
            //   el.parent_id = item.category_id
            // })

            item.sublist = data.data || []

            this.sortedList.forEach((el, k) => {
              if (k !== index) el.opened = false
            })

            item.opened = true
          }
        } catch (err) {
          console.log(err)
        } finally {
          this.$emit('endLoading')
        }
      }

      if (!this.isLoading) {
        this.sortedList.forEach((el, k) => {
          if (k !== index) el.opened = false
        })

        if (item.sublist && item.sublist.length) {
          item.opened = !item.opened
        }
      }
      this.isLoading = false
      this.$forceUpdate()
    },
    formatDate(date) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, '-')
    }
  }
}
</script>
