export default Object.freeze({
  YESTERDAY: 'YESTERDAY',
  TODAY: 'TODAY',
  CURRENT_WEEK: 'CURRENT_WEEK',
  LAST_WEEK: 'LAST_WEEK',
  CURRENT_MONTH: 'CURRENT_MONTH',
  LAST_MONTH: 'LAST_MONTH',
  CURRENT_QUARTER: 'CURRENT_QUARTER',
  LAST_QUARTER: 'LAST_QUARTER',
  CURRENT_YEAR: 'CURRENT_YEAR',
  LAST_YEAR: 'LAST_YEAR',
  OTHER: 'OTHER',
  SEVEN: 'SEVEN',
  FOURTEEN: 'FOURTEEN',
  THIRTY: 'THIRTY',
  NINETY: 'NINETY'
})
