const getCategories = (code, categoryId, search) => {
  const params = new URLSearchParams()

  if (categoryId) params.append('category_id', categoryId)
  if (search) params.append('name', search)

  return [`api/v1/${code}/shared/categories/`, { params }]
}

export default getCategories
