<template>
  <x-page :class="{ disabled__crumbs: bannersPreloader }" card :breadcrumbs="[{ to: '/products', text: 'Товары' }]">
    <template #title>
      <div class="title__row">
        <div class="back__icon" @click="toPrevPage">
          <x-icon :name="'chevron-left'" size="s" />
        </div>
        <x-icon :name="marketPlaceValues[filters.code] ? marketPlaceValues[filters.code].icon : 'ozon'" size="m" />
        <page-title class="product__page__title">{{ headerData.product_name }}</page-title>
        <!-- <div class="tracked__icon">
          <x-icon name="bookmark" size="xs"></x-icon>
        </div> -->
      </div>
    </template>
    <div class="product__page">
      <div class="header">
        <x-img :src="imageUrl" :size="177"></x-img>
        <div class="header__info">
          <div class="header__bold">{{ headerData.product_name }}</div>
          <div class="header__text">
            Артикул
            <span class="header__text__big">&nbsp;{{ filters.code }}</span>
            :&nbsp;
            <span class="header__text__long">{{ headerData.product_id }}</span>
            <a class="header__link" :href="headerData.mp_product_url" target="_blank">
              <x-icon name="link" size="xs"></x-icon>
            </a>
            <x-badge v-if="outOfStock" color="red-lighten-5">Нет в наличии</x-badge>
          </div>
          <!-- <div class="header__text">Бренд: {{ headerData.brand_name }}</div> -->
          <div class="header__text">
            Продавец:&nbsp;
            <!-- <span class="header__text__long">{{ headerData.seller_name }}</span> -->
            <router-link
              class="name__link"
              :to="{
                name: 'seller_page',
                params: { id: headerData.seller_id },
                query: {
                  mp_code: filters.code,
                  default_picker_action: defaultPickerAction,
                  is_fbs: filters.fbs.toString(),
                  date_from: formatDate(filters.period.start, '-'),
                  date_to: formatDate(filters.period.end, '-')
                }
              }"
            >
              {{ headerData.seller_name }}
              <!-- crumbs_text: headerData.product_name -->
            </router-link>
          </div>
          <!-- <div class="header__text">Обновлён: 31.03.2021</div> -->
          <!-- <div class="header__text">Всего отзывов: {{ headerData.reviews_cnt }}</div> -->
          <div class="rating">
            <span class="rating__icon">
              ★★★★★
              <span class="rating__icon_active" :style="{ width: `${calcWidth}%` }">★★★★★</span>
            </span>
            <span class="rating__semibold">{{ headerData.rating }} / 5</span>
            <span class="rating__description">({{ headerData.reviews_cnt }} отзывов)</span>
          </div>
          <!-- <div class="header__path">
            <x-icon :name="marketPlaceValues[filters.code] ? marketPlaceValues[filters.code].icon : 'ozon'" size="xs" />
            <div class="rating__semibold rating__semibold_path">Женщинам / Обувь</div>
          </div> -->
        </div>
        <div class="header__price">
          <div class="header__row header__row_main">
            <div class="header__semibold">Текущая цена</div>
            <div class="header__price__value">
              <span>{{ headerData.price.toLocaleString('ru') }}</span
              >&nbsp;руб
            </div>
          </div>
          <div class="header__row">
            <div class="header__text">Розничная цена</div>
            <div class="header__doted"></div>
            <div class="header__text">
              <span>{{ headerData.regular_price.toLocaleString('ru') }}</span>
              &nbsp;руб
            </div>
          </div>
          <!-- <div class="header__row">
            <div class="header__text">Скидка {{ headerData.discount.toLocaleString('ru') }}%</div>
            <div class="header__doted"></div>
            <div class="header__text">
              <span>{{ headerData.price.toLocaleString('ru') }}</span>
              &nbsp;руб
            </div>
          </div> -->
          <!-- <div class="header__row">
            <div class="header__text">Промокод {{ headerData.promo_code_discount.toLocaleString('ru') }}%</div>
            <div class="header__doted"></div>
            <div class="header__text">
              <span>{{ headerData.promo_code_price.toLocaleString('ru') }}</span
              >&nbsp;руб
            </div>
          </div> -->
        </div>
        <x-loader v-if="bannersPreloader" size="s" :overlay="true"></x-loader>
      </div>
      <div class="banners">
        <info-banner
          class="tooltip__slightly__right "
          :title="'Продажи, шт'"
          :value="headerData.sales_cnt"
          :profit="headerData.sales_delta"
          :preloader="bannersPreloader"
          :text="`Количество продаж за ${datePeriod}`"
        />
        <info-banner
          :title="'Остатки, шт'"
          :value="headerData.quantity_cnt"
          :profit="0"
          :preloader="bannersPreloader"
          :text="'Товарные остатки на текущий момент'"
        />
        <info-banner
          :title="'Выручка, руб'"
          :value="headerData.proceeds"
          :profit="headerData.proceeds_delta"
          :preloader="bannersPreloader"
          :text="`Общая выручка за ${datePeriod}`"
        />
        <info-banner
          :title="'Упущенная выручка, руб'"
          :value="'Скоро'"
          :profit="''"
          :preloader="bannersPreloader"
          :text="`Упущенная выручка на текущий момент`"
        />
      </div>
      <div class="tabs product__page__tabs" :class="{ disabled: !isPermitByTariff }">
        <x-tabs :tabs="tabs" :selected="tabs.findIndex(el => el.id === pageState)" size="m" @change="selectTab" />
      </div>
      <div class="product__page__hint">
        <page-hint :text="hintText"></page-hint>
      </div>
      <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
        <div class="row__item">
          <x-label :size="'xs'">Показать</x-label>
          <date-select
            :preloader="bannersPreloader || dynamicsPreloade"
            :period-action="defaultPickerAction"
            :period="filters.period"
            @selectPeriod="onSelectPeriod"
          >
          </date-select>
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <fbs-switcher :value="filters.fbs" :disabled="dynamicsPreloade" @input="switchFbs" />
        </div>
      </div>
      <keep-alive>
        <component
          :is="pageState"
          :filters="filters"
          @startLoading="dynamicsPreloade = true"
          @endLoading="dynamicsPreloade = false"
        >
        </component>
      </keep-alive>
      <!-- <date-picker-modal
        ref="datePicker"
        :value="filters.period"
        :default-period="defaultPickerAction"
        @change="onSelectPeriod"
      /> -->
    </div>
  </x-page>
</template>

<style lang="stylus" scoped>
.back__icon
  color: $colors.gray-dark
  width 36px
  height 36px
  border-radius 5px
  display flex
  align-items center
  justify-content center
  cursor pointer
  transition .2s
  margin-right 12px
  flex-shrink 0
  &:hover
    color: $colors.gray-darkest-2
    background $colors.gray-lighter

.title__row
  display flex
  align-items center
  width 100%

.product__page__title
  max-width 600px

.x-icon--m
  margin-right 4px
  flex-shrink 0

.tracked__icon
  cursor pointer
  position relative
  background #fff
  display flex
  width 32px
  height 32px
  align-items center
  justify-content center
  box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
  border-radius 4px
  margin-left 10px
  flex-shrink 0
  color: $colors.gray-dark
  fill: transparent

.header
  position relative
  display flex
  flex-wrap wrap
  margin-bottom 20px

.header__info
  padding 0 20px
  max-width 100%
  display flex
  flex-direction column
  flex-grow: 1
  width 1px
  min-width: 300px

.header__bold
  color: $colors.gray-darkest-2
  line-height 20px
  margin-bottom 4px
  font-size $font-size-m
  font-weight $font-weights.semibold

.header__text
  color: $colors.gray-darker
  font-size $font-size-s
  font-weight $font-weights.normal
  line-height 18px
  display flex

.header__text__big
  text-transform uppercase

.header__text span
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  max-width: 100px

.header__text .header__text__long
  max-width 100%

.header__link
  color: $colors.gray-dark
  cursor pointer
  margin 0 9px 0 5px

.name__link
  color: $colors.gray-darker
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  -webkit-box-orient: vertical;

.rating
  font-size 25px
  display flex
  align-items center
  height 30px

.rating__icon
  position relative
  padding-bottom 6px
  color #ccc

.rating__icon_active
  position absolute
  top 0
  left 0
  color: #FFC539
  overflow hidden

.rating__semibold
  color: $colors.gray-darkest-2
  margin 0 8px
  font-size $font-size-s
  line-height 18px
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.rating__semibold_path
  margin-left 4px
  margin-right 0

.rating__description
  font-size 12px
  color: $colors.gray-dark
  white-space nowrap

.header__path
  padding 0 8px 0 6px
  display inline-flex
  align-items center
  height 28px
  border-radius 4px
  border 1px solid $colors.gray-light
  margin-top auto
  align-self start
  max-width 100%

.header__price
  padding-left 20px
  border-left 1px solid $colors.gray-light
  width 274px
  height 138px

@media (max-width 1170px)
  .x-content--collapsed .header__info
    flex-grow: 1
    padding-right 0

  .x-content--collapsed .header__price
    margin-top 20px
    padding-left 0
    border-left none

@media (max-width 992px)
  .x-content--collapsed .header
    flex-direction column
    align-items center

  .x-content--collapsed .header__info
    padding-left 0
    min-width auto
    width 100%

  .x-content--collapsed .header .x-img
    margin-bottom 20px

  .x-content--collapsed .header__price
    height auto
    width 100%
    min-width auto

@media (max-width 960px)
  .header__info
    flex-grow: 1
    padding-right 0

  .header__price
    margin-top 20px
    padding-left 0
    border-left none

@media (max-width 670px)
  .header
    flex-direction column
    align-items center

  .header__info
    padding-left 0
    min-width auto
    width 100%

  .header .x-img
    margin-bottom 20px

  .header__price
    height auto
    width 100%
    min-width auto

.header__row
  display flex
  align-items center

.header__row_main
  padding-bottom 8px
  border-bottom 2px solid $colors.gray-light
  margin-bottom 8px

.header__semibold
  color: $colors.gray-darkest-2
  font-size $font-size-s
  line-height 20px
  white-space nowrap

.header__price__value
  margin-left auto
  color: $colors.gray-darkest-2
  font-size $font-size-s
  line-height 20px
  font-weight $font-weights.semibold
  display flex

.header__price__value span
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  padding-left 10px
  max-width 130px

.header__doted
  flex-grow: 1
  border-bottom: 1px dashed $colors.gray-light
  height 8px

.banners
  display grid
  grid-template-columns repeat(4, minmax(200px, 1fr))
  grid-gap 12px
  margin-bottom 28px

@media (max-width 1250px)
  .x-content--collapsed .banners
    grid-template-columns repeat(2, minmax(200px, 1fr))

@media (max-width 1200px)
  .banners
    grid-template-columns repeat(2, minmax(200px, 1fr))

@media (max-width 900px)
  .x-content--collapsed .banners
    grid-template-columns minmax(200px, 1fr)

@media (max-width 768px)
  .banners
    grid-template-columns minmax(200px, 1fr)

.product__page__tabs
  margin-bottom 6px
  & .x-tabs
    padding-bottom 6px

.product__page__hint
  margin-bottom 12px

.label__fake
  height 14px
  margin-bottom 7px

.fbs__switcher
  height 35px

.grid__row
  display grid
  grid-template-columns minmax(240px, 400px) auto
  grid-gap 12px
  grid-auto-rows auto
  margin-bottom 24px

@media (max-width 992px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

.disabled
  pointer-events none !important
  opacity .5

.disabled__crumbs
  pointer-events none
</style>

<script>
import toBackPage from '@/mixins/toBackPage.js'
import permissions from '@/mixins/permissions.js'
import PriceDiscountSales from './PriceDiscountSales'
import SalesRemains from './SalesRemains'
import RevenueLostRevenue from './RevenueLostRevenue'
import SalesReviews from './SalesReviews'
import PositionsSales from './PositionsSales'
import { marketPlaceValues } from '@/constants/index.js'
import { getHeaderData } from '@/api/productPage/index.js'

const start = new Date()
const end = new Date()
start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [toBackPage, permissions],
  components: {
    PriceDiscountSales,
    SalesRemains,
    RevenueLostRevenue,
    SalesReviews,
    PositionsSales
  },
  data() {
    return {
      marketPlaceValues,
      pageState:
        this.$route.query.state && ['PriceDiscountSales', 'SalesRemains', 'SalesReviews'].includes(this.$route.query.state)
          ? this.$route.query.state
          : 'PriceDiscountSales',
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      bannersPreloader: false,
      dynamicsPreloade: false,
      outOfStock: false,
      headerData: {
        seller_id: 1,
        regular_price: 0,
        price: 0,
        discount: 0,
        promo_code_discount: 0,
        promo_code_price: 0,
        sales_cnt: 0,
        sales_delta: 0,
        quantity_cnt: 0,
        proceeds: 0,
        proceeds_delta: 0,
        lost_proceeds: 0,
        lost_proceeds_delta: 0
      },
      tabs: [
        {
          id: 'PriceDiscountSales',
          title: 'Цена',
          text: 'Проанализируйте историю изменения цен. Сравните зависимость продаж от цен на товар.',
          eventName: 'orm_products_product_price'
        },
        {
          id: 'SalesRemains',
          title: 'Остатки',
          text: 'Проанализируйте историю изменения остатков. Сравните зависимость продаж от остатков на товар.',
          eventName: 'orm_products_product_stock'
        },
        {
          id: 'RevenueLostRevenue',
          title: 'Упущенная выручка',
          badge: 'скоро',
          disabled: true,
          eventName: 'orm_products_product_loss'
        },
        {
          id: 'PositionsSales',
          title: 'Позиции',
          badge: 'скоро',
          disabled: true,
          eventName: 'orm_products_product_positions'
        },
        {
          id: 'SalesReviews',
          title: 'Отзывы',
          text: 'Проанализируйте историю изменения отзывов и оценку. Сравните зависимость продаж от отзывов и оценки на товар.',
          eventName: 'orm_products_product_review'
        }
      ],
      filters: {
        code: this.$route.query.mp_code,
        productId: this.$route.params.id,
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        fbs: this.$route.query.is_fbs === 'true'
      }
    }
  },
  computed: {
    hintText() {
      const tab = this.tabs.find(el => el.id === this.pageState)
      return tab ? tab.text : 'Неверные параметры !'
    },
    imageUrl() {
      return this.headerData.product_url?.replace(/"/g, '')
    },
    calcWidth() {
      return (100 / 5) * this.headerData.rating
    },
    datePeriod() {
      switch (this.defaultPickerAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${Intl.DateTimeFormat('ru').format(this.filters.period?.start)} - ${Intl.DateTimeFormat('ru').format(
            this.filters.period?.end
          )}`
      }
      return ''
    }
  },
  async created() {
    if (this.isPermitByTariff) {
      this.setUrlParams()
      await this.loadHeader(this.filters.code, this.filters.productId, this.filters.period, this.filters.fbs)

      const tab = this.tabs.find(tab => tab.id === this.pageState)
      if (tab) {
        this.$metrics.hit(tab.eventName, {
          category: this.headerData.category_id,
          marketplace_code: this.filters.code,
          item_id: this.filters.productId
        })
      }
    }
  },
  methods: {
    selectTab(idx) {
      this.pageState = this.tabs[idx].id
      this.setUrlParams()

      this.$metrics.hit(this.tabs[idx].eventName, {
        category: this.headerData.category_id,
        marketplace_code: this.filters.code,
        item_id: this.filters.productId
      })
    },
    // onSelectPeriod(period, selectedAction) {
    //   this.filters = { ...this.filters, period }
    //   this.defaultPickerAction = selectedAction
    //   this.setUrlParams()
    //   this.loadHeader(this.filters.code, this.filters.productId, this.filters.period, this.filters.fbs)
    // },

    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )

      // 6 : 13
      const period = {
        start: currentDate,
        end
      }

      this.defaultPickerAction = val.id
      this.filters = { ...this.filters, period }
      this.loadHeader(this.filters.code, this.filters.productId, this.filters.period, this.filters.fbs)
      this.setUrlParams()
    },
    switchFbs(fbs) {
      this.filters = { ...this.filters, fbs }
      this.loadHeader(this.filters.code, this.filters.productId, this.filters.period, this.filters.fbs)
      this.setUrlParams()
    },
    async loadHeader(code, productId, period, fbs) {
      this.bannersPreloader = true
      try {
        const { data } = await this.$axios.get(...getHeaderData(code, productId, period, fbs))
        if (data && data.data) {
          this.headerData = data.data
          document.title = data.data.product_name
          this.outOfStock = this.headerData.quantity_cnt === 0
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.bannersPreloader = false
      }
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    },
    setUrlParams() {
      if (this.$route.name === 'product_page') {
        const params = new URLSearchParams()

        params.append('mp_code', this.filters.code)
        params.append('state', this.pageState)
        params.append('default_picker_action', this.defaultPickerAction)
        params.append('is_fbs', this.filters.fbs)
        params.append('date_from', this.formatDate(this.filters.period.start, '-'))
        params.append('date_to', this.formatDate(this.filters.period.end, '-'))

        if (this.$route.query.crumbs_text) params.append('crumbs_text', this.$route.query.crumbs_text)

        this.$router.replace(`?${params.toString()}`).catch(() => {})
      }
    }
  }
}
</script>
