<template>
  <div class="page__hint">
    <x-icon name="info" size="xs"></x-icon>
    <p v-if="text" class="page__hint__text">{{ text }}</p>
    <slot></slot>
  </div>
</template>

<style lang="stylus" scoped>
.page__hint
  border-radius 4px
  padding 9.35px
  display flex
  align-items center
  background-color: $colors.cyan-lighten-5
  color: $colors.blue

.page__hint__text
  margin-left 8.33px
  color $colors.gray-darkest-2
  font-size $font-size-s
  line-height 18px
</style>

<script>
export default {
  props: {
    text: {
      type: String
    }
  }
}
</script>
