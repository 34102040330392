<template>
  <div class="list__values__container">
    <template v-for="item in sortedList">
      <div :key="item.external_id" class="list__item">
        <div class="list__item__section" />
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ (+item.sellers_cnt).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.sellers_cnt_delta_percent < 0 }">
            <span>{{ isValue(item.sellers_cnt_delta_percent) }}</span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ (+item.brands_cnt).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.brands_cnt_delta_percent < 0 }">
            <span>{{ isValue(item.brands_cnt_delta_percent) }}</span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ (+item.products_cnt).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.products_cnt_delta_percent < 0 }">
            <span>{{ isValue(item.products_cnt_delta_percent) }}</span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ Math.floor(+item.avg_check).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.avg_check_delta_percent < 0 }">
            <span>{{ isValue(item.avg_check_delta_percent) }}</span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ (+item.sales_cnt).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.sales_cnt_delta_percent < 0 }">
            <span>{{ isValue(item.sales_cnt_delta_percent) }}</span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ Math.floor(+item.proceeds).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.proceeds_delta_percent < 0 }">
            <span>{{ isValue(item.proceeds_delta_percent) }}</span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ Math.floor(+item.product_with_sales).toLocaleString('ru') }}</span>
          </div>
          <div class="profit__value">
            <span></span>
          </div>
        </div>
        <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ formatValue(+item.product_with_sales_percent) }}%</span>
          </div>
          <div class="profit__value">
            <span></span>
          </div>
        </div>
        <!-- <div class="list__item__section">
          <div class="list__item__value">
            <span>{{ +item.lost_proceeds ? Math.floor(+item.lost_proceeds).toLocaleString('ru') : 0 }}</span>
          </div>
          <div class="profit__value" :class="{ dropped__profit: +item.lost_proceeds_delta < 0 }">
            <span>{{ isValue(item.lost_proceeds_delta) }}</span>
          </div>
        </div> -->
      </div>
      <CategoriesValuesList
        v-if="item.opened"
        :list="item.sublist"
        :sort-options="sortOptions"
        :key="item.external_id * Math.random()"
      ></CategoriesValuesList>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.list__item
  position relative
  display grid
  // grid-template-columns:  254px repeat(6, minmax(150px, 1fr)) minmax(220px, 1fr)
  grid-template-columns:  254px repeat(6, minmax(150px, 1fr)) repeat(2, minmax(180px, 1fr)) 10px
  grid-auto-rows 52px
  border-bottom 1px solid $colors.gray-light
  line-height 18px
  font-size $font-size-s
  color $colors.gray-darkest-2

.list__item__section
  display flex
  flex-direction column
  justify-content center
  padding 0 8px

.list__item__value
  width 100%
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.profit__value
  color $colors.success
  font-size 12px
  height 18px
  width 100%
  text-align right
  font-weight 600
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.dropped__profit
  color $colors.error
</style>

<script>
export default {
  name: 'CategoriesValuesList',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    sortOptions: {
      type: Object
    }
  },
  computed: {
    sortedList() {
      return [...this.list].sort((a, b) =>
        this.sortOptions.direction === '1'
          ? b[this.sortOptions.id] - a[this.sortOptions.id]
          : a[this.sortOptions.id] - b[this.sortOptions.id]
      )
    }
  },
  methods: {
    isValue(value) {
      if (value === 0) return ''
      if (value > 0) return `+${Math.round(value).toLocaleString('ru')}%`

      return `${Math.round(value).toLocaleString('ru')}%`
    },
    formatValue(value) {
      if (value === 'undefined') return 0
      return /\./g.test(value) ? value.toFixed(2).replace('.', ',') : value
    }
  }
}
</script>
