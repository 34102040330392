import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getProductsRangeFiltersMaxValue = ({ code, category, brand, period, search, fbs, favoritesOnly }) => {
  const params = new URLSearchParams()

  if (search) params.append('name', search)
  if (category.external_id) params.append('category_id', category.external_id)
  if (brand && brand.brand_id) params.append('brand_id', brand.brand_id)

  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/products/sliders/`, { params }]
}

export const getProducts = ({ code, search, favoritesOnly, fbs, category, brand, period, reviews, rating, favorites, price, sales, remains, revenue, lostRevenue, sortOptions, page, limit }) => {
  const params = new URLSearchParams()

  if (search) params.append('product_name', search)
  if (category && category.external_id) params.append('category_id', category.external_id)
  if (brand && brand.brand_id) params.append('brand_id', brand.brand_id)
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)

  if (reviews && reviews.max) {
    params.append('reviews_cnt_from', Math.ceil(reviews.value[0]))
    params.append('reviews_cnt_to', Math.ceil(reviews.value[1]))
    params.append('reviews_max', Math.ceil(reviews.max))
  }

  if (rating && rating.max) {
    params.append('rating_from', Math.ceil(rating.value[0]))
    params.append('rating_to', Math.ceil(rating.value[1]))
    params.append('rating_max', Math.ceil(rating.max))
  }

  if (favorites && favorites.max) {
    params.append('in_favorites_cnt_from', Math.ceil(favorites.value[0]))
    params.append('in_favorites_cnt_to', Math.ceil(favorites.value[1]))
    params.append('favorites_max', Math.ceil(favorites.max))
  }

  if (price && price.max) {
    params.append('price_from', Math.ceil(price.value[0]))
    params.append('price_to', Math.ceil(price.value[1]))
    params.append('price_max', Math.ceil(price.max))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
    params.append('sales_max', Math.ceil(sales.max))
  }

  if (remains && remains.max) {
    params.append('quantity_cnt_from', Math.ceil(remains.value[0]))
    params.append('quantity_cnt_to', Math.ceil(remains.value[1]))
    params.append('remains_max', Math.ceil(remains.max))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_to', Math.ceil(revenue.value[1]))
    params.append('revenue_max', Math.ceil(revenue.max))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
    // params.append('lost_revenue_max', lostRevenue.max)
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)

  if (page) params.append('page', page)
  if (limit) params.append('limit', limit)

  return [`${base}${code}/products/`, { params }]
}
