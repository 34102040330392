<template>
  <form class="search__bar primary-lightest-2 d-flex p-4 mb-3" @submit.prevent="applySearch">
    <x-input
      v-model.trim="value"
      prepend-icon="search"
      placeholder="Ищите по названию товара или артикулу"
      @input="onInput"
    />
    <x-btn class="ml-4" type="submit">Поиск</x-btn>
  </form>
</template>

<style lang="stylus" scoped>
.search__bar
  border-radius 8px
</style>

<script>
export default {
  props: {
    searchValue: String
  },
  data() {
    return {
      value: this.searchValue
    }
  },
  methods: {
    applySearch() {
      this.$emit('applySearch', this.value)
    },
    onInput(val) {
      if (!val) this.applySearch()
    }
  }
}
</script>
