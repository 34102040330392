<template>
  <label
    class="radio"
  >
    <span class="radio__in">
      <input
        class="radio__input visually-hidden"
        type="radio"
        :name="name"
        :checked="shouldBeChecked"
        :value="value"
        @change="updateInput"
      >
      <span class="radio__pseudo" />
      <span class="radio__text">
        <slot />
      </span>
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change'
  },

  props: {
    value: {
      type: String,
      default: null
    },
    modelValue: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    }
  },

  computed: {
    shouldBeChecked () {
      return this.modelValue === this.value
    }
  },

  methods: {
    updateInput () {
      this.$emit('change', this.value)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .radio
    display block
    line-height 16px
    text-align left
    white-space nowrap
    cursor pointer
    -webkit-tap-highlight-color transparent

    &:hover
      .radio__pseudo
        border-color #5b40ff

    &__in
      position relative
      display inline-block
      vertical-align top
      line-height 1

    &__input

      &:checked
        ~ .radio__pseudo
          background-color #5b40ff
          border-color #5b40ff

          &:after
            opacity 1

      &:focus
        ~ .radio__pseudo
          border-color #5b40ff

    &__pseudo
      position relative
      display inline-block
      vertical-align middle
      width 16px
      height 16px
      background-color transparent
      border 1px solid #cacad3
      border-radius 50%
      box-sizing border-box
      transition background-color ease .3s, border-color ease .3s
      backface-visibility hidden

      &:after
        content ""
        position absolute
        top 50%
        left 50%
        width 8px
        height 8px
        background-color #fff
        border-radius 50%
        transform translate(-50%, -50%)
        opacity 0
        transition opacity linear .1s

    &__text
      display inline-block
      vertical-align middle
      padding-left 4px
      font-size 14px
      line-height 16px
      color #54555f
      white-space normal
      user-select none
</style>
