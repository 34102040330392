<template>
  <div ref="mainContainer" class="list__wrapper">
    <div ref="scrollingContainer" class="list__container">
      <div class="list">
        <div
          class="list__header"
          :style="{
            gridTemplateColumns: `${width}px repeat(${periodList.length}, ${
              periodList.length > 1 ? `minmax(${calcWidth}, 1fr)` : '100px'
            }`
          }"
        >
          <div class="list__header__title"></div>
          <div v-for="item in periodList" :key="item" class="list__header__title">{{ item }}</div>
        </div>
        <div
          v-for="item in datasets"
          :key="item.label"
          class="list__item"
          :style="{
            gridTemplateColumns: `${width}px repeat(${periodList.length}, ${
              periodList.length > 1 ? `minmax(${calcWidth}, 1fr)` : '100px'
            }`
          }"
        >
          <div class="list__item__section"></div>
          <div v-for="row in item.rows" :key="row.value + Math.random()" class="list__item__section">
            <div class="list__item__value">
              <span v-if="row.value !== null">{{ (+row.value).toLocaleString('ru') }}</span>
              <div v-else class="list__item__icon">
                <x-icon name="minus"></x-icon>
              </div>
            </div>
            <!-- zero__profit: Math.round(row.difference) === 0 -->
            <div
              v-if="row.value !== null"
              class="profit__value"
              :class="{
                profit__value_hidden: !row.difference,
                profit__down: +row.difference < 0
              }"
            >
              <span>{{ formatDelta(row.difference) }}%</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
    <div ref="namesContainer" class="names__container">
      <div class="names__container__content">
        <div class="names__container__title">Показатели</div>
        <div v-for="item in datasets" :key="item.label" class="name__item">
          <div class="name__link">{{ item.label }}</div>
        </div>
      </div>
    </div>
    <div class="shadow__container">
      <div class="shadow__content"></div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.x-loader--overlay
  z-index 3

.list__wrapper
  position relative
  overflow hidden
  user-select none
  margin-bottom 20px

.list__container
  position relative
  overflow-x auto
  display flex
  &::-webkit-scrollbar
    height 8px
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    box-shadow 0px 2px 4px rgba(0, 0, 0, 0.05)
    border-radius 4px

.list
  position relative
  padding-bottom 12px
  flex-grow 1

.list__header
  display grid
  grid-auto-rows 34px
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light

.list__header__title
  display flex
  justify-content flex-end
  align-items center
  color $colors.gray-darker
  font-size $font-size-s
  padding 0 10px 0 8px
  white-space nowrap
  font-family Arial, Helvetica, sans-serif

.list__item
  position relative
  display grid
  // grid-template-columns  165px 88px 92px 112px 130px 112px 152px 144px
  grid-auto-rows 52px
  border-bottom 1px solid $colors.gray-light
  line-height 18px
  font-size $font-size-s
  color $colors.gray-darkest-2

.list__item__section
  display flex
  flex-direction column
  justify-content center
  padding 0 10px 0 8px

.list__item__value
  width 100%
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.list__item__icon
  display flex
  align-items center
  justify-content flex-end

.profit__value
  color $colors.success
  height 18px
  width 100%
  font-weight 600
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.profit__value_hidden
  visibility hidden

.profit__down
  color: $colors.error

.zero__profit
  color: $colors.gray-dark

.names__container
  position absolute
  top 0
  left 0
  height calc(100% - 18px)
  background #fff
  &:after
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    background #fff
    top 0
    right  0
    box-shadow 6px 0 18px rgba(0,0,0,.15)

.names__container__content
  position relative
  top 0
  left 0
  background #fff
  z-index 1

.names__container__title
  display flex
  align-items center
  height 36px
  font-size $font-size-s
  color $colors.gray-darker
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light
  padding 0 8px

.name__item
  height 53px
  border-bottom 1px solid $colors.gray-light
  padding 8px 0
  font-size $font-size-s
  color $colors.gray-darkest-2
  -webkit-box-orient: vertical;

.name__link
  padding 0 8px
  line-height 18px
  color $colors.gray-darkest-2

.shadow__container
  position absolute
  top 0
  right -32px
  width 32px
  height calc(100% - 8px)
  &:before
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    top 0
    left 0
    box-shadow -6px 0 18px rgba(0,0,0,.15)

.shadow__content
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff
</style>

<script>
import scrollList from '@/mixins/scrollList.js'
import permissions from '@/mixins/permissions.js'

export default {
  mixins: [scrollList, permissions],
  props: {
    periodList: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    },
    preloader: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: 0
    }
  },
  computed: {
    isData() {
      return this.datasets.some(el => el.data.length)
    },
    calcWidth() {
      if (this.periodList[0].includes('-')) return '170px'
      if (/[а-я]/g.test(this.periodList[0])) return '130px'
      return '110px'
    }
  },
  methods: {
    formatDelta(value) {
      if (value === Math.round(value)) return value > 0 ? `+${value.toLocaleString('ru')}` : value.toLocaleString('ru')
      return value > 0 ? `+${value.toLocaleString('ru').replace(/\d$/, '')}` : value.toLocaleString('ru').replace(/\d$/, '')
    }
  },
  activated() {
    this.width = this.$refs.namesContainer.offsetWidth
  }
}
</script>
