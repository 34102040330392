<template>
  <h2 class="page__title">
    <slot />
  </h2>
</template>

<style lang="stylus" scoped>
.page__title
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  font-size $font-sizes.headline-2

.title__link
  color $colors.gray-darkest-2
  text-decoration none

.title__link__disabled
  pointer-events none
</style>

<script>
export default {
  name: 'PageTitle'
}
</script>
