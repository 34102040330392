<template>
  <div class="category__dynamics">
    <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
      <div class="row__item">
        <x-label :size="'xs'">Показать</x-label>
        <date-select
          :preloader="preloader"
          :period-action="defaultPickerAction"
          :period="filters.period"
          @selectPeriod="onSelectPeriod"
        ></date-select>
        <!-- <div :class="{ disabled: preloader }" @click="$refs.datePicker.$modal.show('datepicker-modal')">
          <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
        </div> -->
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
      </div>
    </div>
    <div class="not-permit-tariff" v-if="!isPermitByTariff">
      <not-permit-by-tariff />
    </div>
    <template v-else>
      <div class="row__container">
        <chart-labels :chart-data="chartData.datasets" :class="{ disabled: preloader }" @hideChart="hideChart"></chart-labels>
        <selected-period :period="filters.period"></selected-period>
      </div>
      <Chart ref="chart" :chart-data="chartDataFiltered" :options="options" :preloader="preloader"/>
      <div class="title__row">
        <h3 class="statistics__title">Анализ динамики</h3>
        <!-- <x-btn class="download__btn" color="gray" :disabled="preloader || downloadPreloader" @click="downloadXLSX">
          <x-icon class="download__icon" name="download" size="s"></x-icon>
          Скачать в XLSX
          <x-loader v-if="downloadPreloader" size="xs"></x-loader>
        </x-btn> -->
      </div>
      <dynamics-list :period-list="chartData.labels" :datasets="chartData.datasets" :preloader="preloader"></dynamics-list>
      <!-- <date-picker-modal ref="datePicker" :value="filters.period" :default-period="defaultPickerAction" @change="onSelectPeriod" /> -->
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.grid__row
  display grid
  grid-template-columns calc(25% - 7px) auto
  grid-gap 12px
  grid-auto-rows auto
  // margin-bottom 24px
  margin-bottom 12px

.label__fake
  margin-bottom 7px
  height 14px

@media (max-width 1660px)
  .x-content--collapsed .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) auto

@media (max-width 1500px)
  .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) auto

@media (max-width 1360px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 1200px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 992px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

.row__container
  display flex
  align-items center
  margin-bottom 5px

.chart__labels
  border-right 1px solid #ededed
  padding-right 12px
  height 26px

@media (max-width 1290px)
  .x-content--collapsed .row__container
    display block

  .x-content--collapsed .chart__labels
    border none

  .x-content--collapsed .current__period
    padding 0 !important

@media (max-width 1080px)
  .row__container
    display block

  .chart__labels
    border none

  .current__period
    padding 0 !important

@media (max-width 1060px)
  .x-content--collapsed .chart__labels
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-auto-rows 36px
    height auto

@media (max-width 800px)
  .x-content--collapsed .chart__labels
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-auto-rows 36px

@media (max-width 840px)
  .chart__labels
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-auto-rows 36px
    height auto

@media (max-width 600px)
  .chart__labels
    grid-template-columns repeat(2, 1fr)

@media (max-width 680px)
  .x-content--collapsed .chart__labels
    grid-template-columns 1fr

@media (max-width 460px)
  .chart__labels
    grid-template-columns 1fr

.title__row
  display flex
  align-items center
  margin 20px 0 12px
  height 36px

.statistics__title
  color rgba(44,44,55,0.898)
  font-size $font-size-l

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit
    cursor inherit

.not-permit-tariff
  position relative
  min-height 370px

.disabled
  opacity .5
  pointer-events none
</style>

<script>
import Chart from '@/ui/chart/Chart'
import DynamicsList from '@/components/DynamicsList.vue'
import ChartLabels from '@/ui/chart/ChartLabels.vue'
import calcPeriod from '@/mixins/calcPeriod.js'
import permissions from '@/mixins/permissions.js'
import { getCategoryDynamics } from '@/api/categories/index.js'

export default {
  mixins: [calcPeriod, permissions],
  components: {
    ChartLabels,
    Chart,
    DynamicsList
  },
  props: {
    filters: {
      type: Object
    },
    defaultPickerAction: {
      type: String
    }
  },
  data() {
    return {
      preloader: false,
      downloadPreloader: false,
      chartData: {
        type: 'line',
        labels: [],
        datasets: [
          {
            id: 'sellers_cnt',
            label: 'Продавцы, шт',
            delta_id: 'sellers_cnt_delta_percent',
            selected: true,
            data: [],
            backgroundColor: '#FD8E39',
            borderColor: ['#FD8E39'],
            borderWidth: 2,
            yAxisID: 'sellers_cnt'
          },
          {
            id: 'products_cnt',
            label: 'Товары, шт',
            delta_id: 'products_cnt_delta_percent',
            selected: true,
            data: [],
            backgroundColor: '#3CC13B',
            borderColor: ['#3CC13B'],
            borderWidth: 2,
            yAxisID: 'products_cnt'
          },
          {
            id: 'brands_cnt',
            label: 'Бренды, шт',
            delta_id: 'brands_cnt_delta_percent',
            selected: true,
            data: [],
            backgroundColor: '#8855DD',
            borderColor: ['#8855DD'],
            borderWidth: 2,
            yAxisID: 'brands_cnt'
          },
          {
            id: 'sales_cnt',
            label: 'Продажи, шт',
            delta_id: 'sales_cnt_delta_percent',
            selected: true,
            data: [],
            backgroundColor: '#43B2D4',
            borderColor: ['#43B2D4'],
            borderWidth: 2,
            yAxisID: 'sales_cnt'
          },
          {
            id: 'remains',
            label: 'Остатки, шт',
            delta_id: 'remains_delta_percent',
            selected: true,
            data: [],
            backgroundColor: '#ED5051',
            borderColor: ['#ED5051'],
            borderWidth: 2,
            yAxisID: 'remains'
          },
          {
            id: 'proceeds',
            label: 'Выручка, руб',
            delta_id: 'proceeds_delta_percent',
            selected: true,
            data: [],
            backgroundColor: '#3E66FB',
            borderColor: ['#3E66FB'],
            borderWidth: 2,
            yAxisID: 'proceeds'
          }
        ]
      },
      options: {
        spanGaps: true,
        interaction: {
          intersect: false
        },
        plugins: {
          tooltip: {
            boxPadding: 5,
            interaction: {
              mode: 'index'
            }
          },
          legend: {
            display: false
            // labels: {
            //   usePointStyle: true,
            //   pointStyle: 'rect'
            // }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3,
        scales: {
          remains: {
            id: 'remains',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#ED5051'
            }
          },
          sales_cnt: {
            id: 'sales_cnt',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#43B2D4'
            }
          },
          brands_cnt: {
            id: 'brands_cnt',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#8855DD'
            }
          },
          products_cnt: {
            id: 'products_cnt',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#3CC13B'
            }
          },
          sellers_cnt: {
            id: 'sellers_cnt',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: true
            },
            ticks: {
              color: '#FD8E39'
            }
          },
          proceeds: {
            id: 'proceeds',
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#3E66FB'
            }
          }
        }
      }
    }
  },
  watch: {
    filters(filters) {
      this.loadDynamics(filters)
      this.setUrlParams()
    }
  },
  computed: {
    chartDataFiltered() {
      return { ...this.chartData, datasets: this.chartData.datasets.filter(el => el.selected) }
    }
  },
  created() {
    if (this.isPermitByTariff) this.loadDynamics(this.filters)
  },
  methods: {
    onSelectPeriod(val) {
      this.$emit('periodChanged', val)
    },
    switchFbs(fbs) {
      this.$emit('switchFbs', fbs)
    },
    hideChart(item) {
      this.options.scales[item.id].display = item.selected
    },
    downloadXLSX() {},
    async loadDynamics(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getCategoryDynamics(filters))
        this.chartData.labels = this.calcPeriod

        if (data && data.data) {
          if (data.data.length > this.calcPeriod.length) data.data.pop()
          this.chartData.datasets.forEach(el => {
            el.rows = data.data.map(item => {
              return {
                value: item[el.id],
                difference: item[el.delta_id]
              }
            })

            el.data = data.data.map(item => item[el.id])
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    },
    setUrlParams() {
      if (this.$route.name === 'category_page') {
        const { fbs, period } = this.filters
        const query = {
          ...this.$route.query,
          is_fbs: fbs,
          default_picker_action: this.defaultPickerAction,
          date_from: this.formatDate(period.start, '-'),
          date_to: this.formatDate(period.end, '-')
        }

        this.$router.replace(`?${new URLSearchParams(query).toString()}`).catch(() => {})
      }
    }
  }
}
</script>
