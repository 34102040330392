<template>
  <div class="category__subcategories">
    <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
      <div class="row__item">
        <x-label :size="'xs'">Показать</x-label>
        <date-select
          :preloader="preloader"
          :period-action="defaultPickerAction"
          :period="filters.period"
          @selectPeriod="onSelectPeriod"
        ></date-select>
        <!-- <div :class="{ disabled: preloader }" @click="$refs.sellerPicker.$modal.show('datepicker-modal')">
          <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
        </div> -->
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
      </div>
      <!-- <div class="row__item">
        <div class="label__fake"></div>
        <filters-button
          :opened="isOpened"
          :changes="slidersChanged"
          @click.native="openFiltersToggle"
          @resetFilters="resetFilters"
        />
      </div> -->
    </div>
    <!-- <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
      <div class="range__grid">
        <range-slider
          v-for="item in rangesFilters"
          :key="item.key"
          :label="item.label"
          :filter-key="item.key"
          :max="item.max"
          :disabled="item.disabled || preloader"
          :value="item.value"
          @rangefilterchanged="onRangeFiltersValue"
        />
        <div class="apply__button__row__item">
          <div class="apply__button__row">
            <div class="label__fake"></div>
            <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
              Применить
            </x-btn>
          </div>
        </div>
      </div>
      <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
    </div> -->
    <!-- <div class="banners">
      <statistic-banner v-for="item in banners" :key="item.id" :data="item"></statistic-banner>
    </div> -->
    <div v-if="isPermitByTariff" class="tracked__container__row">
      <div class="total">
        Всего: <span>{{ categoriesData.total.toLocaleString('ru') }}</span>
      </div>
      <selected-period :period="filters.period"></selected-period>
      <!-- <x-btn class="download__btn" color="gray" :disabled="preloader">
        <x-icon class="download__icon" name="download" size="s"></x-icon>
        Скачать в XLSX
      </x-btn> -->
    </div>
    <categories-list
      :categories-data="categoriesData"
      :preloader="preloader"
      :code="filters.code"
      :period="filters.period"
      :fbs="filters.fbs"
      :sort-options="sortOptions"
      :default-picker-action="defaultPickerAction"
      @startLoading="preloader = true"
      @endLoading="preloader = false"
      @sort="sortList"
    />
    <!-- <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" /> -->
    <!-- <date-picker-modal
      ref="sellerPicker"
      :value="filters.period"
      :default-period="defaultPickerAction"
      @change="onSelectPeriod"
    /> -->
  </div>
</template>

<style lang="stylus" scoped>
.category__subcategories
  display flex
  flex-direction column
  flex-grow 1

// .banners
//   display grid
//   grid-template-columns repeat(3, 1fr)
//   grid-gap 40px
//   margin-bottom 29px

// @media (max-width 1200px)
//   .banners
//     grid-template-columns repeat(auto-fill, minmax(300px, 1fr))

// @media (max-width 1350px)
//   .x-content--collapsed .banners
//     grid-template-columns repeat(auto-fill, minmax(300px, 1fr))

.tracked__container__row
  display flex
  align-items center
  height 36px
  // margin-bottom 12px
  border-top 1px solid transparent
  margin 12px 0

.total
  border-right 1px solid #ededed
  padding-right 12px
  height 100%
  line-height 36px
  font-size $font-size-s
  color: $colors.gray-darkest-2
  & span
    font-weight bold

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit

// .apply__button
//   width 120px
//   margin-left auto

.grid__row
  display grid
  grid-template-columns calc(25% - 7px) auto
  grid-gap 12px
  grid-auto-rows auto
  // margin-bottom 24px
  margin-bottom 12px

.label__fake
  margin-bottom 7px
  height 14px

@media (max-width 1660px)
  .x-content--collapsed .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) auto

@media (max-width 1500px)
  .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) auto

@media (max-width 1360px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 1200px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 992px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

// .grid__row
//   display grid
//   grid-template-columns calc(25% - 7px) 1fr
//   grid-gap 12px
//   grid-auto-rows auto
//   margin-bottom 12px

// @media (max-width 1660px )
//   .x-content--collapsed .grid__row
//     grid-template-columns calc(100% / 12 * 4 - 7px) 1fr

// @media (max-width 1350px )
//   .x-content--collapsed .grid__row
//     grid-template-columns calc(50% - 6px) 1fr

// @media (max-width 1500px)
//   .grid__row
//     grid-template-columns calc(100% / 12 * 4 - 7px) 1fr

// @media (max-width 1200px)
//   .grid__row
//     grid-template-columns calc(50% - 6px) 1fr

// @media (max-width 992px)
//   .x-content--collapsed .grid__row
//     grid-template-columns 1fr

// @media (max-width 768px)
//   .grid__row
//     grid-template-columns 1fr

// .label__fake
//   margin-bottom 7px
//   height 14px

// .filters__range__animate
//   position relative
//   overflow hidden
//   transition .3s
//   border-bottom 1px solid $colors.gray-light
//   margin-bottom 12px

// .apply__button__row__item
//   display flex
//   justify-content flex-end
//   grid-column span 2

// .range__grid
//   display grid
//   grid-template-columns repeat(4, calc(25% - 9px))
//   grid-gap 12px
//   padding 0 2px 12px

// @media (max-width 1500px)
//   .range__grid
//     grid-template-columns repeat(3, 1fr)

//   .apply__button__row__item
//     grid-column span 3

// @media (max-width 1660px)
//   .x-content--collapsed .range__grid
//     grid-template-columns repeat(3, 1fr)

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 3

// @media (max-width 1200px)
//   .range__grid
//     grid-template-columns repeat(2, 1fr)

//   .apply__button__row__item
//     grid-column span 2

// @media (max-width 1350px)
//   .x-content--collapsed .range__grid
//     grid-template-columns repeat(2, 1fr)

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 2

// @media (max-width 992px)
//   .x-content--collapsed .range__grid
//     grid-template-columns 1fr

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 1

// @media (max-width 768px)
//   .range__grid
//     grid-template-columns repeat(1, 1fr)

//   .apply__button__row__item
//     grid-column span 1

.disabled
  pointer-events none
  opacity .5
</style>

<script>
// import filtersToggle from '@/mixins/filtersToggle.js'
// import StatisticBanner from './StatisticBanner.vue'
import CategoriesList from '@/components/categoriesList/CategoriesList'
import permissions from '@/mixins/permissions.js'
import { getCategoriesList } from '@/api/categories/index.js'

export default {
  // mixins: [filtersToggle],
  mixins: [permissions],
  components: {
    // StatisticBanner,
    CategoriesList
  },
  props: {
    filters: {
      type: Object
    },
    defaultPickerAction: {
      type: String
    }
  },
  data() {
    return {
      preloader: false,
      rangePreloader: false,
      // banners: [
      //   {
      //     id: 1,
      //     title: 'Продажи, шт',
      //     list: [
      //       {
      //         id: 1,
      //         color: '#ED5051',
      //         name: 'Бриджи и капри',
      //         value: '156000',
      //         delta: '15'
      //       },
      //       {
      //         id: 2,
      //         color: '#FD8E39',
      //         name: 'Горнолыжные брюки',
      //         value: '143000',
      //         delta: '14'
      //       },
      //       {
      //         id: 3,
      //         color: '#2CC445',
      //         name: 'Джоггеры',
      //         value: '121000',
      //         delta: '13'
      //       },
      //       {
      //         id: 4,
      //         color: '#55D0F5',
      //         name: 'Карго',
      //         value: '98000',
      //         delta: '11'
      //       },
      //       {
      //         id: 5,
      //         color: '#3E66FB',
      //         name: 'Классические брюки',
      //         value: '75000',
      //         delta: '9'
      //       },
      //       {
      //         id: 6,
      //         color: '#E5E5E9',
      //         name: 'Другое',
      //         value: '',
      //         delta: '43'
      //       }
      //     ]
      //   },
      //   {
      //     id: 2,
      //     title: 'Выручка, руб',
      //     list: [
      //       {
      //         id: 1,
      //         color: '#ED5051',
      //         name: 'Бриджи и капри',
      //         value: '156000',
      //         delta: '15'
      //       },
      //       {
      //         id: 2,
      //         color: '#FD8E39',
      //         name: 'Горнолыжные брюки',
      //         value: '143000',
      //         delta: '14'
      //       },
      //       {
      //         id: 3,
      //         color: '#2CC445',
      //         name: 'Джоггеры',
      //         value: '121000',
      //         delta: '13'
      //       },
      //       {
      //         id: 4,
      //         color: '#55D0F5',
      //         name: 'Карго',
      //         value: '98000',
      //         delta: '11'
      //       },
      //       {
      //         id: 5,
      //         color: '#3E66FB',
      //         name: 'Классические брюки',
      //         value: '75000',
      //         delta: '9'
      //       },
      //       {
      //         id: 6,
      //         color: '#E5E5E9',
      //         name: 'Другое',
      //         value: '',
      //         delta: '43'
      //       }
      //     ]
      //   },
      //   {
      //     id: 3,
      //     title: 'Упущенная выручка, руб',
      //     list: [
      //       {
      //         id: 1,
      //         color: '#ED5051',
      //         name: 'Бриджи и капри',
      //         value: '156000',
      //         delta: '15'
      //       },
      //       {
      //         id: 2,
      //         color: '#FD8E39',
      //         name: 'Горнолыжные брюки',
      //         value: '143000',
      //         delta: '14'
      //       },
      //       {
      //         id: 3,
      //         color: '#2CC445',
      //         name: 'Джоггеры',
      //         value: '121000',
      //         delta: '13'
      //       },
      //       {
      //         id: 4,
      //         color: '#55D0F5',
      //         name: 'Карго',
      //         value: '98000',
      //         delta: '11'
      //       },
      //       {
      //         id: 5,
      //         color: '#3E66FB',
      //         name: 'Классические брюки',
      //         value: '75000',
      //         delta: '9'
      //       },
      //       {
      //         id: 6,
      //         color: '#E5E5E9',
      //         name: 'Другое',
      //         value: '',
      //         delta: '43'
      //       }
      //     ]
      //   }
      // ],
      categoriesData: {
        data: [],
        total: 0
      },
      defaultRangsFilters: {},
      rangeFiltersPrevVal: {},
      // rangesFilters: {
      //   sellers: {
      //     label: 'Продавцы',
      //     key: 'sellers',
      //     value: [0, 0],
      //     max: 0,
      //     disabled: false
      //   },
      //   brands: {
      //     label: 'Бренды',
      //     key: 'brands',
      //     value: [0, 0],
      //     max: 0,
      //     disabled: false
      //   },
      //   products: {
      //     label: 'Товары',
      //     key: 'products',
      //     value: [0, 0],
      //     max: 0,
      //     disabled: false
      //   },
      //   avg_check: {
      //     label: 'Средний чек, руб',
      //     key: 'avg_check',
      //     value: [0, 0],
      //     max: 0,
      //     disabled: false
      //   },
      //   sales: {
      //     label: 'Кол-во продаж, шт',
      //     key: 'sales',
      //     value: [0, 0],
      //     max: 0,
      //     disabled: false
      //   },
      //   revenue: {
      //     label: 'Выручка, руб',
      //     key: 'revenue',
      //     value: [0, 0],
      //     max: 0,
      //     disabled: false
      //   }
      //   // lostRevenue: {
      //   //   label: 'Упущенная выручка, руб',
      //   //   key: 'lostRevenue',
      //   //   value: [0, 40000],
      //   //   max: 50000,
      //   //   disabled: false
      //   // }
      // },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: 10,
        offset: 0,
        total: 5,
        page: 1
      }
    }
  },
  watch: {
    filters(filters) {
      this.loadCategories({
        ...filters,
        ...this.rangesFilters,
        sortOptions: this.sortOptions,
        ...this.paginationOptions
      })
      this.setUrlParams()
    }
  },
  created() {
    // this.saveDefaultRangeFilters()
    if (this.isPermitByTariff) {
      this.loadCategories({
        ...this.filters,
        ...this.rangesFilters,
        sortOptions: this.sortOptions,
        ...this.paginationOptions
      })
    }
  },
  activated() {
    this.setUrlParams()
  },
  computed: {
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultRangsFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
  },
  methods: {
    onSelectPeriod(val) {
      this.$emit('periodChanged', val)
    },
    switchFbs(fbs) {
      this.$emit('switchFbs', fbs)
    },
    resetFilters() {
      this.rangesFilters = JSON.parse(JSON.stringify(this.defaultRangsFilters))
    },
    onRangeFiltersValue({ val, key }) {
      this.rangesFilters = { ...this.rangesFilters, [key]: { ...this.rangesFilters[key], value: val } }
    },
    saveDefaultRangeFilters() {
      this.defaultRangsFilters = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    sortList(titleId) {
      if (this.sortOptions.id === titleId) {
        this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
      } else {
        this.sortOptions.direction = '1'
      }
      this.sortOptions.id = titleId
      this.setUrlParams()
      // this.loadSellers({ ...this.filters, ...this.rangeFiltersPrevVal, sortOptions: this.sortOptions, ...this.paginationOptions })
    },
    // applyRangeFilters() {
    //   // this.saveDefaultRangeFilters()
    //   this.paginationOptions.page = 1
    //   // this.setUrlParams()
    //   // this.loadCategories({ ...this.filters, ...this.rangesFilters, sortOptions: this.sortOptions, ...this.paginationOptions })
    // },
    async loadCategories(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getCategoriesList(filters))
        if (data && data.data) {
          this.categoriesData.data = data.data

          this.categoriesData.total = data.paging.total
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    },
    setUrlParams() {
      if (this.$route.name === 'category_page') {
        const params = new URLSearchParams()
        params.append('mp_code', this.filters.code)
        params.append('is_fbs', this.filters.fbs)
        params.append('title', this.$route.query.title)
        params.append('child', this.$route.query.child)
        params.append('state', this.$route.query.state)
        params.append('default_picker_action', this.defaultPickerAction)
        params.append('date_from', this.formatDate(this.filters.period.start, '-'))
        params.append('date_to', this.formatDate(this.filters.period.end, '-'))
        params.append('field', this.sortOptions.id)
        params.append('direction', this.sortOptions.direction)

        // if (this.$route.query.crumbs_text) params.append('crumbs_text', this.$route.query.crumbs_text)

        this.$router.replace(`?${params.toString()}`).catch(() => {})
      }
    }
  }
}
</script>
