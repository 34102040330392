import _axios from '@/plugins/axios'
// import getUser from '@/api/user/index.js'
// import getMarketplaces from '@/api/marketplaces/index.js'
import { getFavoritesSellers } from '@/api/sellers/index.js'
import * as Types from './mutation-types'
import {
  applyTheme,
  clearTheme,
  getCompanyDetails,
  getDefaultCompanyDetails,
  getThemeNameByCabinetType,
  getDefaultThemeName
} from '@xway-team/theme-detector'
import { setCompanyDetails } from '@/plugins/companyDetails.js'
import { setCurrentThemeName } from '@/plugins/currentTheme.js'
import { sellerApi } from '@/api/sellerApi.js'

export default {
  async getUser({ commit }) {
    commit(`${Types.SET_STATUS_IS_LOADING}`, true)
    try {
      const { data } = await sellerApi.getUser()
      if (data && data.data) {
        commit(`${Types.UPDATE_USER}`, data.data)
        commit(`${Types.SET_USER_STATUS}`, data.data.isVip)

        if (data.data.is_demo_account) return
        const themeName = getThemeNameByCabinetType(data.data.cabinet_type)
        if (themeName) {
          applyTheme(themeName)
          setCompanyDetails(getCompanyDetails(themeName))
          setCurrentThemeName(themeName)
        } else {
          clearTheme()
          setCompanyDetails(getDefaultCompanyDetails())
          setCurrentThemeName(getDefaultThemeName())
        }
      }
    } catch (err) {
      console.log(err)
    } finally {
      commit(`${Types.SET_STATUS_IS_LOADING}`, false)
    }
  },

  async loadSellersFavoritesList({ commit }, mpCode) {
    try {
      const { data } = await _axios.get(getFavoritesSellers(mpCode))
      if (data && data.data) commit(`${Types.UPDATE_SELLERS_FAVORITES_LIST}`, data.data.sellers ? data.data.sellers : [])
    } catch (err) {
      console.log(err)
    }
  }
}
