var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"categories"},_vm._l((_vm.sortedList),function(item,index){return _c('div',{key:item.category_id,staticClass:"categories__item__container",class:{ not__active: !item.has_children },on:{"click":function($event){$event.stopPropagation();return _vm.openSubList(item, index)}}},[_c('div',{staticClass:"categories__item"},[_c('div',{staticClass:"categories__item__row",style:({ paddingLeft: (_vm.padding + "px") })},[_c('x-icon',{staticClass:"categories__arrow",class:{ arrow__active: item.opened },style:({ visibility: item.has_children ? 'visible' : 'hidden' }),attrs:{"name":"expand-arrow","size":"xxs"}}),_c('x-icon',{attrs:{"name":_vm.marketPlaceValues[item.mp_code].icon,"size":"s"}}),_c('div',{on:{"!click":function($event){return _vm.sendEvent(item)}}},[_c('x-link',{staticClass:"categories__name",attrs:{"to":{
              name: 'category_page',
              params: { id: item.category_id },
              query: {
                mp_code: item.mp_code,
                title: item.category_name,
                child: item.has_children,
                is_fbs: _vm.fbs.toString(),
                default_picker_action: _vm.defaultPickerAction,
                date_from: _vm.formatDate(_vm.period.start),
                date_to: _vm.formatDate(_vm.period.end)
              }
            }}},[_vm._v(" "+_vm._s(item.category_name)+" ")])],1)],1),(item.opened)?_c('categories-names',{attrs:{"list":item.sublist,"sort-options":_vm.sortOptions,"get-sublist":_vm.getSublist,"padding":_vm.padding + 10,"code":_vm.code,"period":_vm.period,"fbs":_vm.fbs,"default-picker-action":_vm.defaultPickerAction},on:{"startLoading":function($event){return _vm.$emit('startLoading')},"endLoading":function($event){return _vm.$emit('endLoading')}}}):_vm._e()],1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }