import { datesFormatting } from '../index.js'

const base = 'api/v1/'

// export const getBrands = (code, categoryId, search, page, sellerId) => {
//   const params = new URLSearchParams()

//   if (categoryId) params.append('category_id', categoryId)
//   if (search) params.append('name', search)
//   params.append('page', page)
//   params.append('limit', 50)
//   params.append('seller_id', sellerId)

//   return [`${base}${code}/shared/brands/`, { params }]
// }

export const getBrandsRangeFiltersMaxValue = ({ code, category, period, search, fbs, favoritesOnly }) => {
  const params = new URLSearchParams()

  if (search) params.append('name', search)
  if (category.external_id) params.append('category_id', category.external_id)
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/brands/sliders/`, { params }]
}

export const getBrandsList = ({ code, search, favoritesOnly, fbs, category, period, products, sellers, cheque, sales, remains, revenue, lostRevenue, sortOptions, page, limit }) => {
  const params = new URLSearchParams()

  if (search) params.append('name', search)
  if (category && category.external_id) params.append('category_id', category.external_id)
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)

  if (products && products.max) {
    params.append('products_cnt_from', Math.ceil(products.value[0]))
    params.append('products_cnt_to', Math.ceil(products.value[1]))
    params.append('products_max', Math.ceil(products.max))
  }

  if (sellers && sellers.max) {
    params.append('sellers_cnt_from', Math.ceil(sellers.value[0]))
    params.append('sellers_cnt_to', Math.ceil(sellers.value[1]))
    params.append('sellers_max', Math.ceil(sellers.max))
  }

  if (cheque && cheque.max) {
    params.append('avg_check_from', Math.ceil(cheque.value[0]))
    params.append('avg_check_to', Math.ceil(cheque.value[1]))
    params.append('cheque_max', Math.ceil(cheque.max))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
    params.append('sales_max', Math.ceil(sales.max))
  }

  if (remains && remains.max) {
    params.append('quantity_cnt_from', Math.ceil(remains.value[0]))
    params.append('quantity_cnt_to', Math.ceil(remains.value[1]))
    params.append('remains_max', Math.ceil(remains.max))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_cnt_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_cnt_to', Math.ceil(revenue.value[1]))
    params.append('revenue_max', Math.ceil(revenue.max))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
    // params.append('lost_revenue_max', lostRevenue.max)
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)

  if (page) params.append('page', page)
  if (limit) params.append('limit', limit)

  return [`${base}${code}/brands/`, { params }]
}
