export default {
  data () {
    return {
      flag: true,
      isOpened: false,
      delay: 300
    }
  },
  mounted() {
    if (this.slidersChanged) this.openFiltersToggle()
  },
  methods: {
    openFiltersToggle () {
      if (!this.flag) return false
      this.flag = false
      const animateBlock = this.$refs.animateBlock
      const childrenBlock = animateBlock.children[0]

      if (animateBlock.style.height === '0px') {
        animateBlock.style.height = childrenBlock.offsetHeight + 'px'
        setTimeout(() => {
          this.flag = true
          this.isOpened = true
          animateBlock.style.height = 'auto'
        }, this.delay)
      } else {
        animateBlock.style.height = childrenBlock.offsetHeight + 'px'
        setTimeout(() => { animateBlock.style.height = '0px' }, 10)
        setTimeout(() => {
          this.flag = true
          this.isOpened = false
        }, this.delay)
      }
    }
  }
}
