import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getHeaderData = (code, productId, period, fbs) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/product/${productId}/`, { params }]
}

export const getPriceDiscountSales = ({ code, productId, period, fbs }) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/product/${productId}/price-discount-sales`, { params }]
}

export const getRemainsSales = ({ code, productId, period, fbs }) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/product/${productId}/remains`, { params }]
}

export const getReviewsSales = ({ code, productId, period, fbs }) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/product/${productId}/reviews`, { params }]
}
