import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getCategoriesRangeFiltersMaxValue = ({ code, categoryId, period, fbs, search }) => {
  const params = new URLSearchParams()

  params.append('mp_code', code)
  params.append('is_fbs', fbs)
  if (categoryId) params.append('category_id', categoryId)
  if (search) params.append('name', search)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/categories/sliders/`, { params }]
}

export const getCategoriesList = ({ code, search, category, period, fbs, sellers, brands, products, avgCheck, sales, revenue, lostRevenue }) => {
  const params = new URLSearchParams()

  if (search) params.append('name', search)
  if (category && category.external_id) params.append('category_id', category.external_id)
  params.append('is_fbs', fbs)

  if (sellers && sellers.max) {
    params.append('sellers_cnt_from', Math.ceil(sellers.value[0]))
    params.append('sellers_cnt_to', Math.ceil(sellers.value[1]))
    params.append('sellers_max', Math.ceil(sellers.max))
  }

  if (brands && brands.max) {
    params.append('brands_cnt_from', Math.ceil(brands.value[0]))
    params.append('brands_cnt_to', Math.ceil(brands.value[1]))
    params.append('brands_max', Math.ceil(brands.max))
  }

  if (products && products.max) {
    params.append('products_cnt_from', Math.ceil(products.value[0]))
    params.append('products_cnt_to', Math.ceil(products.value[1]))
    params.append('products_max', Math.ceil(products.max))
  }

  if (avgCheck && avgCheck.max) {
    params.append('avg_check_from', Math.ceil(avgCheck.value[0]))
    params.append('avg_check_to', Math.ceil(avgCheck.value[1]))
    params.append('cheque_max', Math.ceil(avgCheck.max))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
    params.append('sales_max', Math.ceil(sales.max))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_to', Math.ceil(revenue.value[1]))
    params.append('revenue_max', Math.ceil(revenue.max))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
    // params.append('lost_revenue_max', lostRevenue.max)
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/categories/`, { params }]
}

export const getCategoryDynamics = ({ code, category, period, fbs }) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/category/${category.external_id}/dynamic/`, { params }]
}
