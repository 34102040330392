<template>
  <div class="range__slider" :class="{ no__allowed: disabled }">
    <x-label :size="'xs'">{{ label }}</x-label>
    <div class="vue__slide__container" @click="flag = true">
      <vue-slider
        v-model="defaultValue"
        :height="2"
        :dot-size="12"
        :use-keyboard="false"
        :tooltip="'none'"
        :enable-cross="false"
        :max="max"
        :disabled="disabled"
        :duration="0.2"
        @drag-start="flag = false"
        @drag-end="$emit('rangefilterchanged', { val: defaultValue, key: filterKey })"
      />
    </div>
    <div class="range__slider__inputs__row">
      <div class="input__wrapper">
        От
        <input
          class="range__slider__input"
          type="text"
          :value="defaultValue[0].toLocaleString('ru')"
          :disabled="disabled"
          @input="setMin"
        />
      </div>
      <div class="line__between" />
      <div class="input__wrapper">
        До
        <input
          class="range__slider__input"
          type="text"
          :value="defaultValue[1].toLocaleString('ru')"
          :disabled="disabled"
          @input="setMax"
        />
      </div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.x-label
  pointer-events none

.range__slider__inputs__row
  display flex
  align-items center
  margin-top 8px

.input__wrapper
  padding  0 4px
  height 20px
  width 100%
  display flex
  align-items center
  max-width 118px
  border 1px solid $colors.gray-lighter
  border-radius 4px
  font-size 12px
  color $colors.gray-dark

.range__slider__input
  padding-left 4px
  font-size $font-size-s
  color $colors.gray-darkest-2
  border none
  outline none
  display block
  width 100%

.line__between
  flex-grow 1
  background-color  $colors.gray-light
  height 1px
  min-width 10px

.no__allowed
  cursor not-allowed

.no__allowed input:disabled
  cursor not-allowed
</style>

<script>
import './default.css'
import VueSlider from './vue-slider-component'

export default {
  components: {
    VueSlider
  },
  props: {
    label: {
      type: String
    },
    filterKey: {
      type: String
    },
    max: {
      type: Number,
      default: 50000
    },
    value: {
      type: Array,
      default: () => [0, 30000]
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultValue: '',
      flag: false
      // inputTimeout: null
    }
  },
  watch: {
    value(val) {
      this.flag = false
      this.defaultValue = [...val]
    },
    defaultValue(val) {
      setTimeout(() => {
        if (this.flag) this.$emit('rangefilterchanged', { val: this.defaultValue, key: this.filterKey })
      }, 10)
    }
  },
  created() {
    this.defaultValue = [...this.value]
  },
  methods: {
    setMin(e) {
      this.flag = true
      e.target.value = this.onlyNumbers(e.target.value)
      e.target.value = Math.min(+e.target.value, this.defaultValue[1])

      if (this.validAction(e)) {
        // clearTimeout(this.inputTimeout)

        // this.inputTimeout = setTimeout(() => {
        this.defaultValue[0] = +e.target.value
        this.defaultValue = [...this.defaultValue]
        // }, 700)
      }
    },
    setMax(e) {
      this.flag = true
      e.target.value = this.onlyNumbers(e.target.value)
      e.target.value = Math.min(e.target.value, this.max)

      if (this.validAction(e)) {
        // clearTimeout(this.inputTimeout)

        // this.inputTimeout = setTimeout(() => {
        this.defaultValue[1] = +e.target.value
        this.defaultValue = [...this.defaultValue]
        // }, 700)
      }
    },
    onlyNumbers(value) {
      return value ? value.replace(/[^\d]/g, '') : ''
    },
    validAction(e) {
      return (
        /[\d]/.test(e.data) ||
        e.inputType === 'deleteContentBackward' ||
        e.inputType === 'deleteContentForward' ||
        e.inputType === 'historyUndo'
      )
    }
  }
}
</script>
