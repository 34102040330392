<template>
  <!-- <div class="periods__picker">
    <div class="periods__picker__value">{{ datePeriod }}</div>
    <div class="calendar__icon" @click.stop>
      <x-icon name="calendar" size="s"></x-icon>
    </div> -->
  <!-- :placeholder="datePeriod" -->
  <x-select
    :class="{ disabled: preloader }"
    :value="selectedItem"
    :options="dateList"
    :track-by="'id'"
    :label="'label'"
    :prependIcon="'calendar'"
    @input="selectPeriod"
  ></x-select>
  <!-- </div> -->
</template>

<style lang="stylus">

.periods__picker
  position relative
  background #FFFFFF
  border 1px solid $colors.gray
  line-height 36px
  font-size $font-size-s
  color $colors.gray-darkest-2
  border-radius 4px
  height 36px
  display flex
  user-select none

.periods__picker__value
  flex-grow 1
  padding 0 8px
  cursor pointer
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.calendar__icon
  width 36px
  height 100%
  display flex
  align-items center
  justify-content center
  flex-shrink 0
  border-left 1px solid $colors.gray
  color: $colors.gray-dark
</style>

<script>
export default {
  props: {
    period: {
      type: Object
    },
    periodAction: {
      type: String
    },
    preloader: {
      type: Boolean
    }
  },
  data() {
    return {
      dateList: [
        {
          id: 'SEVEN',
          days: 7,
          label: '7 дней'
        },
        {
          id: 'FOURTEEN',
          days: 14,
          label: '14 дней'
        },
        {
          id: 'THIRTY',
          days: 30,
          label: '30 дней'
        },
        {
          id: 'SIXTY',
          days: 60,
          label: '60 дней'
        },
        {
          id: 'NINETY',
          days: 90,
          label: '90 дней'
        }
      ]
    }
  },
  computed: {
    selectedItem() {
      return this.dateList.find(el => el.id === this.periodAction)
    }
    // datePeriod() {
    //   switch (this.periodAction) {
    //     case 'SEVEN':
    //       return '7 дней'
    //     case 'FOURTEEN':
    //       return '14 дней'
    //     case 'OTHER':
    //       return `${Intl.DateTimeFormat('ru').format(this.period?.start)} - ${Intl.DateTimeFormat('ru').format(this.period?.end)}`
    //   }
    //   return ''
    // }
  },
  methods: {
    selectPeriod(val) {
      this.$emit('selectPeriod', val)
    }
  }
}
</script>
