<template>
  <div class="brand__products">
    <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
      <div class="row__item">
        <x-label :size="'xs'">Категория</x-label>
        <div :class="{ disabled: preloader }" @click="$emit('categoriesModalOpen')">
          <category-button :value="filters.category" @input="clearCategory" />
        </div>
      </div>
      <div class="row__item">
        <x-label :size="'xs'">Показать</x-label>
        <date-select
          :preloader="preloader"
          :period-action="defaultPickerAction"
          :period="filters.period"
          @selectPeriod="onSelectPeriod"
        ></date-select>
        <!-- <div :class="{ disabled: preloader }" @click="$refs.datePicker.$modal.show('datepicker-modal')">
              <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
            </div> -->
      </div>
      <div class="row__item">
        <x-label :size="'xs'">Поиск</x-label>
        <x-input v-model.trim="searchValue" prepend-icon="search" placeholder="По наименованию" />
      </div>
      <div class="row__item"></div>
      <div class="row__item">
        <div class="label__fake"></div>
        <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <filters-button
          :opened="isOpened"
          :changes="slidersChanged"
          @click.native="openFiltersToggle"
          @resetFilters="resetRangeFilters"
        />
      </div>
    </div>
    <div class="filters__range">
      <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
        <div class="range__grid" :class="{ disabled: !isPermitByTariff }">
          <range-slider
            v-for="item in rangesFilters"
            :key="item.key"
            :label="item.label"
            :filter-key="item.key"
            :max="item.max"
            :disabled="item.disabled || preloader"
            :value="item.value"
            @rangefilterchanged="onRangeFiltersValue"
          />
          <div class="apply__button__row__item">
            <div class="apply__button__row">
              <div class="label__fake"></div>
              <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
                Применить
              </x-btn>
            </div>
          </div>
        </div>
        <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
      </div>
    </div>
    <div v-if="isPermitByTariff" class="tracked__container__row">
      <div class="total">
        Всего: <span>{{ productsData.total.toLocaleString('ru') }}</span>
      </div>
      <!-- <div class="tracked">
        <Switcher v-model="filters.favoritesOnly" :disabled="preloader || !productsData.data.length" />
        <div class="tracked__label">Только отслеживаемые: 1</div>
      </div> -->
      <selected-period :period="filters.period"></selected-period>
      <div class="download__btn__container">
        <!-- <x-btn
          class="download__btn"
          color="gray"
          :disabled="preloader || !productsData.data.length || downloadPreloader"
          @click="downloadXLSX"
        >
          <x-icon class="download__icon" name="download" size="s"></x-icon>
          Скачать в XLSX
          <x-loader v-if="downloadPreloader" size="xs"></x-loader>
        </x-btn> -->
        <tooltip class="arrow__offset">Максимальный размер скачивания 1 000 строк.</tooltip>
      </div>
    </div>
    <!-- :prev-page-name="headerData.brand_name" -->
    <products-list
      :products-data="productsData"
      :preloader="preloader"
      :sort-options="sortOptions"
      :period="filters.period"
      :fbs="filters.fbs"
      :category="filters.category"
      :more-columns="true"
      :default-picker-action="defaultPickerAction"
      @sort="sortProductsList"
    />
    <x-paginator v-model="paginationOptions" size="xs" :max-page-count="5" />
    <!-- <date-picker-modal
        ref="datePicker"
        :value="filters.period"
        :default-period="defaultPickerAction"
        @change="onSelectPeriod"
      /> -->
  </div>
</template>

<style lang="stylus" scoped>
.brand__products
  display flex
  flex-direction column
  flex-grow 1

.x-label
  pointer-events none

.grid__row
  display grid
  grid-template-columns repeat(2, calc(25% - 9px)) 1fr auto auto
  grid-auto-rows auto
  grid-gap 12px
  margin-bottom 12px

.row__item
  &:nth-child(4)
    display none

@media (max-width 1280px)
  .grid__row
    grid-template-columns repeat(3, minmax(254px, 1fr))

  .row__item:last-of-type
    grid-column span 2
    display flex
    flex-direction column
    align-items flex-end

@media (max-width 1470px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .row__item:last-of-type
    grid-column span 2
    display flex
    flex-direction column
    align-items flex-end

@media (max-width 1050px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed  .row__item
    &:nth-child(4)
      display block

  .x-content--collapsed  .row__item:last-of-type
    grid-column span 1

@media (max-width 900px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

  .x-content--collapsed  .row__item
    &:nth-child(4)
      display none

  .x-content--collapsed  .row__item:last-of-type
    align-items start
    grid-column span 1

@media (max-width 992px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

  .row__item
    &:nth-child(4)
      display block

  .row__item:last-of-type
    grid-column span 1

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

  .row__item:nth-child(4)
    display none

  .row__item:last-of-type
    align-items: start

.label__fake
  margin-bottom 7px
  height 14px

.filters__range
  border-bottom 1px solid $colors.gray-light

.filters__range__animate
  position relative
  overflow hidden
  transition .3s

.apply__button__row__item
  grid-column span 2
  display flex

.range__grid
  display grid
  grid-template-columns repeat(4, calc(25% - 9px))
  grid-gap 12px
  padding 0 2px 12px

@media (max-width 1500px)
  .range__grid
    grid-template-columns repeat(3, 1fr)

  .apply__button__row__item
    grid-column span 3

// @media (max-width 1660px)
//   .x-content--collapsed .range__grid
//     grid-template-columns repeat(3, minmax(200px, 1fr))

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 3

@media (max-width 1200px)
  .range__grid
    grid-template-columns repeat(2, 1fr)

  .apply__button__row__item
    grid-column span 2

  .x-content--collapsed .range__grid
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 2

@media (max-width 992px)
  .x-content--collapsed .range__grid
    grid-template-columns 1fr

  .x-content--collapsed .apply__button__row__item
    grid-column span 1

@media (max-width 850px)
  .range__grid
    grid-template-columns 1fr

  .apply__button__row__item
    grid-column span 1

.apply__button__row
  margin-left auto

.apply__button
  width 120px

.x-loader--overlay
  z-index 5

.tracked__container__row
  display flex
  align-items center
  height 36px
  margin 12px 0

.total
  border-right 1px solid #ededed
  padding-right 12px
  height 100%
  font-size $font-size-s
  color $colors.gray-darkest-2
  line-height 36px
  & span
    font-weight bold

.tracked
  display flex
  align-items center
  padding-left 16px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn__container
  position relative
  margin-left auto
  &:hover
    .tooltip
      visibility visible
      opacity 1

.download__btn__container .tooltip
  left -7px
  bottom 46px
  white-space nowrap

.download__btn
  position relative
  &:disabled .download__icon
    color inherit
    cursor inherit

.x-btn:disabled + .tooltip
  visibility hidden
  opacity 0

.download__btn .x-loader
  transform translateX(6px)

.disabled
  pointer-events none
  opacity .5
</style>

<script>
import filtersToggle from '@/mixins/filtersToggle.js'
import permissions from '@/mixins/permissions.js'
import productsList from '@/components/productsList.vue'
import { getProductsRangeFiltersMaxValue, getProducts } from '@/api/products/index.js'

export default {
  mixins: [filtersToggle, permissions],
  components: {
    productsList
  },
  props: {
    filters: {
      type: Object
    },
    defaultPickerAction: {
      type: String
    },
    headerData: {
      type: Object
    }
  },
  data() {
    return {
      searchTimeout: null,
      searchValue: this.$route.query.product_name || '',
      preloader: false,
      rangePreloader: false,
      downloadPreloader: false,
      productsData: {
        data: [],
        total: 0
      },
      defaultRangesFilters: {},
      rangeFiltersPrevVal: {},
      rangesFilters: {
        reviews: {
          label: 'Отзывы',
          key: 'reviews',
          value: [+this.$route.query.reviews_cnt_from || 0, +this.$route.query.reviews_cnt_to || 0],
          max: +this.$route.query.reviews_max || 0,
          disabled: !+this.$route.query.reviews_max
        },
        rating: {
          label: 'Оценка',
          key: 'rating',
          value: [+this.$route.query.rating_from || 0, +this.$route.query.rating_to || 0],
          max: +this.$route.query.rating_max || 0,
          disabled: !+this.$route.query.rating_max
        },
        // favorites: {
        //   label: 'В избранном',
        //   key: 'favorites',
        //   value: [+this.$route.query.in_favorites_cnt_from || 0, +this.$route.query.in_favorites_cnt_to || 0],
        //   max: +this.$route.query.favorites_max || 0,
        //   disabled: !+this.$route.query.favorites_max
        // },
        price: {
          label: 'Цена со скидкой, руб',
          key: 'price',
          value: [+this.$route.query.price_from || 0, +this.$route.query.price_to || 0],
          max: +this.$route.query.price_max || 0,
          disabled: !+this.$route.query.price_max
        },
        sales: {
          label: 'Кол-во продаж, шт',
          key: 'sales',
          value: [+this.$route.query.sales_cnt_from || 0, +this.$route.query.sales_cnt_to || 0],
          max: +this.$route.query.sales_max || 0,
          disabled: !+this.$route.query.sales_max
        },
        remains: {
          label: 'Остатки, шт',
          key: 'remains',
          value: [+this.$route.query.quantity_cnt_from || 0, +this.$route.query.quantity_cnt_to || 0],
          max: +this.$route.query.remains_max || 0,
          disabled: !+this.$route.query.remains_max
        },
        revenue: {
          label: 'Выручка, руб',
          key: 'revenue',
          value: [+this.$route.query.proceeds_from || 0, +this.$route.query.proceeds_to || 0],
          max: +this.$route.query.revenue_max || 0,
          disabled: !+this.$route.query.revenue_max
        }
        // lostRevenue: {
        //   label: 'Упущенная выручка, руб',
        //   key: 'lostRevenue',
        //   value: [0, 0],
        //   max: 0,
        //   disabled: true
        // }
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: +this.$route.query.limit || 10,
        offset: 0,
        total: 0,
        page: +this.$route.query.page || 1
      },
      favoritesOnly: this.$route.query.favorites_only === 'true'
    }
  },
  watch: {
    filters(filters) {
      this.paginationOptions.page = 1
      this.loadSliders({ ...filters, favoritesOnly: this.favoritesOnly, search: this.searchValue })
    },
    searchValue(val) {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        if (val.length > 2 || !val.length) {
          this.paginationOptions.page = 1
          this.loadSliders({ ...this.filters, favoritesOnly: this.favoritesOnly, search: val })
        }
      }, 700)
    },
    paginationOptions() {
      this.setUrlParams()
      this.loadProducts({
        ...this.filters,
        search: this.searchValue,
        favoritesOnly: this.favoritesOnly,
        sortOptions: this.sortOptions,
        ...this.rangeFiltersPrevVal,
        ...this.paginationOptions
      })
    }
  },
  computed: {
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultRangesFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
  },
  created() {
    this.saveDefaultRangeFilters()
    if (this.isPermitByTariff) {
      this.saveRangeFiltersValues()

      if (!this.rangesFilters.price.max) {
        this.loadSliders({ ...this.filters, favoritesOnly: this.favoritesOnly, search: this.searchValue })
      } else {
        this.rangePreloader = false
        this.loadProducts({
          ...this.filters,
          search: this.searchValue,
          favoritesOnly: this.favoritesOnly,
          sortOptions: this.sortOptions,
          ...this.rangeFiltersPrevVal,
          ...this.paginationOptions
        })
      }
    }
  },
  activated() {
    this.setUrlParams()
  },
  methods: {
    clearCategory(category) {
      this.$emit('clearCategory', category)
    },
    onSelectPeriod(val) {
      this.$emit('periodChanged', val)
    },
    switchFbs(fbs) {
      this.$emit('switchFbs', fbs)
    },
    onRangeFiltersValue({ val, key }) {
      this.rangesFilters[key].value = val
    },
    saveDefaultRangeFilters() {
      this.defaultRangesFilters = {
        ...this.rangesFilters,
        ...JSON.parse(
          JSON.stringify({
            reviews: {
              label: 'Отзывы',
              key: 'reviews',
              value: [0, this.rangesFilters.reviews.max],
              max: this.rangesFilters.reviews.max,
              disabled: this.rangesFilters.reviews.max === 0
            },
            rating: {
              label: 'Оценка',
              key: 'rating',
              value: [0, this.rangesFilters.rating.max],
              max: this.rangesFilters.rating.max,
              disabled: this.rangesFilters.rating.max === 0
            },
            // favorites: {
            //   label: 'В избранном',
            //   key: 'favorites',
            //   value: [0, this.rangesFilters.favorites.max],
            //   max: this.rangesFilters.favorites.max,
            //   disabled: this.rangesFilters.favorites.max === 0
            // },
            price: {
              label: 'Цена со скидкой, руб',
              key: 'price',
              value: [0, this.rangesFilters.price.max],
              max: this.rangesFilters.price.max,
              disabled: this.rangesFilters.price.max === 0
            },
            sales: {
              label: 'Кол-во продаж, шт',
              key: 'sales',
              value: [0, this.rangesFilters.sales.max],
              max: this.rangesFilters.sales.max,
              disabled: this.rangesFilters.sales.max === 0
            },
            remains: {
              label: 'Остатки, шт',
              key: 'remains',
              value: [0, this.rangesFilters.remains.max],
              max: this.rangesFilters.remains.max,
              disabled: this.rangesFilters.remains.max === 0
            },
            revenue: {
              label: 'Выручка, руб',
              key: 'revenue',
              value: [0, this.rangesFilters.revenue.max],
              max: this.rangesFilters.revenue.max,
              disabled: this.rangesFilters.revenue.max === 0
            }
            // lostRevenue: {
            //   label: 'Упущенная выручка, руб',
            //   key: 'lostRevenue',
            //   value: [0, this.rangesFilters.lostRevenue.max],
            //   max: this.rangesFilters.lostRevenue.max,
            //   disabled: this.rangesFilters.lostRevenue.max === 0
            // }
          })
        )
      }
    },
    saveRangeFiltersValues() {
      this.rangeFiltersPrevVal = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    applyRangeFilters() {
      this.saveRangeFiltersValues()
      this.paginationOptions.page = 1
      this.setUrlParams()
      this.loadProducts({
        ...this.filters,
        search: this.searchValue,
        favoritesOnly: this.favoritesOnly,
        sortOptions: this.sortOptions,
        ...this.rangesFilters,
        ...this.paginationOptions
      })
    },
    resetRangeFilters() {
      for (const k in this.defaultRangesFilters) {
        this.rangesFilters[k].value = this.defaultRangesFilters[k].value
      }
    },
    sortProductsList(titleId) {
      if (titleId !== 'seller_name') {
        if (this.sortOptions.id === titleId) {
          this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
        } else {
          this.sortOptions.direction = '1'
        }
        this.sortOptions.id = titleId

        this.setUrlParams()
        this.loadProducts({
          ...this.filters,
          search: this.searchValue,
          favoritesOnly: this.favoritesOnly,
          sortOptions: this.sortOptions,
          ...this.rangesFilters,
          ...this.paginationOptions
        })
      }
    },
    async loadSliders(filters) {
      this.$emit('loadingStarted')
      this.rangePreloader = true
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getProductsRangeFiltersMaxValue(filters))

        const res = data.data

        if (res) {
          for (const k in res) res[k] = Math.ceil(res[k])
        }

        this.rangesFilters.reviews.value = [0, res.reviews_max]
        this.rangesFilters.reviews.disabled = res.reviews_max === 0

        this.rangesFilters.rating.value = [0, res.rating_max]
        this.rangesFilters.rating.disabled = res.rating_max === 0

        // this.rangesFilters.favorites.value = [0, res.in_favorites_max]
        // this.rangesFilters.favorites.disabled = res.in_favorites_max === 0

        this.rangesFilters.price.value = [0, res.price_max]
        this.rangesFilters.price.disabled = res.price_max === 0

        this.rangesFilters.sales.value = [0, res.sales_max]
        this.rangesFilters.sales.disabled = res.sales_max === 0

        this.rangesFilters.remains.value = [0, res.quantity_max]
        this.rangesFilters.remains.disabled = res.quantity_max === 0

        this.rangesFilters.revenue.value = [0, res.proceeds_max]
        this.rangesFilters.revenue.disabled = res.proceeds_max === 0

        // this.rangesFilters.lostRevenue.value = [0, res.lost_proceeds_max]
        // this.rangesFilters.lostRevenue.disabled = res.lost_proceeds_max === 0

        setTimeout(() => {
          this.rangesFilters.reviews.max = res.reviews_max
          this.rangesFilters.rating.max = res.rating_max
          // this.rangesFilters.favorites.max = res.in_favorites_max
          this.rangesFilters.price.max = res.price_max
          this.rangesFilters.sales.max = res.sales_max
          this.rangesFilters.remains.max = res.quantity_max
          this.rangesFilters.revenue.max = res.proceeds_max
          // this.rangesFilters.lostRevenue.max = Math.ceil(res.lost_proceeds_max)
          this.saveDefaultRangeFilters()
          this.saveRangeFiltersValues()
          this.setUrlParams()
          this.loadProducts({
            ...this.filters,
            search: this.searchValue,
            favoritesOnly: this.favoritesOnly,
            sortOptions: this.sortOptions,
            ...this.rangesFilters,
            ...this.paginationOptions
          })
        })
      } catch (err) {
        this.preloader = false
        console.log(err)
      } finally {
        this.rangePreloader = false
        this.$emit('loadingFinished')
      }
    },
    async loadProducts(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getProducts(filters))

        this.productsData.data = data.data || []
        this.productsData.total = data.paging?.total || 0

        this.paginationOptions.total = data.paging?.total
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    downloadXLSX() {},
    setUrlParams() {
      if (this.$route.name === 'brand_page') {
        const params = new URLSearchParams()

        if (this.filters.category.name) {
          params.append('category_name', this.filters.category.name)
        }

        if (this.$route.query.crumbs_text) params.append('crumbs_text', this.$route.query.crumbs_text)

        params.append('state', this.$route.query.state)

        this.$router
          .replace(
            `?${params.toString()}&default_picker_action=${this.defaultPickerAction}&mp_code=${this.filters.code}&${getProducts({
              ...this.filters,
              search: this.searchValue,
              favoritesOnly: this.favoritesOnly,
              ...this.rangesFilters,
              sortOptions: this.sortOptions,
              ...this.paginationOptions
            })[1].params.toString()}`
          )
          .catch(() => {})
      }
    }
  }
}
</script>
