<template>
  <div ref="mainContainer" class="list__wrapper">
    <div ref="scrollingContainer" class="list__container">
      <div class="list">
        <div
          class="list__header"
          :style="{
            gridTemplateColumns: `336px repeat(${periodList.length}, ${
              periodList.length > 1 ? `minmax(${calcWidth}, 1fr)` : '100px'
            }`
          }"
        >
          <div class="list__header__title"></div>
          <div v-for="item in periodList" :key="item" class="list__header__title">{{ item }}</div>
        </div>
        <div
          v-for="item in datasets"
          :key="item.label"
          class="list__item"
          :style="{
            gridTemplateColumns: `336px repeat(${periodList.length}, ${
              periodList.length > 1 ? `minmax(${calcWidth}, 1fr)` : '100px'
            }`
          }"
        >
          <div class="list__item__section"></div>
          <div v-for="row in item.rows" :key="row.value + Math.random()" class="list__item__section">
            <div class="list__item__value">
              <span>{{ (+row.value).toLocaleString('ru') }}</span>
            </div>
            <div class="profit__value profit__subvalue" :class="{ profit__down: +row.delta < 0, zero__profit: +row.delta === 0 }">
              <span>{{ +row.delta > 0 ? `+${(+row.delta).toLocaleString('ru')}` : row.delta.toLocaleString('ru') }}%</span>
            </div>
            <div class="list__item__value">
              <span>{{ (+row.sub_value).toLocaleString('ru') }}</span>
            </div>
            <div class="profit__value" :class="{ profit__down: +row.difference < 0, zero__profit: +row.difference === 0 }">
              <span>
                {{ +row.difference > 0 ? `+${(+row.difference).toLocaleString('ru')}` : row.difference.toLocaleString('ru') }}%
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
    <div class="names__container">
      <div class="names__container__content">
        <div class="names__containe__col">
          <div class="names__container__title">Запрос</div>
          <div v-for="item in datasets" :key="item.label" class="name__item">
            <div class="name__link label__link">{{ item.label }}</div>
            <span class="name__link__category">
              {{ item.category }} {{ item.category === 'Категория' ? '(5000+ товаров)' : '' }}
            </span>
          </div>
        </div>
        <div class="names__containe__col">
          <div class="names__container__title names__container__subtitle">Метрики</div>
          <div v-for="(item, index) in datasets" :key="index + 1" class="name__item">
            <div class="name__link">Позиция</div>
            <div class="name__link name__link_sublink">Продаж, шт</div>
          </div>
        </div>
      </div>
    </div>
    <div class="shadow__container">
      <div class="shadow__content"></div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.x-loader--overlay
  z-index 3

.list__wrapper
  position relative
  overflow hidden
  user-select none
  margin-bottom 20px

.list__container
  position relative
  overflow-x auto
  display flex
  &::-webkit-scrollbar
    height 8px
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    box-shadow 0px 2px 4px rgba(0, 0, 0, 0.05)
    border-radius 4px

.list
  position relative
  padding-bottom 12px
  flex-grow 1

.list__header
  display grid
  grid-auto-rows 34px
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light

.list__header__title
  display flex
  justify-content flex-end
  align-items center
  color $colors.gray-darker
  font-size $font-size-s
  padding 0 10px 0 8px
  white-space nowrap
  font-family Arial, Helvetica, sans-serif

.list__item
  position relative
  display grid
  // grid-template-columns  165px 88px 92px 112px 130px 112px 152px 144px
  grid-auto-rows 91px
  border-bottom 1px solid $colors.gray-light
  line-height 18px
  font-size $font-size-s
  color $colors.gray-darkest-2

.list__item__section
  display flex
  flex-direction column
  justify-content center
  padding 0 10px 0 8px

.list__item__value
  width 100%
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.profit__value
  color $colors.success
  height 18px
  width 100%
  font-weight 600
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.profit__subvalue
  margin-bottom 8px

.profit__down
  color: $colors.error

.zero__profit
  color: $colors.gray-dark

.names__container
  position absolute
  top 0
  left 0
  height calc(100% - 18px)
  background #fff
  width 336px
  &:after
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    background #fff
    top 0
    right  0
    box-shadow 6px 0 18px rgba(0,0,0,.15)

.names__container__content
  position relative
  display flex
  top 0
  left 0
  background #fff
  z-index 1

.names__container__title
  display flex
  align-items center
  height 36px
  font-size $font-size-s
  color $colors.gray-darker
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light
  padding 0 8px
  width 204px

.names__container__subtitle
  width 130px

.name__item
  height 92px
  border-bottom 1px solid $colors.gray-light
  padding 8px 0
  font-size $font-size-s
  color $colors.gray-darkest-2
  -webkit-box-orient: vertical;

.name__link
  padding 0 8px
  line-height 18px
  color $colors.gray-darkest-2

.label__link
  display -webkit-box
  -webkit-line-clamp 2
  overflow hidden
  -webkit-box-orient vertical
  text-decoration underline

.name__link__category
  padding 0 8px
  font-size $font-sizes.caption
  line-height 18px
  color: $colors.gray-darker

.name__link_sublink
  margin-top 26px

.shadow__container
  position absolute
  top 0
  right -32px
  width 32px
  height calc(100% - 8px)
  &:before
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    top 0
    left 0
    box-shadow -6px 0 18px rgba(0,0,0,.15)

.shadow__content
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff
</style>

<script>
import scrollList from '@/mixins/scrollList.js'

export default {
  mixins: [scrollList],
  props: {
    periodList: {
      type: Array,
      default: () => []
    },
    datasets: {
      type: Array,
      default: () => []
    },
    preloader: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    calcWidth() {
      if (this.periodList[0].includes('-')) return '170px'
      if (/[а-я]/g.test(this.periodList[0])) return '130px'
      return '110px'
    }
  }
}
</script>
