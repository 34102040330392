<template>
  <x-page title="Товары" card>
    <div class="page__products">
      <div class="tabs__container">
        <x-tabs :tabs="marketplacesTabs" size="l" @change="selectTab" />
      </div>
      <search-bar :search-value="filters.search" @applySearch="applySearch" :class="{ disabled: !isPermitByTariff }" />
      <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
        <div class="row__item">
          <x-label :size="'xs'">Категория</x-label>
          <div :class="{ disabled: preloader }" @click="categoriesModal = true">
            <category-button v-model="filters.category" />
          </div>
        </div>
        <!-- <div class="row__item">
          <x-label :size="'xs'">Бренд</x-label>
          <div>
            <x-select
              :class="{ disabled: preloader }"
              :value="filters.brand"
              :options="brands"
              :track-by="'brand_id'"
              :label="'brand_name'"
              :searchable="true"
              @input="selectBrand"
              @searchChange="brandSearch"
            />
          </div>
        </div> -->
        <div class="row__item">
          <x-label :size="'xs'">Показать</x-label>
          <date-select
            :preloader="preloader"
            :period-action="defaultPickerAction"
            :period="filters.period"
            @selectPeriod="onSelectPeriod"
          ></date-select>
          <!-- <div :class="{ disabled: preloader }" @click="$refs.sellerPicker.$modal.show('datepicker-modal')">
            <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
          </div> -->
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <fbs-switcher v-model="filters.fbs" :disabled="preloader" @input="sendEvent" />
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <filters-button
            :opened="isOpened"
            :changes="slidersChanged"
            @click.native="openFiltersToggle"
            @resetFilters="resetFilters"
          />
        </div>
      </div>
      <div class="filters__range">
        <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
          <div class="range__grid" :class="{ disabled: !isPermitByTariff }">
            <range-slider
              v-for="item in rangesFilters"
              :key="item.key"
              :label="item.label"
              :filter-key="item.key"
              :max="item.max"
              :disabled="item.disabled"
              :value="item.value"
              @rangefilterchanged="onRangeFiltersValue"
            />
            <div class="apply__button__row__item">
              <div class="apply__button__row">
                <div class="label__fake"></div>
                <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
                  Применить
                </x-btn>
              </div>
            </div>
          </div>
          <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
        </div>
        <page-hint
          text="Вы узнаете всё о продажах любого товара!  Подберите товары под Ваши параметры, найдите лидеров продаж, проанализируйте показатели и стратегии потенциальных конкурентов."
        ></page-hint>
      </div>
      <div v-if="isPermitByTariff" class="tracked__container__row">
        <div class="total">
          Всего: <span>{{ productsData.total.toLocaleString('ru') }}</span>
        </div>
        <selected-period :period="filters.period"></selected-period>
        <!-- <div class="tracked">
          <Switcher v-model="filters.favoritesOnly" :disabled="preloader" />
          <div class="tracked__label">Только отслеживаемые: 1</div>
        </div> -->
        <!-- <x-btn class="download__btn" color="gray" :disabled="preloader">
          <x-icon class="download__icon" name="download" size="s"></x-icon>
          Скачать в XLSX
        </x-btn> -->
      </div>
      <!-- :prev-page-name="'Товары'" -->
      <products-list
        :products-data="productsData"
        :preloader="preloader"
        :fbs="filters.fbs"
        :more-columns="true"
        :category="filters.category"
        :period="filters.period"
        :default-picker-action="defaultPickerAction"
        :sort-options="sortOptions"
        @sort="sortProductsList"
      />
      <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" />
      <transition name="fade" mode="out-in" appear>
        <keep-alive>
          <x-categories-popup
            v-if="categoriesModal"
            :value="filters.category"
            key-id="external_id"
            key-name="name"
            :end-point="categoriesEndpoint"
            :selected-tab="filters.code"
            :axios="$axios"
            @input="selectCategory"
            @close="categoriesModal = false"
          />
        </keep-alive>
      </transition>
      <!-- <date-picker-modal
        ref="sellerPicker"
        :value="filters.period"
        :default-period="defaultPickerAction"
        @change="onSelectPeriod"
      /> -->
    </div>
  </x-page>
</template>

<style lang="stylus" scoped>
.page__products
  display flex
  flex-direction column
  flex-grow 1

.product__list
  flex-grow 1

.x-label
  pointer-events none

// .buttons__block
//   display none
//   padding 12px 0

.grid__row
  display grid
  // grid-template-columns repeat(3, calc(16.6% - 9px)) minmax(16%, 1fr) auto auto
  grid-template-columns repeat(2, calc(25% - 9px)) auto 1fr
  grid-gap 12px
  grid-auto-rows auto
  margin-bottom 12px

@media (max-width 1280px)
  .grid__row
    grid-template-columns repeat(2, calc(100% / 3 - 8px)) auto 1fr

@media (max-width 1470px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, calc(100% / 3 - 8px)) auto 1fr

@media (max-width 1260px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed .row__item:last-of-type
    display flex
    flex-direction column
    align-items end

@media (max-width 960px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 1030px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

  .row__item:last-of-type
    display flex
    flex-direction column
    align-items end

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

.label__fake
  margin-bottom 7px
  height 14px

.filters__range
  padding-bottom 12px
  border-bottom 1px solid $colors.gray-light

.filters__range__animate
  position relative
  overflow hidden
  transition .3s

.x-loader--overlay
  z-index 10

.range__grid
  display grid
  grid-template-columns repeat(4, calc(25% - 9px))
  grid-gap 12px
  padding 0 2px 12px

.apply__button__row__item
  grid-column span 2
  display flex

@media (max-width 1470px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 3

@media (max-width 1200px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 2

@media (max-width 960px)
  .x-content--collapsed .range__grid
    grid-template-columns 1fr

  .x-content--collapsed .apply__button__row__item
    grid-column span 1

@media (max-width 1280px)
  .range__grid
    grid-template-columns repeat(3, 1fr)

  .apply__button__row__item
    grid-column span 3

@media (max-width 992px)
  .range__grid
    grid-template-columns repeat(2, 1fr)

  .apply__button__row__item
    grid-column span 2

@media (max-width 768px)
  .range__grid
    grid-template-columns 1fr

  .apply__button__row__item
    grid-column span 1

.apply__button__row
  margin-left auto

.apply__button
  width 120px

.apply__button
  width 120px
  margin-left auto

.tracked__container__row
  display flex
  align-items center
  height 36px
  margin 12px 0

.total
  border-right 1px solid #ededed
  padding-right 12px
  height 100%
  font-size $font-size-s
  color $colors.gray-darkest-2
  line-height 36px
  & span
    font-weight bold

.tracked
  display flex
  align-items center
  padding-left 16px

.tracked__label
  color $colors.gray-darkest-1
  font-size $font-size-s
  margin-left 4px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit

.disabled
  pointer-events none
  opacity .5
</style>

<script>
import filtersToggle from '@/mixins/filtersToggle.js'
import permissions from '@/mixins/permissions.js'
import productsList from '@/components/productsList.vue'
import getCategories from '@/api/marketplacesCategories/index.js'
import changeDocumentTitle from '@/mixins/changeDocumentTitle.js'
import { getProductsRangeFiltersMaxValue, getProducts } from '@/api/products/index.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

const start = new Date()
const end = new Date()
start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [filtersToggle, changeDocumentTitle, permissions],
  components: {
    productsList
  },
  data() {
    return {
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      categoriesModal: false,
      categoriesEndpoint: getCategories,
      preloader: false,
      rangePreloader: false,
      searchTimeout: null,
      brands: [],
      productsData: {
        data: [],
        total: 0
      },
      filters: {
        code: this.$route.query.mp_code || 'wb',
        category: {
          external_id: this.$route.query.category_id || '',
          name: this.$route.query.category_name || ''
        },
        brand: {
          brand_id: '',
          brand_name: 'Все'
        },
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        search: this.$route.query.product_name || '',
        favoritesOnly: this.$route.query.favorites_only === 'true',
        fbs: this.$route.query.is_fbs === 'true'
      },
      defaultRangesFilters: {},
      rangeFiltersPrevVal: {},
      rangesFilters: {
        reviews: {
          label: 'Отзывы',
          key: 'reviews',
          value: [+this.$route.query.reviews_cnt_from || 0, +this.$route.query.reviews_cnt_to || 0],
          max: +this.$route.query.reviews_max || 0,
          disabled: !+this.$route.query.reviews_max
        },
        rating: {
          label: 'Оценка',
          key: 'rating',
          value: [+this.$route.query.rating_from || 0, +this.$route.query.rating_to || 0],
          max: +this.$route.query.rating_max || 0,
          disabled: !+this.$route.query.rating_max
        },
        // favorites: {
        //   label: 'В избранном',
        //   key: 'favorites',
        //   value: [+this.$route.query.in_favorites_cnt_from || 0, +this.$route.query.in_favorites_cnt_to || 0],
        //   max: +this.$route.query.favorites_max || 0,
        //   disabled: !+this.$route.query.favorites_max
        // },
        price: {
          label: 'Цена со скидкой, руб',
          key: 'price',
          value: [+this.$route.query.price_from || 0, +this.$route.query.price_to || 0],
          max: +this.$route.query.price_max || 0,
          disabled: !+this.$route.query.price_max
        },
        sales: {
          label: 'Кол-во продаж, шт',
          key: 'sales',
          value: [+this.$route.query.sales_cnt_from || 0, +this.$route.query.sales_cnt_to || 0],
          max: +this.$route.query.sales_max || 0,
          disabled: !+this.$route.query.sales_max
        },
        remains: {
          label: 'Остатки, шт',
          key: 'remains',
          value: [+this.$route.query.quantity_cnt_from || 0, +this.$route.query.quantity_cnt_to || 0],
          max: +this.$route.query.remains_max || 0,
          disabled: !+this.$route.query.remains_max
        },
        revenue: {
          label: 'Выручка, руб',
          key: 'revenue',
          value: [+this.$route.query.proceeds_from || 0, +this.$route.query.proceeds_to || 0],
          max: +this.$route.query.revenue_max || 0,
          disabled: !+this.$route.query.revenue_max
        }
        // lostRevenue: {
        //   label: 'Упущенная выручка, руб',
        //   key: 'lostRevenue',
        //   value: [0, 0],
        //   max: 0,
        //   disabled: true
        // }
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: +this.$route.query.limit || 10,
        offset: 0,
        total: 0,
        page: +this.$route.query.page || 1
      }
    }
  },
  watch: {
    filters: {
      handler(filters) {
        this.paginationOptions.page = 1
        this.loadSliders({ ...filters })
      },
      deep: true
    },
    paginationOptions() {
      this.setUrlParams()
      this.loadProducts({
        ...this.filters,
        sortOptions: this.sortOptions,
        ...this.rangeFiltersPrevVal,
        ...this.paginationOptions
      })
    }
  },
  computed: {
    ...mapState(['marketplacesTabs']),
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultRangesFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
  },
  created() {
    this.saveDefaultRangeFilters()
    if (this.isPermitByTariff) {
      this.saveRangeFiltersValues()

      if (!this.rangesFilters.price.max) {
        this.loadSliders({ ...this.filters })
      } else {
        this.rangePreloader = false
        this.loadProducts({
          ...this.filters,
          sortOptions: this.sortOptions,
          ...this.rangeFiltersPrevVal,
          ...this.paginationOptions
        })
      }
    }
    // this.loadBrands()
  },
  activated() {
    setTimeout(() => {
      const meniItem = document.querySelector('.x-nav-item--active')
      if (meniItem) meniItem.classList.add('disabled__item')
    }, 500)
    this.setUrlParams()

    this.$metrics.hit('orm_products_view')
  },
  deactivated() {
    document.querySelector('.x-nav-item--active').classList.remove('disabled__item')
  },
  methods: {
    sendEvent() {
      this.$metrics.hit(this.filters.fbs ? 'orm_products_fbs' : 'orm_products_fbo', {
        category: this.filters.category.external_id,
        marketplace_code: this.filters.code
      })
    },
    applySearch(val) {
      this.filters.search = val
      this.setUrlParams()
    },
    selectTab(idx) {
      this.filters.code = this.marketplacesTabs[idx].mp_code
    },
    selectCategory(category) {
      this.filters.brand = { brand_id: '', brand_name: 'Все' }
      this.filters = { ...this.filters, category }
      // this.loadBrands(this.filters.code, category.external_id, null)
    },
    selectBrand(brand) {
      this.filters = { ...this.filters, brand }
    },
    // brandSearch(val) {
    // this.loadBrands(this.filters.code, this.filters.category.categoryId, val)
    // },
    // onSelectPeriod(period, selectedAction) {
    //   this.filters = { ...this.filters, period }
    //   this.defaultPickerAction = selectedAction
    // },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )

      // 6 : 13
      const period = {
        start: currentDate,
        end
      }

      this.defaultPickerAction = val.id
      this.filters = { ...this.filters, period }
    },
    resetFilters() {
      this.rangesFilters = JSON.parse(JSON.stringify(this.defaultRangesFilters))
    },
    onRangeFiltersValue({ val, key }) {
      this.rangesFilters = { ...this.rangesFilters, [key]: { ...this.rangesFilters[key], value: val } }
    },
    saveDefaultRangeFilters() {
      this.defaultRangesFilters = {
        ...this.rangesFilters,
        ...JSON.parse(
          JSON.stringify({
            reviews: {
              label: 'Отзывы',
              key: 'reviews',
              value: [0, this.rangesFilters.reviews.max],
              max: this.rangesFilters.reviews.max,
              disabled: this.rangesFilters.reviews.max === 0
            },
            rating: {
              label: 'Оценка',
              key: 'rating',
              value: [0, this.rangesFilters.rating.max],
              max: this.rangesFilters.rating.max,
              disabled: this.rangesFilters.rating.max === 0
            },
            // favorites: {
            //   label: 'В избранном',
            //   key: 'favorites',
            //   value: [0, this.rangesFilters.favorites.max],
            //   max: this.rangesFilters.favorites.max,
            //   disabled: this.rangesFilters.favorites.max === 0
            // },
            price: {
              label: 'Цена со скидкой, руб',
              key: 'price',
              value: [0, this.rangesFilters.price.max],
              max: this.rangesFilters.price.max,
              disabled: this.rangesFilters.price.max === 0
            },
            sales: {
              label: 'Кол-во продаж, шт',
              key: 'sales',
              value: [0, this.rangesFilters.sales.max],
              max: this.rangesFilters.sales.max,
              disabled: this.rangesFilters.sales.max === 0
            },
            remains: {
              label: 'Остатки, шт',
              key: 'remains',
              value: [0, this.rangesFilters.remains.max],
              max: this.rangesFilters.remains.max,
              disabled: this.rangesFilters.remains.max === 0
            },
            revenue: {
              label: 'Выручка, руб',
              key: 'revenue',
              value: [0, this.rangesFilters.revenue.max],
              max: this.rangesFilters.revenue.max,
              disabled: this.rangesFilters.revenue.max === 0
            }
            // lostRevenue: {
            //   label: 'Упущенная выручка, руб',
            //   key: 'lostRevenue',
            //   value: [0, this.rangesFilters.lostRevenue.max],
            //   max: this.rangesFilters.lostRevenue.max,
            //   disabled: this.rangesFilters.lostRevenue.max === 0
            // }
          })
        )
      }
    },
    saveRangeFiltersValues() {
      this.rangeFiltersPrevVal = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    applyRangeFilters() {
      this.saveRangeFiltersValues()
      this.paginationOptions.page = 1
      this.setUrlParams()
      this.loadProducts({ ...this.filters, sortOptions: this.sortOptions, ...this.rangesFilters, ...this.paginationOptions })
    },
    sortProductsList(titleId) {
      if (titleId !== 'seller_name') {
        if (this.sortOptions.id === titleId) {
          this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
        } else {
          this.sortOptions.direction = '1'
        }
        this.sortOptions.id = titleId
        this.setUrlParams()
        this.loadProducts({ ...this.filters, sortOptions: this.sortOptions, ...this.rangesFilters, ...this.paginationOptions })
      }
    },
    // async loadBrands(code, categoryId, search) {
    //   this.brands = [
    //     {
    //       brand_id: '',
    //       brand_name: 'Все'
    //     }
    //   ]
    //   // const res = await this.$axios.get(...getBrands(code, categoryId, search))
    //   // console.log(res)

    //   const res = await Promise.resolve([
    //     {
    //       brand_id: 'oshade',
    //       brand_name: 'O`SHADEO'
    //     },
    //     {
    //       brand_id: 'panasonic',
    //       brand_name: 'Panasonic '
    //     },
    //     {
    //       brand_id: 'apple',
    //       brand_name: 'Apple '
    //     },
    //     {
    //       brand_id: 'braun',
    //       brand_name: 'Braun '
    //     },
    //     {
    //       brand_id: 'smart_life',
    //       brand_name: 'Smart Life '
    //     }
    //   ])

    //   this.brands = this.brands.concat(res)
    // },
    async loadSliders(filters) {
      this.rangePreloader = true
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getProductsRangeFiltersMaxValue(filters))

        const res = data.data

        if (res) {
          for (const k in res) res[k] = Math.ceil(res[k])
        }

        this.rangesFilters.reviews.value = [0, res.reviews_max]
        this.rangesFilters.reviews.disabled = res.reviews_max === 0

        this.rangesFilters.rating.value = [0, res.rating_max]
        this.rangesFilters.rating.disabled = res.rating_max === 0

        // this.rangesFilters.favorites.value = [0, res.in_favorites_max]
        // this.rangesFilters.favorites.disabled = res.in_favorites_max === 0

        this.rangesFilters.price.value = [0, res.price_max]
        this.rangesFilters.price.disabled = res.price_max === 0

        this.rangesFilters.sales.value = [0, res.sales_max]
        this.rangesFilters.sales.disabled = res.sales_max === 0

        this.rangesFilters.remains.value = [0, res.quantity_max]
        this.rangesFilters.remains.disabled = res.quantity_max === 0

        this.rangesFilters.revenue.value = [0, res.proceeds_max]
        this.rangesFilters.revenue.disabled = res.proceeds_max === 0

        // this.rangesFilters.lostRevenue.value = [0, res.lost_proceeds_max]
        // this.rangesFilters.lostRevenue.disabled = res.lost_proceeds_max === 0

        setTimeout(() => {
          this.rangesFilters.reviews.max = res.reviews_max
          this.rangesFilters.rating.max = res.rating_max
          // this.rangesFilters.favorites.max = res.in_favorites_max
          this.rangesFilters.price.max = res.price_max
          this.rangesFilters.sales.max = res.sales_max
          this.rangesFilters.remains.max = res.quantity_max
          this.rangesFilters.revenue.max = res.proceeds_max
          // this.rangesFilters.lostRevenue.max = Math.ceil(res.lost_proceeds_max)
          this.saveDefaultRangeFilters()
          this.saveRangeFiltersValues()
          this.setUrlParams()
          this.loadProducts({ ...this.filters, sortOptions: this.sortOptions, ...this.rangesFilters, ...this.paginationOptions })
        })
      } catch (err) {
        this.preloader = false
        console.log(err)
      } finally {
        this.rangePreloader = false
      }
    },
    async loadProducts(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getProducts(filters))

        this.productsData.data = data.data || []
        this.productsData.total = data.paging?.total || 0

        this.paginationOptions.total = data.paging?.total
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    setUrlParams() {
      if (this.$route.name === 'products') {
        this.$router
          .replace(
            `?${this.filters.category.name ? `category_name=${this.filters.category.name}` : ''}&default_picker_action=${
              this.defaultPickerAction
            }&mp_code=${this.filters.code}&${getProducts({
              ...this.filters,
              ...this.rangesFilters,
              sortOptions: this.sortOptions,
              ...this.paginationOptions
            })[1].params.toString()}`
          )
          .catch(() => {})
      }
    }
  }
}
</script>
