<template>
  <div class="current__period" :class="{ current__period_noborder: showBorder }">
    Период:
    {{ `${Intl.DateTimeFormat('ru').format(period.start)} - ${Intl.DateTimeFormat('ru').format(period.end)}` }}
  </div>
</template>

<style lang="stylus" scoped>
.current__period
  color: $colors.gray-darkest-2
  font-size $font-size-s
  padding 0 12px
  height 100%
  line-height 36px
  white-space nowrap

.current__period_noborder
  border-right 1px solid #ededed
</style>

<script>
export default {
  props: {
    period: {
      type: Object
    },
    showBorder: {
      type: Boolean,
      default: false
    }
  }
}
</script>
