<template>
  <div class="statistics">
    <div class="grid__row">
      <div class="row__item">
        <x-label :size="'xs'">Категория</x-label>
        <div :class="{ disabled: preloader }" @click="$emit('showCategoriesModal')">
          <category-button :value="filters.category" @input="$emit('clearCategory')" />
        </div>
      </div>
      <!-- <div class="row__item">
            <x-label :size="'xs'">Бренд</x-label>
            <div class="select__container" :class="{ disabled: isLoading }">
              <x-select
                :value="filters.brand"
                :options="brands"
                :track-by="'brand_id'"
                :searchable="true"
                :clearable="false"
                :label="'brand_name'"
                :preserve-search="true"
                :internal-search="false"
                :clear-on-select="false"
                :options-limit="500000"
                @close="closeSelect"
                @input="selectBrand"
                @search-change="brandSearch"
              />
              <x-loader v-if="brandsPreloader" size="xs" :overlay="false"></x-loader>
            </div>
          </div> -->
      <div class="row__item">
        <x-label :size="'xs'">Показать</x-label>
        <date-select
          :preloader="preloader"
          :period-action="defaultPickerAction"
          :period="filters.period"
          @selectPeriod="onSelectPeriod"
        >
        </date-select>
        <!-- <div :class="{ disabled: isLoading }" @click="$refs.datePicker.$modal.show('datepicker-modal')">
              <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
            </div> -->
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
      </div>
    </div>
    <div class="statistics__row">
      <chart-labels :chart-data="chartData.datasets" :class="{ disabled: preloader }" @hideChart="hideChart"></chart-labels>
      <selected-period :period="filters.period"></selected-period>
    </div>
    <Chart ref="chart" :chart-data="chartDataFiltered" :options="options" :preloader="preloader" />
    <div class="title__row">
      <h3 class="statistics__title">Анализ динамики</h3>
      <x-btn class="download__btn" color="gray" :disabled="preloader || downloadPreloader" @click="downloadXLSX">
        <x-icon class="download__icon" name="download" size="s"></x-icon>
        Скачать в XLSX
        <x-loader v-if="downloadPreloader" size="xs"></x-loader>
      </x-btn>
    </div>
    <dynamics-list :period-list="chartData.labels" :datasets="chartData.datasets" :preloader="preloader"></dynamics-list>
  </div>
</template>

<style lang="stylus" scoped>

.grid__row
  display grid
  // grid-template-columns repeat(3, calc(16.6% - 9px)) minmax(16%, 1fr) auto auto
  grid-template-columns repeat(2, calc(25% - 9px))  auto
  grid-gap 12px
  grid-auto-rows auto
  margin-bottom 24px

@media (max-width 1400px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(3, 1fr)

@media (max-width 1200px)
  .grid__row
    grid-template-columns repeat(3, 1fr)

@media (max-width 1060px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 880px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 868px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

.label__fake
  margin-bottom 7px
  height 14px

.statistics__row
  display flex
  align-items center
  margin-bottom 5px

.chart__labels
  padding-right 12px
  border-right 1px solid #ededed
  height 26px

.title__row
  display flex
  align-items center
  margin 20px 0 12px

.statistics__title
  line-height 36px
  white-space nowrap
  color rgba(44,44,55,0.898)
  font-size $font-size-l

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit
    cursor inherit

.disabled
  opacity .5
  pointer-events none
</style>

<script>
import Chart from '@/ui/chart/Chart'
import DynamicsList from '@/components/DynamicsList.vue'
import ChartLabels from '@/ui/chart/ChartLabels.vue'
import { getDynamicsData, getDynamicsFile } from '@/api/sellerPage/index.js'
import calcPeriod from '@/mixins/calcPeriod.js'

export default {
  mixins: [calcPeriod],
  components: {
    ChartLabels,
    Chart,
    DynamicsList
  },
  props: {
    filters: {
      type: Object
    },
    headerData: {
      type: Object
    },
    defaultPickerAction: {
      type: String
    },
    marketPlaceValues: {
      type: Object
    }
  },
  data() {
    return {
      preloader: false,
      downloadPreloader: false,
      chartData: {
        type: 'line',
        labels: [],
        datasets: [
          {
            id: 'products',
            label: 'Товары, шт',
            selected: true,
            data: [],
            backgroundColor: '#2CC545',
            borderColor: ['#2CC545'],
            borderWidth: 2,
            yAxisID: 'products'
          },
          {
            id: 'brands',
            label: 'Бренды, шт',
            selected: true,
            data: [],
            backgroundColor: '#8855DD',
            borderColor: ['#8855DD'],
            borderWidth: 2,
            yAxisID: 'brands'
          },
          {
            id: 'sales',
            label: 'Продажи, шт',
            selected: true,
            data: [],
            backgroundColor: '#43B2D4',
            borderColor: ['#43B2D4'],
            borderWidth: 2,
            yAxisID: 'sales'
          },
          {
            id: 'leftovers',
            label: 'Остатки, шт',
            selected: true,
            data: [],
            backgroundColor: '#ED5051',
            borderColor: ['#ED5051'],
            borderWidth: 2,
            yAxisID: 'leftovers'
          },
          {
            id: 'proceeds',
            label: 'Выручка, руб',
            selected: true,
            data: [],
            backgroundColor: '#3E66FB',
            borderColor: ['#3E66FB'],
            borderWidth: 2,
            yAxisID: 'proceeds'
          }
          // {
          //   id: 'lost_proceeds',
          //   label: 'Упущенная выручка, руб',
          //   data: [],
          //   backgroundColor: ['#ff6a00'],
          //   borderColor: ['#ff6a00'],
          //   borderWidth: 2
          // }
        ]
      },
      options: {
        animation: true,
        plugins: {
          tooltip: {
            boxPadding: 5,
            interaction: {
              mode: 'index'
            }
          },
          legend: {
            display: false
            // labels: {
            //   usePointStyle: true,
            //   pointStyle: 'rect'
            // }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3,
        scales: {
          products: {
            id: 'products',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: true
            },
            ticks: {
              color: '#2CC545'
            }
          },
          brands: {
            id: 'brands',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#8855DD'
            }
          },
          sales: {
            id: 'sales',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#43B2D4'
            }
          },
          leftovers: {
            id: 'leftovers',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#ED5051'
            }
          },
          proceeds: {
            id: 'proceeds',
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#3E66FB'
            }
          }
        }
      }
    }
  },
  watch: {
    filters(filters) {
      // this.loadDynamics(filters)
    }
  },
  computed: {
    chartDataFiltered() {
      return { ...this.chartData, datasets: this.chartData.datasets.filter(el => el.selected) }
    }
  },
  mounted() {
    // this.loadDynamics(this.filters)
  },
  methods: {
    onSelectPeriod(val) {
      this.$emit('selectPeriod', val)
    },
    switchFbs(val) {
      this.$emit('switchFbs', val)
    },
    hideChart(item) {
      this.options.scales[item.id].display = item.selected
    },
    async loadDynamics(filters) {
      this.options.animation = false
      this.preloader = true
      this.$emit('startDynamicsLoading')

      try {
        const { data } = await this.$axios.get(...getDynamicsData(filters))
        const res = data.data || {}

        this.chartData.labels = this.calcPeriod
        this.chartData.datasets.forEach(el => {
          el.rows = res[el.id].rows
          el.data = res[el.id].rows.map(row => Math.round(row.value))
        })
      } catch (err) {
        console.log(err)
      } finally {
        this.options.animation = true
        this.preloader = false
        this.$emit('doneDynamicsLoading')
      }
    },
    async downloadXLSX() {
      this.downloadPreloader = true
      try {
        const { data } = await this.$axios.get(getDynamicsFile({ ...this.filters }), {
          responseType: 'blob'
        })
        const blob = new Blob([data], { type: 'application/vnd.ms-excel' })
        const url = window.URL.createObjectURL(blob)

        const date = new Date()
        const formatedDate = `${Intl.DateTimeFormat('ru')
          .format(date)
          .replace(/\./g, '_')}г.`

        const link = document.createElement('a')
        link.style = 'position: absolute; opacity: 0;'
        link.href = url
        link.download = `Динамика_${this.headerData.name.split(' ').join('_')}_${
          this.marketPlaceValues[this.filters.code].title
        }_на_${formatedDate}_(${Intl.DateTimeFormat('ru').format(this.filters.period.start)}-${Intl.DateTimeFormat('ru').format(
          this.filters.period.end
        )}).xlsx`
        document.body.append(link)
        link.click()
        link.remove()
      } catch (err) {
        console.log(err)
      } finally {
        this.downloadPreloader = false
      }
    }
  }
}
</script>
