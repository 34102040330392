<template>
  <div class="not-permit-wrap">
    <div class="green-circle mb-4">
      <x-icon
        color="success"
        name="star"
        size="xl"
      />
    </div>
    <h5 class="headline-5 mb-4">
      Данный раздел доступен на другом тарифе
    </h5>
    <x-btn :href="tariffPage">
      Перейти к тарифам
    </x-btn>
  </div>
</template>

<script>

export default {
  name: 'NotPermitByTariff',

  computed: {
    tariffPage () {
      return `${process.env.VUE_APP_SELLER_CABINET_URL}/tariffs`
    }
  }
}
</script>

<style lang="stylus" scoped>
.not-permit-wrap
  padding $space * 6
  display flex
  justify-content center
  align-items center
  flex-direction column
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)

.green-circle
  display flex
  justify-content center
  align-items center
  border-radius 50%
  width 64px
  height 64px
  background-color $colors.success-lightest-2
</style>
