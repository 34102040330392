<template>
  <div class="categories__list">
    <not-permit-by-tariff
      v-if="!isPermitByTariff || showTariffRestriction"
    />
    <template v-else>
      <div class="tooltip__container">
        <tooltip ref="tooltip" class="categories__List__tooltip" :style="{ left: `${tooltipLeft}px` }">{{ tooltipText }}</tooltip>
      </div>
      <div ref="mainContainer" class="list__wrapper">
        <div ref="scrollingContainer" class="list__container">
          <div class="list">
            <div class="list__header">
              <div
                v-for="item in headerList"
                :key="item.id"
                class="list__header__title"
                :class="{ disabled: !categoriesData.data.length }"
                @click="$emit('sort', item.id)"
              >
                <div class="list__header__title__text" @mouseenter="showTooltip($event, item)" @mouseleave="hideTooltip()">
                  {{ item.name }}
                  <div
                    v-if="categoriesData.data.length"
                    class="sort__icon"
                    :class="{ sort__icon_active: item.id === sortOptions.id, sort__icon_asc: sortOptions.direction === '0' }"
                  >
                    <x-icon name="arrow-down" size="xs"></x-icon>
                  </div>
                </div>
              </div>
            </div>
            <CategoriesValuesList :list="categoriesData.data" :sort-options="sortOptions"></CategoriesValuesList>
          </div>
        </div>
        <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
        <x-placeholder
          v-if="!categoriesData.data.length && !preloader"
          title="Запрашиваемые данные не найдены"
          icon="no-data"
          class="list__nodata"
        />
        <div class="names__container">
          <div class="names__container__content">
            <div class="names__container__title">Категория</div>
            <categories-names
              :list="categoriesData.data"
              :get-sublist="getSublist"
              :pudding="20"
              :code="code"
              :sort-options="sortOptions"
              :period="period"
              :fbs="fbs"
              :default-picker-action="defaultPickerAction"
              @startLoading="$emit('startLoading')"
              @endLoading="endLoading"
            />
          </div>
        </div>
        <div class="shadow__container">
          <div class="shadow__content"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.categories__list
  position relative
  flex-grow 1
  margin-bottom -20px
  min-height 370px

.tooltip__container
  position absolute
  width 100%
  left: 0
  top 0

.categories__List__tooltip
  bottom 0 !important
  z-index 3
  text-align center
  transform translateX(0) !important

.x-loader--overlay
  z-index 3

.list__wrapper
  position relative
  background #fff
  overflow hidden
  user-select none
  padding-bottom 20px
  height 100%

.list__container
  position relative
  overflow-x auto
  display flex
  height 100%
  &::-webkit-scrollbar
    height 8px
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    box-shadow 0px 2px 4px rgba(0, 0, 0, 0.05)
    border-radius 4px

.list
  flex-grow 1
  padding-bottom 12px

.list__header
  position relative
  display grid
  grid-auto-rows 34px
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light
  // grid-template-columns: 254px repeat(6, minmax(150px, 1fr)) minmax(220px, 1fr)
  grid-template-columns: 254px repeat(6, minmax(150px, 1fr)) repeat(2, minmax(180px, 1fr)) 10px

.list__header__title
  display flex
  justify-content flex-end
  align-items center
  color $colors.gray-darker
  font-size $font-size-s
  padding 0 8px
  white-space nowrap
  cursor pointer

.list__header__title__text
  position relative

.sort__icon
  position absolute
  top 50%
  right -18px
  transform translateY(-38%)
  display none

.sort__icon_active
  display block

.sort__icon_asc
  transform translateY(-53%) rotateZ(-180deg)

.names__container
  position absolute
  top 0
  left 0
  width 254px
  height calc(100% - 37px)
  background #fff
  &:after
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    background #fff
    top 0
    right  0
    box-shadow 6px 0 18px rgba(0,0,0,.15)

.names__container__content
  position relative
  background #fff
  z-index 1
  height 100%
  overflow hidden

.names__container__title
  display flex
  align-items center
  height 36px
  font-size $font-size-s
  color $colors.gray-darkest-2
  font-weight $font-weights.semibold
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light
  padding 0 8px

.shadow__container
  position absolute
  top 0
  right -32px
  width 32px
  height calc(100% - 37px)
  &:before
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    top 0
    left 0
    box-shadow -6px 0 18px rgba(0,0,0,.15)

.shadow__content
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff

.list__item
  position relative
  display grid
  grid-template-columns: 254px repeat(7, minmax(150px, 1fr))
  grid-auto-rows 52px
  border-bottom 1px solid $colors.gray-light
  line-height 18px
  font-size $font-size-s
  color $colors.gray-darkest-2

.list__item__section
  display flex
  flex-direction column
  align-items end
  justify-content center
  padding 0 8px

.list__item__value
  width 100%
  display flex
  justify-content flex-end
  & span
    white-space nowrap
    text-overflow ellipsis
    overflow hidden

.profit__value
  color $colors.success
  font-size 12px
  height 18px
  font-weight 600

.dropped__profit
  color $colors.error

.list__nodata
  position absolute
  top 50%
  left 50%
  transform: translate(-50%, -50%)

.disabled
  pointer-events none
</style>

<script>
import scrollList from '@/mixins/scrollList.js'
import permissions from '@/mixins/permissions.js'
import CategoriesNames from './CategoriesNames.vue'
import CategoriesValuesList from './CategoriesValuesList.vue'
import { getCategoriesList } from '@/api/categories/index.js'

export default {
  mixins: [scrollList, permissions],
  components: {
    CategoriesNames,
    CategoriesValuesList
  },
  props: {
    categoriesData: {
      type: Object
    },
    preloader: {
      type: Boolean
    },
    code: {
      type: String
    },
    period: {
      type: Object
    },
    fbs: Boolean,
    defaultPickerAction: {
      type: String
    },
    sortOptions: {
      type: Object
    }
  },
  data() {
    return {
      tooltipText: '',
      tooltipLeft: 0,
      headerList: [
        {
          id: 'empty_first',
          name: ''
        },
        {
          id: 'sellers_cnt',
          name: 'Продавцов',
          tooltip_text: 'Количество продавцов',
          width: 'auto'
        },
        {
          id: 'brands_cnt',
          name: 'Брендов',
          tooltip_text: 'Количество брендов',
          width: 'auto'
        },
        {
          id: 'products_cnt',
          name: 'Товаров',
          tooltip_text: 'Товары, которые были в продаже за ',
          width: 'auto'
        },
        {
          id: 'avg_check',
          name: 'Ср. чек, руб',
          tooltip_text: 'Средний чек за ',
          width: 'auto'
        },
        {
          id: 'sales_cnt',
          name: 'Продажи, шт',
          tooltip_text: 'Количество проданных единиц за ',
          width: 'auto'
        },
        {
          id: 'proceeds',
          name: 'Выручка, руб',
          tooltip_text: 'Выручка за ',
          width: 'auto'
        },
        {
          id: 'product_with_sales',
          name: 'Товаров с продажами',
          tooltip_text: 'Количество товаров, у которых была хотя бы одна продажа за ',
          width: '270px'
        },
        {
          id: 'product_with_sales_percent',
          name: '% Товаров с продажами',
          tooltip_text: 'Отношение товаров с продажами ко всем товарам в группе',
          width: '250px'
        }
        // {
        //   id: 'lost_proceeds',
        //   name: 'Упущенная выручка, руб'
        // }
      ]
    }
  },
  computed: {
    datePeriod() {
      switch (this.defaultPickerAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${this.formatDate(this.period?.start, '.')} - ${this.formatDate(this.period?.end, '.')}`
      }
      return ''
    }
  },
  methods: {
    showTooltip(e, item) {
      const tooltip = this.$refs.tooltip.$el
      const title = e.currentTarget
      tooltip.classList.add('tooltip_active')

      if (item.id !== 'product_with_sales' && item.id !== 'product_with_sales_percent') {
        tooltip.classList.add('no__wrapp')
      }

      if (
        item.id === 'products_cnt' ||
        item.id === 'avg_check' ||
        item.id === 'sales_cnt' ||
        item.id === 'proceeds' ||
        item.id === 'product_with_sales'
      ) {
        this.tooltipText = `${item.tooltip_text}${this.datePeriod}`
      } else {
        this.tooltipText = item.tooltip_text
      }

      tooltip.style.width = item.width
      setTimeout(() => {
        this.tooltipLeft =
          title.offsetLeft - this.$refs.scrollingContainer.scrollLeft + title.offsetWidth / 2 - tooltip.offsetWidth / 2
      })
      setTimeout(() => {
        if (tooltip.getBoundingClientRect().right > document.querySelector('.x-card').getBoundingClientRect().right) {
          this.tooltipLeft =
            this.tooltipLeft -
            (tooltip.getBoundingClientRect().right - document.querySelector('.x-card').getBoundingClientRect().right) -
            12
          tooltip.classList.add('arrow_right')
        }
      })
    },
    hideTooltip() {
      const tooltip = this.$refs.tooltip.$el
      tooltip.classList.remove('tooltip_active')
      tooltip.classList.remove('arrow_right')
      tooltip.classList.remove('no__wrapp')
    },
    getSublist(categoryId) {
      return this.$axios.get(
        ...getCategoriesList({ code: this.code, category: { external_id: categoryId }, period: this.period, fbs: this.fbs })
      )
    },
    isValue(value) {
      if (+value === 0) return ''
      if (+value > 0) return `+${value}%`

      return `${value}%`
    },
    endLoading() {
      this.$emit('endLoading')
      this.$forceUpdate()
      this.categoriesData.data = JSON.parse(JSON.stringify(this.categoriesData.data))
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    }
  }
}
</script>
