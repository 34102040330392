<template>
  <modal
    :name="modalName"
    width="1020"
    height="auto"
    @before-close="closeModal"
    @before-open="beforeOpen"
  >
    <div class="modal-container">
      <div class="content-columns content-columns_content_datepicker">
        <div class="column column_layout_a">
          <radio-buttons-group
            class="column__list"
            :value="current"
            :items="radiobuttons"
            @change="onChangePeriod"
          />
        </div>

        <div class="column column_layout_b">
          <calendar
            ref="calendar"
            v-model="date"
            :columns="2"
            is-range
            is-expanded
            show-weeknumbers
            :max-date="maxDate"
            :locale="{ masks: { weekdays: 'WW' } }"
            @click.native="onCalendarClick"
          />

          <div class="modal-container__action">
            <x-btn
              class="select__button"
              @click="onSelectClick"
            >
              Выбрать
            </x-btn>
            <!-- <reset-button
              v-if="hasResetButton"
              class="modal-container__reset"
              @click="resetDate"
            /> -->
            <!-- <btn
              block
              primary
              @click="onSelectClick"
            >
              Выбрать
            </btn> -->
          </div>
        </div>
      </div>

      <close-button
        class="modal-container__close"
        @click="$modal.hide(modalName)"
      />
    </div>
  </modal>
</template>

<script>
import moment from 'moment'
// import CalendarPeriods from '@/constants/calendar-periods'
// import calendarQuickFilters from '@/constants/calendar-quick-links'

// import CloseButton from '@/components/Interface/CloseButton.vue'
// import RadioButtonsGroup from '@/components/Interface/RadioButtonsGroup.vue'
// import Btn from '@/components/Interface/Button.vue'
// import ResetButton from '@/components/Interface/ResetButton.vue'

import CalendarPeriods from '../../constants/calendar-periods'
import calendarQuickFilters from '../../constants/calendar-quick-links'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import CloseButton from '../../components/Interface/CloseButton.vue'
import RadioButtonsGroup from '../../components/Interface/RadioButtonsGroup.vue'

const today = new Date()

export default {
  components: {
    Calendar: DatePicker,
    CloseButton,
    RadioButtonsGroup
    // ResetButton,
    // Btn
  },

  props: {
    hasResetButton: Boolean,
    maxDate: {
      type: Date,
      default: () => new Date()
    },
    defaultPeriod: {
      type: String,
      default: CalendarPeriods.SEVEN
    },
    value: {
      type: Object,
      default: () => ({
        start: today,
        end: today
      })
    }
  },

  data () {
    return {
      date: this.value,
      modalName: 'datepicker-modal',
      radiobuttons: calendarQuickFilters,
      current: this.defaultPeriod,
      clickEvent: false
    }
  },

  watch: {
    value (newValue) {
      this.date = newValue
    },

    defaultPeriod (value) {
      this.current = value
    },

    current (period) {
      switch (period) {
        case CalendarPeriods.SEVEN:
          this.date = {
            start: moment().subtract(6, 'day').toDate(),
            end: this.maxDate
          }
          if (this.$refs.calendar) this.$refs.calendar.move(this.maxDate)
          break

        case CalendarPeriods.FOURTEEN:
          this.date = {
            start: moment().subtract(13, 'day').toDate(),
            end: this.maxDate
          }
          if (this.$refs.calendar) this.$refs.calendar.move(this.maxDate)
          break

        case CalendarPeriods.THIRTY:
          this.date = {
            start: moment().subtract(29, 'day').toDate(),
            end: this.maxDate
          }
          if (this.$refs.calendar) this.$refs.calendar.move(this.maxDate)
          break

          // case CalendarPeriods.NINETY:
          //   this.date = {
          //     start: moment().subtract(89, 'day').toDate(),
          //     end: this.maxDate
          //   }
          //   if (this.$refs.calendar) this.$refs.calendar.move(this.maxDate)
          //   break

        case CalendarPeriods.OTHER:
          if (!this.clickEvent) {
            this.date = {
              start: this.value.start,
              end: this.value.end
            }

            if (this.$refs.calendar) this.$refs.calendar.move(this.value.end)
          }
          break

        // case CalendarPeriods.YESTERDAY:
        //   this.date = {
        //     start: moment().subtract(1, 'days').toDate(),
        //     end: moment().subtract(1, 'days').toDate()
        //   }
        //   break

        // case CalendarPeriods.TODAY:
        //   this.date = {
        //     start: new Date(),
        //     end: new Date()
        //   }
        //   break

        // case CalendarPeriods.LAST_WEEK:
        //   this.date = {
        //     start: moment().subtract(1, 'week').startOf('isoWeek').toDate(),
        //     end: moment().subtract(1, 'week').endOf('isoWeek').toDate()
        //   }
        //   break

        // case CalendarPeriods.CURRENT_MONTH:
        //   this.date = {
        //     start: moment().startOf('month').toDate(),
        //     end: new Date()
        //   }
        //   break

        // case CalendarPeriods.LAST_MONTH:
        //   this.date = {
        //     start: moment().subtract(1, 'month').startOf('month').toDate(),
        //     end: moment().subtract(1, 'month').endOf('month').toDate()
        //   }
        //   break

        // case CalendarPeriods.CURRENT_QUARTER:
        //   this.date = {
        //     start: moment().startOf('quarter').toDate(),
        //     end: new Date()
        //   }
        //   break

        // case CalendarPeriods.LAST_QUARTER:
        //   this.date = {
        //     start: moment().subtract(1, 'Q').startOf('quarter').toDate(),
        //     end: moment().subtract(1, 'Q').endOf('quarter').toDate()
        //   }
        //   break

        // case CalendarPeriods.CURRENT_YEAR:
        //   this.date = {
        //     start: moment().startOf('year').toDate(),
        //     end: new Date()
        //   }
        //   break

        // case CalendarPeriods.LAST_YEAR:
        //   this.date = {
        //     start: moment().subtract(1, 'year').startOf('year').toDate(),
        //     end: moment().subtract(1, 'year').endOf('year').toDate()
        //   }
        //   break
      }
    }
  },

  methods: {
    beforeOpen() {
      setTimeout(() => {
        this.$refs.calendar.$el.querySelectorAll('.vc-focusable').forEach(el => el.removeAttribute('tabindex'))
      }, 500)
    },
    closeModal () {
      this.current = this.defaultPeriod
      setTimeout(() => {
        this.$modal.hide(this.modalName)
      }, 500)
    },
    onSelectClick () {
      this.$emit('change', this.date, this.current)
      this.$modal.hide(this.modalName)
    },

    onCalendarClick (e) {
      this.clickEvent = true

      this.current = CalendarPeriods.OTHER

      setTimeout(() => {
        this.clickEvent = false
      }, 200)
    },

    resetDate () {
      this.$emit('change', {})
      this.$emit('hide')
    },

    onChangePeriod (period) {
      this.current = period
      this.$emit('change-default-period', this.current)
    }
  }
}
</script>

<style lang="stylus" scoped>
  .select__button
    width 100%

  .modal-container
    padding 40px 20px

    &__action
      margin-top 20px
      display flex

    &__reset
      margin-right 10px

    &__close
      top 10px
      width 9px
      height 9px

      >>> .close__icon
        width 9px
        height 9px

    >>>.radiolist
      .radiolist__item
        margin-bottom 11px
        margin-top 30px

        &:first-child
          margin-top 0

  .content-columns_content_datepicker
    display flex

    .column
      &_layout_a
        flex-basis 150px
        flex-shrink 0
        margin-right 50px
        padding-top 10px

      &__list
        height 100%

      &_layout_b
        flex-grow 1

        >>> .vc-container
          font-family 'proxima_nova_regular'
          border none

          .vc-pane-layout
            display flex
            justify-content space-between
            margin-left -60px
            // pointer-events none // тут

          .vc-pane
            flex-grow 1
            margin-left 60px

          .vc-weeks
            row-gap 20px

          .vc-header
            margin-bottom 50px
            padding-top 0

          .vc-arrows-container
            padding-top 0
            padding-bottom 0
            // display none // бок. кнопки

          .vc-title
            font-size 20px
            text-transform capitalize

          .vc-weekday
            font-weight normal
            text-transform capitalize
            color #7b7b85

          .vc-weeknumber-content
            margin-top 0
            margin-right 25px
            font-size 14px
            font-style normal
            background linear-gradient(0deg, rgba(255, 255, 255, .9), rgba(255, 255, 255, .9)), linear-gradient(0deg, #5346e4, #5346e4), #3e66fb

            &:empty
              background transparent

          .vc-day-content
            color #3c3c47
            border-radius 4px

            &.is-disabled
              color #7b7b85

          .vc-highlight
            background-color #5b40ff!important
            border-radius 4px!important
            border none!important

            &.vc-highlight-base-middle
            &.vc-highlight-base-start
            &.vc-highlight-base-end
              background-color #d6cfff!important
              border-radius 0!important
</style>
