import Vue from 'vue'
import App from './App.vue'
import _axios from '@/plugins/axios.js'
import router from './router'
import store from './store'
import PageTitle from '@/ui/PageTitle.vue'
import RangeSlider from '@/ui/rangeSlider/RangeSlider.vue'
import FiltersButton from '@/ui/FiltersButton.vue'
import PeriodsPicker from '@/ui/PeriodsPicker.vue'
import CategoryButton from '@/ui/CategoryButton.vue'
import InfoBanner from '@/ui/InfoBanner.vue'
import Tooltip from '@/ui/Tooltip.vue'
import SelectedPeriod from '@/ui/SelectedPeriod.vue'
import Switcher from '@/ui/Switcher.vue'
import FbsSwitcher from '@/ui/FbsSwitcher.vue'
import DateSelect from '@/ui/DateSelect.vue'
import SearchBar from '@/ui/SearchBar.vue'
import PageHint from '@/ui/PageHint.vue'
import NotPermitByTariff from '@/components/NotPermit/NotPermitByTariff.vue'
import XWUI, { XTabs, XBtn, XDropdownBtn, XInput, XLabel, XIcon, XPaginator, XLoader, XSelect, XCheckbox, XLink, XPlaceholder, XLayout, XPage, XCard, XImg, XBadge, XAlert, dialogPlugin, XDialog } from '@xway-team/ui'
import XCategoriesPopup from '@/ui/XCategoriesPopup/XCategoriesPopup.vue'
import VModal from 'vue-js-modal/dist/index.nocss.js'
import 'vue-js-modal/dist/styles.css'
import DatePickerModal from '@/ui/datepicker/components/Modals/DatepickerModal.vue'
import '@xway-team/ui/dist/index.css'
import { XMetricsPlugin } from '@xway-team/metrics'
import {
  applyTheme,
  detectThemeByQuery,
  detectThemeByCookie,
  getCompanyDetails,
  getAvailableThemeNames
} from '@xway-team/theme-detector'
import companyDetails, { setCompanyDetails } from '@/plugins/companyDetails.js'
import { setCurrentThemeName } from '@/plugins/currentTheme.js'

const themeName = detectThemeByQuery() || detectThemeByCookie()

if (getAvailableThemeNames().includes(themeName)) {
  setCurrentThemeName(themeName)
  applyTheme(themeName)
  setCompanyDetails(getCompanyDetails(themeName))
}

Vue.use(VModal)
// Vue.use(toast)

Vue.config.productionTip = false
Vue.prototype.$axios = _axios
Vue.prototype.$isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

Vue.use(XWUI, {
  breadcrumbsBase: {
    to: '/',
    text: 'Аналитика рынка'
  },
  companyDetails
})

Vue.use(XMetricsPlugin, {
  product: 'ORM',
  router,
  useProd: process.env.VUE_APP_DEPLOYMENT_ENV === 'production'
})

Vue.use(dialogPlugin)

Vue.component('page-title', PageTitle)
Vue.component('range-slider', RangeSlider)
Vue.component('filters-button', FiltersButton)
Vue.component('periods-picker', PeriodsPicker)
Vue.component('info-banner', InfoBanner)
Vue.component('Tooltip', Tooltip)
Vue.component('selected-period', SelectedPeriod)
Vue.component('Switcher', Switcher)
Vue.component('fbs-switcher', FbsSwitcher)
Vue.component('date-select', DateSelect)
Vue.component('search-bar', SearchBar)
Vue.component('page-hint', PageHint)
Vue.component('category-button', CategoryButton)
Vue.component('date-picker-modal', DatePickerModal)
Vue.component('x-tabs', XTabs)
Vue.component('x-paginator', XPaginator)
Vue.component('x-loader', XLoader)
Vue.component('x-select', XSelect)
Vue.component('x-checkbox', XCheckbox)
Vue.component('x-link', XLink)
Vue.component('x-placeholder', XPlaceholder)
Vue.component('x-layout', XLayout)
Vue.component('x-page', XPage)
Vue.component('x-card', XCard)
Vue.component('x-img', XImg)
Vue.component('x-badge', XBadge)
Vue.component('x-alert', XAlert)
Vue.component('x-dialog', XDialog)
Vue.component('x-btn', XBtn)
Vue.component('x-dropdown-btn', XDropdownBtn)
Vue.component('x-input', XInput)
Vue.component('x-label', XLabel)
Vue.component('x-icon', XIcon)
Vue.component('x-categories-popup', XCategoriesPopup)
Vue.component('NotPermitByTariff', NotPermitByTariff)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
