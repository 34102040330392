import axios from 'axios'
import store from '@/store'

import credentials from '../utils/credentials'

const _axios = axios.create({
  baseURL: process.env.VUE_APP_API
})

_axios.interceptors.request.use(
  function (config) {
    const res = document.cookie.split(';').find(el => /xw_token=\w/.test(el))

    if (res) {
      const token = res.replace('xw_token=', '')
      if (token) {
        config.headers.Authorization = 'Bearer ' + token.trim()
        if (/name=/g.test(config.params?.toString())) config.headers['Cache-Control'] = 'no-store'
      }
    }

    return config
  },

  function (error) {
    return Promise.reject(error)
  }
)

_axios.interceptors.response.use(
  function (response) {
    return response
  },

  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        const returnUrl = window.location.href
        const passportUrl = process.env.VUE_APP_PASSPORT_URL
        credentials.erase()
        const url = `${passportUrl}?return_url=${returnUrl}`
        window.location.replace(url)
        return
      }

      if (error.response.status === 402) {
        const tarifsUrl = `${process.env.VUE_APP_SELLER_CABINET_URL}/tariffs?unpaid=true`
        window.location.replace(tarifsUrl)
      }

      return error.response
    } else {
      store.commit('ui/SET_USER_STATUS', true)
      store.commit('ui/ALERT_MODAL', true)
    }

    return Promise.reject(error)
  }
)

export default _axios
