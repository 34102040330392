<template>
  <div id="orm">
    <x-layout
      :loading="isLoading"
      :class="{ disabled: isLoading || !menu.length }"
      :user-name="getUserName"
      :menu-items="menu"
      :user-email="user.email"
      :tariff="tariff"
      :show-tariff-expired-banner="showTariffExpiredBanner"
      show-recommendations-banner
      :order-recommendations="orderRecommendations"
      :repeat-payment="repeatPayment"
      @logout="logout"
    >
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive" />
    </x-layout>
    <transition name="fade" mode="out-in" appear>
      <alert-modal v-if="showAlertModal"></alert-modal>
    </transition>
  </div>
</template>

<style lang="stylus">
.headline-3
  font-size $font-sizes.headline-2 !important

// табы маркетплейсов
.tabs__container
  display flex
  position relative
  overflow-x auto
  margin-bottom 10px
  &::-webkit-scrollbar
    height 7px
  &::-webkit-scrollbar-thumb
    border 1px solid #fff
    background $colors.gray-dark
    border-radius 20px

.tabs__container .x-tabs
  pointer-events none
  padding-bottom 10px
  flex-grow 1

// остальные табы на страницах
.tabs
  display flex
  margin-bottom 10px
  white-space nowrap
  overflow-x auto
  &::-webkit-scrollbar
    height 7px
  &::-webkit-scrollbar-thumb
    border 1px solid #fff
    background $colors.gray-dark
    border-radius 20px

.tabs .x-tabs
  padding-bottom 10px
  flex-grow 1

.x-breadcrumbs__link
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.router-link-active
  max-width 500px
  overflow hidden
  text-overflow ellipsis

.x-paginator__btn
  width auto !important
  min-width 32px

*
  padding 0
  margin 0
  font-family $font-family
  list-style none

.disabled .x-nav
  pointer-events none

.x-nav
  z-index 100 !important

// .x-nav-global-menu__list .x-nav-global-menu__list-item--active
//   pointer-events none

// .x-nav__list .x-nav-item--active
  // pointer-events none

.x-breadcrumbs .disabled__crumb
  pointer-events none !important

.x-nav__list .disabled__item
  pointer-events none !important

.x-tabs__items .x-tabs__badge
  align-self auto !important
  background-color #2E68FB !important
  color #fff !important
  text-transform capitalize
  // font-weight $font-weights.semibold
  margin-left 8px !important
  // border 1px solid #FD6D21 !important
  height 16px !important

.x-header
  z-index 90 !important

.x-page
  padding 0 !important

.x-footer
  flex-shrink 0

.fade-enter-active, .fade-leave-active
  transition opacity .2s cubic-bezier(0.25, 0.8, 0.5, 1)

.fade-enter, .fade-leave-to
  opacity 0

// .animate-enter-active, .animate-leave-active
//    transition transform .25s cubic-bezier(0.25, 0.8, 0.5, 1), opacity .25s cubic-bezier(0.25, 0.8, 0.5, 1)

// .animate-enter, .animate-leave-to
//   opacity 0
//   transform scale(.4)
</style>

<script>
import getMenu from '@/api/menu'
import AlertModal from '@/ui/modals/AlertModal.vue'
import { createNamespacedHelpers } from 'vuex'
import { sellerApi } from '@/api/sellerApi'

const { mapActions, mapState } = createNamespacedHelpers('ui')

export default {
  name: 'App',
  components: {
    AlertModal
  },
  data() {
    return {
      menu: []
    }
  },
  computed: {
    ...mapState(['user', 'isLoading', 'showAlertModal']),
    getUserName() {
      return this.user.fullName ? this.user.fullName : this.user.name
    },
    tariff () {
      const { active_subscription } = this.user
      return active_subscription && {
        name: active_subscription.tariff?.name,
        expiresAt: active_subscription.expires_at
      }
    },
    showTariffExpiredBanner () {
      return this.user.hasDueBill
    }
  },
  async created() {
    // const pId = '54359658'
    // this.$axios.post('api/v1/wb/products/fovourites', {
    //   mp_code: 'wb',
    //   product_id: '54359658'
    // })
    // const { data } = await this.$axios.get('api/v1/wb/products/fovourites')
    // console.log(data)
    // const { data } = await this.$axios.get('api/v1/wb/favourites_products_section', {
    //   params: {
    //     date_from: '04-04-2022',
    //     date_to: '10-04-2022',
    //     is_fbs: false
    //   }
    // })
    // console.log(data)
    await this.getUser()
    this.loadMenu()
  },
  methods: {
    ...mapActions(['getUser']),
    orderRecommendations () {
      return sellerApi.orderRecommendations('Топбар')
    },
    async repeatPayment () {
      const { term, is_recurrent, id } = this.user.future_tariff

      const res = await sellerApi.initTinkoffPayment({
        tariff_id: id,
        term,
        is_recurrent,
        amount: this.user.next_bill?.amount
      })

      const paymentUrl = res?.data?.data?.payment_url

      if (paymentUrl) {
        window.open(paymentUrl, '_blank')
      }
    },
    async loadMenu() {
      try {
        const { data } = await this.$axios.get(getMenu())

        if (data.data) {
          // if (process.env.VUE_APP_ORM_URL !== 'https://orm.xway.ru') {
          //   data.data[3].children.unshift({
          //     exact: true,
          //     icon: 'analytics-tools',
          //     id: 30,
          //     name: 'Панель управления',
          //     parentId: 26,
          //     resource: 'VUE_APP_ORM_URL',
          //     type: 'link',
          //     url: '/'
          //   })
          // }

          // if (process.env.VUE_APP_ORM_URL !== 'https://orm.xway.ru' && !data.data[5]) {
          //   data.data.push({
          //     exact: false,
          //     icon: null,
          //     id: 26,
          //     name: 'Аналитика рынка',
          //     parentId: null,
          //     resource: 'VUE_APP_ORM_URL',
          //     type: 'link',
          //     url: '',
          //     children: [
          //       // {
          //       //   exact: true,
          //       //   icon: 'circle-pie',
          //       //   id: 1,
          //       //   name: 'Сводка',
          //       //   parentId: 26,
          //       //   resource: 'VUE_APP_ORM_URL',
          //       //   type: 'link',
          //       //   url: '/'
          //       // },
          //       {
          //         exact: false,
          //         icon: 'chart',
          //         id: 2,
          //         name: 'Продавцы',
          //         parentId: 26,
          //         resource: 'VUE_APP_ORM_URL',
          //         type: 'link',
          //         url: '/sellers'
          //       },
          //       {
          //         exact: false,
          //         icon: 'tree',
          //         id: 3,
          //         name: 'Категории',
          //         parentId: 26,
          //         resource: 'VUE_APP_ORM_URL',
          //         type: 'link',
          //         url: '/categories'
          //       },
          //       {
          //         exact: false,
          //         icon: 'tag',
          //         id: 4,
          //         name: 'Бренды',
          //         parentId: 26,
          //         resource: 'VUE_APP_ORM_URL',
          //         type: 'link',
          //         url: '/brands'
          //       },
          //       {
          //         exact: false,
          //         icon: 't-shirt',
          //         id: 5,
          //         name: 'Товары',
          //         parentId: 26,
          //         resource: 'VUE_APP_ORM_URL',
          //         type: 'link',
          //         url: '/products'
          //       }
          //     ]
          //   })
          // } else {
          //   console.log(data.data)
          // }
          this.menu = data.data
        }
      } catch (err) {
        console.log(err)
      }
    },
    logout() {
      const domain = location.hostname.match(/(x-way\.io|xway\.ru)/g)[0]
      document.cookie = `xw_token= ; path=/; max-age=0; domain=${domain}`

      const host = /xway\.ru/g.test(location.hostname) ? 'https://passport.xway.ru' : 'https://dev-passport-front.review.x-way.io'
      location.href = `${host}?return_url=${location.origin}`
    }
  }
}
</script>
