<template>
  <div class="seller__page">
    <x-page :class="{ disabled__crumbs: bannersPreloader }" card :breadcrumbs="breadCrumbs">
      <template #title>
        <div class="title__row">
          <div class="back__icon" @click="toPrevPage">
            <x-icon :name="'chevron-left'" size="s" />
          </div>
          <x-icon :name="marketPlaceValues[filters.code] ? marketPlaceValues[filters.code].icon : 'wildberries'" />
          <page-title>
            <a
              class="title__link"
              :class="{ title__link__disabled: !headerData.mp_seller_url }"
              :href="headerData.mp_seller_url"
              target="_blank"
            >
              {{ headerData.name }}
            </a>
          </page-title>
          <div class="tracked__icon" :class="{ tracked__icon_active: isInFavorites }" @click="toggleTrack">
            <x-icon name="bookmark" size="xs"></x-icon>
          </div>
        </div>
      </template>
      <div class="seller__page__content">
        <div class="seller__info">
          <span v-if="headerData.ogrn" class="saller__id">
            {{ headerData.ogrn[0] === '3' ? 'ОГРНИП' : 'ОГРН' }} {{ headerData.ogrn }}
          </span>
          <span v-if="isMarketplaces" class="other__markets">Другие магазины продавца:</span>
          <div v-for="item in filterMarketplaces" :key="item.code" class="market__icon">
            <x-icon
              :name="marketPlaceValues[item.code].icon"
              size="s"
              :class="{ disabled: bannersPreloader }"
              @click="selectMarketplace(item)"
            />
            <tooltip>
              <span>{{ headerData.name }}</span>
              &nbsp;на {{ marketPlaceValues[item.code].title }}
            </tooltip>
          </div>
        </div>
        <div class="info__banner__container">
          <info-banner
            :title="'Товары, шт'"
            :value="headerData.products_cnt"
            :profit="headerData.products_delta"
            :preloader="bannersPreloader"
            :text="`Товары, которые были в продаже за ${datePeriod}`"
          />
          <info-banner
            :title="'Бренды, шт'"
            :value="headerData.brands_cnt"
            :profit="headerData.brands_delta"
            :preloader="bannersPreloader"
            :text="`Бренды, которые были в продаже за ${datePeriod}`"
          />
          <info-banner
            :title="'Продажи, шт'"
            :value="headerData.sales_cnt"
            :profit="headerData.sales_delta"
            :preloader="bannersPreloader"
            :text="`Количество продаж за ${datePeriod}`"
          />
          <info-banner
            :title="'Остатки, шт'"
            :value="headerData.quantity_cnt"
            :profit="headerData.quantity_delta"
            :preloader="bannersPreloader"
            text="Товарные остатки на текущий момент"
          />
          <info-banner
            :title="'Выручка, руб'"
            :value="headerData.proceeds"
            :profit="headerData.proceeds_delta"
            :preloader="bannersPreloader"
            :text="`Общая выручка за ${datePeriod}`"
          />
          <info-banner
            :title="'Упущенная выручка, руб'"
            :value="'Скоро'"
            :profit="''"
            :preloader="bannersPreloader"
            text="Упущенная выручка на текущий момент"
          />
        </div>
        <div class="tabs">
          <x-tabs :tabs="tabs" size="l" :selected="tabs.findIndex(el => el.id === pageState)" @change="selectTab" />
        </div>
        <keep-alive>
          <component
            :is="pageState"
            :filters="filters"
            :default-picker-action="defaultPickerAction"
            :header-data="headerData"
            :market-place-values="marketPlaceValues"
            @showCategoriesModal="categoriesModal = true"
            @clearCategory="clearCategory"
            @periodChanged="onSelectPeriod"
            @switchFbs="switchFbs"
          >
          </component>
        </keep-alive>
      </div>
      <transition name="fade" mode="out-in" appear>
        <keep-alive>
          <x-categories-popup
            v-if="categoriesModal"
            :value="filters.category"
            key-id="external_id"
            key-name="name"
            :selected-tab="filters.code"
            :code="filters.code"
            @input="selectCategory"
            @close="categoriesModal = false"
          />
        </keep-alive>
      </transition>
      <!-- <date-picker-modal
        ref="datePicker"
        :value="filters.period"
        :default-period="defaultPickerAction"
        @change="onSelectPeriod"
      /> -->
    </x-page>
  </div>
</template>

<style lang="stylus" scoped>
.seller__page
  display flex
  flex-direction column
  flex-grow 1

.seller__page__content
  display flex
  flex-direction column
  flex-grow 1

.back__icon
  color: $colors.gray-dark
  width 36px
  height 36px
  border-radius 5px
  display flex
  align-items center
  justify-content center
  cursor pointer
  transition .2s
  margin-right 10px
  flex-shrink 0
  &:hover
    color: $colors.gray-darkest-2
    background $colors.gray-lighter

.title__row
  display flex
  align-items center
  width 100%
  // width calc(100% - 48px)

.seller__page .x-icon--m
  margin-right 4px
  flex-shrink 0

.market__icon
  position relative
  margin-right 8px
  width 20px
  height 20px
  &:last-of-type
    margin-right 0
  &:hover .tooltip
    display flex

.tooltip
  max-width 280px
  white-space nowrap
  & span
    max-width 150px
    white-space nowrap
    display block
    text-overflow ellipsis
    overflow hidden

.seller__page .x-icon--s
  cursor pointer

.tracked__icon
  cursor pointer
  position relative
  background #fff
  display flex
  width 32px
  height 32px
  align-items center
  justify-content center
  box-shadow 0px 6px 16px rgba(5, 2, 55, 0.04)
  border-radius 4px
  margin-left 10px
  flex-shrink 0
  color: $colors.gray-dark
  fill: transparent

.tracked__icon_active
  color: $colors.primary
  fill: $colors.primary

.seller__info
  position relative
  display flex
  align-items center
  color $colors.gray-dark
  font-size $font-size-s
  margin-bottom 20px
  height 24px

.other__markets
  margin-right 8px
  display flex
  align-items center
  &:before
    content ''
    width 1px
    height 24px
    background $colors.gray-light
    margin 0 8px

.info__banner__container
  display grid
  grid-template-columns repeat(6, minmax(200px, 1fr))
  grid-gap 12px
  margin-bottom 20px

@media (max-width 1500px)
  .info__banner__container
    grid-template-columns repeat(3, minmax(200px, 1fr))

@media (max-width 1670px)
  .x-content--collapsed .info__banner__container
    grid-template-columns repeat(3, minmax(200px, 1fr))

@media (max-width 1050px)
  .x-content--collapsed .info__banner__container
    grid-template-columns repeat(2, minmax(200px, 1fr))

@media (max-width 876px)
  .x-content--collapsed .info__banner__container
    grid-template-columns minmax(200px, 1fr)

@media (max-width 992px)
  .info__banner__container
    grid-template-columns repeat(2, minmax(200px, 1fr))

@media (max-width 768px)
  .info__banner__container
    grid-template-columns minmax(200px, 1fr)

.x-loader
  position absolute
  top 50%
  left 50%
  z-index 100
  transform translate(-50%, -50%)

.tabs
  pointer-events none

.disabled
  pointer-events none
  opacity .5

.disabled__crumbs
  pointer-events none
</style>

<script>
import toBackPage from '@/mixins/toBackPage.js'
import permissions from '@/mixins/permissions.js'
import SellerProducts from './SellerProducts'
import SellerDynamics from './SellerDynamics.vue'
// import getBrands from '@/api/brands/index.js'
import { getHeaderData } from '@/api/sellerPage/index.js'
import { addSellerToFavorites, removeSellersFromFavorites } from '@/api/sellers/index.js'
import { marketPlaceValues } from '@/constants/index.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions, mapMutations } = createNamespacedHelpers('ui')
// const { mapState } = createNamespacedHelpers('ui')

const start = new Date()
const end = new Date()

start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  mixins: [toBackPage, permissions],
  components: {
    SellerProducts,
    SellerDynamics
  },
  data() {
    return {
      marketPlaceValues,
      prevPagePath: '',
      // pageState: this.$route.query.state || 'SellerProducts',
      pageState: 'SellerProducts',
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      bannersPreloader: false,
      brandsPreloader: false,
      categoriesModal: false,
      headerData: {
        name: '',
        products_cnt: '',
        products_delta: '0',
        brands_cnt: '',
        brands_delta: '0',
        sales_cnt: '',
        sales_delta: '0',
        quantity_cnt: '',
        quantity_delta: '0',
        proceeds: '',
        proceeds_delta: '0',
        lost_proceeds: '',
        lost_proceeds_delta: '0',
        analysis_seller_ids: {},
        ogrn: ''
      },
      defaultFilters: {},
      filters: {
        code: this.$route.query.mp_code,
        sellerId: this.$route.params.id,
        category: {
          external_id: this.$route.query.category_id || '',
          name: this.$route.query.category_name || ''
        },
        brand: {
          brand_id: this.$route.query.brand_id || '',
          brand_name: this.$route.query.brand_label || 'Все'
        },
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        fbs: this.$route.query.is_fbs === 'true',
        favoritesOnly: this.$route.query.favorites_only === 'true'
      },
      tabs: [
        {
          id: 'SellerProducts',
          title: 'Товары'
        }
        // {
        //   id: 'SellerDynamics',
        //   title: 'Динамика'
        // }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.prevPagePath = from.fullPath
      if (!vm.isVIP) {
        next('/sellers')
      }
    })
  },
  computed: {
    ...mapState(['isVIP', 'sellersFavoritesList']),
    breadCrumbs() {
      // let path
      // if (this.prevPagePath) {
      //   path = this.prevPagePath.replace(/crumbs_text=.{1,100000}/g, `crumbs_text=${this.headerData.name}`)
      //   if (this.$route.query.crumbs_text === 'Продавцы') path = '/sellers'
      // }

      // if (!this.prevPagePath || this.prevPagePath === '/' || path.match('sellers')) {
      //   path = '/'
      // }

      // return [
      //   {
      //     to: path && path !== '/' ? path : '/sellers',
      //     text: path && path !== '/' ? this.$route.query.crumbs_text : 'Продавцы'
      //   }
      // ]
      return [
        {
          to: '/sellers',
          text: 'Продавцы'
        }
      ]
    },
    filterMarketplaces() {
      const arr = Object.entries(this.headerData.analysis_seller_ids)
      return arr.map(([code, value]) => ({ code, sellerId: value }))
    },
    isMarketplaces() {
      return Object.keys(this.headerData.analysis_seller_ids).length
    },
    datePeriod() {
      switch (this.defaultPickerAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${Intl.DateTimeFormat('ru').format(this.filters.period?.start)} - ${Intl.DateTimeFormat('ru').format(
            this.filters.period?.end
          )}`
      }
      return ''
    },
    isInFavorites() {
      return this.sellersFavoritesList.includes(this.filters.sellerId)
    }
  },
  created() {
    if (this.isPermitByTariff) {
      if (this.isVIP) {
        if (!this.sellersFavoritesList.length) this.loadSellersFavoritesList(this.filters.code)
        this.loadHeaderData(this.filters.code, this.filters.sellerId, this.filters.fbs, this.filters.period)

        // if (!this.$route.query.state) this.setUrlParams()
      }
    }
  },
  methods: {
    ...mapMutations(['UPDATE_SELLERS_FAVORITES_LIST']),
    ...mapActions(['loadSellersFavoritesList']),
    toggleTrack() {
      const { sellerId, code } = this.filters
      if (!this.isInFavorites) {
        this.UPDATE_SELLERS_FAVORITES_LIST([...this.sellersFavoritesList, sellerId])
        this.$axios.post(addSellerToFavorites(), {
          mp_code: code,
          seller_id: sellerId
        })
      } else {
        this.UPDATE_SELLERS_FAVORITES_LIST(this.sellersFavoritesList.filter(id => id !== sellerId))
        this.$axios.delete(removeSellersFromFavorites(code, sellerId))
      }
    },
    // async selectMarketplace(item) {
    //   this.defaultPickerAction = 'SEVEN'
    //   this.brandsPage = 1
    //   this.searchValue = ''
    //   this.brandsSearchValue = ''
    //   await this.loadHeaderData(item.code, item.sellerId, this.filters.fbs, { start, end })
    //   // await this.loadBrands(item.code, null, null, this.brandsPage, this.filters.sellerId)
    //   this.filters = {
    //     code: item.code,
    //     sellerId: item.sellerId,
    //     category: {
    //       external_id: '',
    //       name: ''
    //     },
    //     brand: {
    //       brand_id: '',
    //       brand_name: 'Все'
    //     },
    //     period: {
    //       start,
    //       end
    //     },
    //     search: '',
    //     favoritesOnly: false
    //   }
    // },
    selectTab(idx) {
      this.pageState = this.tabs[idx].id
      // this.setUrlParams()
    },
    selectCategory(category) {
      this.filters.brand = { brand_id: '', brand_name: 'Все' }
      this.filters = { ...this.filters, category }

      this.brandsPage = 1
      this.brandsSearchValue = ''
      // this.loadBrands(this.filters.code, category.external_id, null, this.brandsPage, this.filters.sellerId)
    },
    clearCategory() {
      this.selectCategory({ external_id: '', name: '' })
    },
    selectBrand(brand) {
      this.filters = { ...this.filters, brand }
    },
    // scrollBrands() {
    //   if (!this.brandsScrollFlag) return false

    //   const container = this.$el.querySelector('.multiselect__content-wrapper')

    //   if (this.brands.length < this.brandsCount) {
    //     if (container.scrollTop > container.scrollHeight - container.offsetHeight - 100) {
    //       this.brandsPage++

    //       this.loadBrands(
    //         this.filters.code,
    //         this.filters.category.external_id,
    //         this.brandsSearchValue,
    //         this.brandsPage,
    //         this.filters.sellerId
    //       )
    //       this.brandsScrollFlag = false
    //     }
    //   }
    // },
    // onSelectPeriod(period, selectedAction) {
    //   this.defaultPickerAction = selectedAction
    //   this.loadHeaderData(this.filters.code, this.filters.sellerId, period)
    //   this.filters = { ...this.filters, period }
    // },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )

      // 6 : 13
      const period = {
        start: currentDate,
        end
      }

      this.filters = { ...this.filters, period }

      this.defaultPickerAction = val.id

      this.loadHeaderData(this.filters.code, this.filters.sellerId, this.filters.fbs, this.filters.period)
    },
    switchFbs(fbs) {
      this.filters = { ...this.filters, fbs }
      this.loadHeaderData(this.filters.code, this.filters.sellerId, this.filters.fbs, this.filters.period)
    },
    async loadHeaderData(code, sellerId, fbs, period) {
      this.bannersPreloader = true
      try {
        const { data } = await this.$axios.get(...getHeaderData(code, sellerId, fbs, period))
        if (data && data.data) document.title = data.data.name
        this.headerData = data.data || {
          ...this.headerData,
          ...{
            products_cnt: '',
            products_delta: '0',
            brands_cnt: '',
            brands_delta: '0',
            sales_cnt: '',
            sales_delta: '0',
            quantity_cnt: '',
            quantity_delta: '0',
            proceeds: '',
            proceeds_delta: '0',
            lost_proceeds: '',
            lost_proceeds_delta: '0'
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.bannersPreloader = false
      }
    }
    // setUrlParams() {
    //   if (this.$route.name === 'seller_page') {
    //     const params = new URLSearchParams()
    //     params.append('state', this.pageState)
    //     this.$router.replace(`?${params.toString()}`).catch(() => {})
    //   }
    // }
  }
}
</script>
