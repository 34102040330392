<template>
  <div class="summary__sellers">
    <template v-if="favoritesSellers">
      <div v-if="isPermitByTariff" class="tracked__container">
        <div class="total">
          Всего: <span>{{ sellersData.total.toLocaleString('ru') }}</span>
        </div>
        <!-- <div class="tracked">
          <Switcher v-model="filters.favoritesOnly" :disabled="preloader || !sellersData.data.length" />
          <div class="tracked__label">Только отслеживаемые: <span>1</span></div>
        </div> -->
        <selected-period :period="filters.period"></selected-period>
        <!-- <div class="download__btn__container">
        <x-btn
          class="download__btn"
          color="gray"
          :disabled="preloader || !sellersData.data.length || downloadPreloader"
          @click="downloadXLSX"
        >
          <x-icon class="download__icon" name="download" size="s"></x-icon>
          Скачать в XLSX
          <x-loader v-if="downloadPreloader" size="xs"></x-loader>
        </x-btn>
        <tooltip class="arrow__offset">Максимальный размер скачивания 1 000 строк.</tooltip>
      </div> -->
      </div>
      <sellers-list
        :sellers-data="sellersData"
        :preloader="preloader"
        :code="filters.code"
        :period="filters.period"
        :fbs="filters.fbs"
        :default-picker-action="defaultPickerAction"
        :sort-options="sortOptions"
        @sort="sortList"
        @isFavoritesSellers="isFavoritesSellers"
      />
      <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" />
    </template>
    <x-placeholder v-if="!favoritesSellers && !preloader" icon="table-empty">
      <template #desc>Вы не добавили ни одного продавца в отслеживаемые </template>
      <template #bottom>
        <router-link to="/sellers" class="plug__link">
          <x-btn>Добавить</x-btn>
        </router-link>
      </template>
    </x-placeholder>
  </div>
</template>

<style lang="stylus" scoped>
.summary__sellers
  display flex
  flex-direction column
  flex-grow 1
  min-height 400px

.tracked__container
  display flex
  flex-wrap wrap
  align-items center
  margin 12px 0

.total
  border-right 1px solid #ededed
  font-size $font-size-s
  color $colors.gray-darkest-2
  padding-right 12px
  line-height 36px
  max-width 200px
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  flex-shrink 0
  & span
    font-weight bold

.plug__link
  text-decoration none
</style>

<script>
import { getFavoritesSellers } from '@/api/summary/index.js'
import SellersList from '@/components/SellersList.vue'
import permissions from '@/mixins/permissions.js'

export default {
  mixins: [permissions],
  components: {
    SellersList
  },
  props: {
    filters: Object,
    defaultPickerAction: String
  },
  data() {
    return {
      preloader: false,
      favoritesSellers: true,
      sellersData: {
        data: [],
        total: 0
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: +this.$route.query.limit || 10,
        offset: 0,
        total: 0,
        page: +this.$route.query.page || 1
      }
    }
  },
  watch: {
    filters(filters) {
      this.paginationOptions.page = 1
      this.loadSellers({ ...filters, sortOptions: this.sortOptions, ...this.paginationOptions })
    },
    paginationOptions() {
      this.loadSellers({ ...this.filters, sortOptions: this.sortOptions, ...this.paginationOptions })
    }
  },
  created() {
    if (this.isPermitByTariff) {
      this.loadSellers({ ...this.filters, sortOptions: this.sortOptions, ...this.paginationOptions })
    }
  },
  methods: {
    isFavoritesSellers(payload) {
      this.favoritesSellers = payload
    },
    async loadSellers(filters) {
      this.$emit('loadingStarted')
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getFavoritesSellers(filters))
        this.sellersData.data = data.data || []
        this.sellersData.total = data.paging?.total || 0

        this.paginationOptions.total = data.paging?.total
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
        this.$emit('loadingFinished')
      }
    },
    sortList(titleId) {
      if (this.sortOptions.id === titleId) {
        this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
      } else {
        this.sortOptions.direction = '1'
      }
      this.sortOptions.id = titleId
      this.loadSellers({ ...this.filters, sortOptions: this.sortOptions, ...this.paginationOptions })
    }
  }
}
</script>
