<template>
  <div class="overlay" @click="closeModal">
    <div class="content">
      <button class="content__close">
        <x-icon name="x" size="xs"></x-icon>
      </button>
      <div class="content__icon">
        <svg width="66" height="66" viewBox="0 0 66 66" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M33 60.5C48.1878 60.5 60.5 48.1878 60.5 33C60.5 17.8122 48.1878 5.5 33 5.5C17.8122 5.5 5.5 17.8122 5.5 33C5.5 48.1878 17.8122 60.5 33 60.5Z"
            stroke="#CACAD3"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path d="M33 16.5V33L44 38.5" stroke="#CACAD3" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      </div>
      <div class="content__title">Слишком много запросов</div>
      <div class="content__description">Попробуйте снова</div>
      <x-btn color="gray">Закрыть</x-btn>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.overlay
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  z-index 500
  display flex
  align-items center
  justify-content center
  padding 10px
  background rgba(0,0,0,.2)

.content__icon
  margin 0 auto 20px

.content
  position relative
  width 100%
  height 270px
  display flex
  flex-direction column
  max-width 541px
  background #fff
  box-shadow 0px 6px 16px rgba(5, 2, 55,.14)
  border-radius 12px
  padding 36px 64px 40px

.content__title
  text-align center
  color: $colors.gray-darkest-2
  margin-bottom 4px
  font-size $font-sizes.headline-4
  line-height 24px
  font-weight $font-weights.bold

.content__description
  font-size $font-size-s
  color: $colors.gray-darker
  text-align center
  margin-bottom 20px

.content__close
  display flex
  align-items center
  justify-content center
  background-color transparent
  outline none
  color #a3a3ac
  border none
  border-radius 4px
  width 32px
  height 32px
  position absolute
  top 3px
  right 3px
  transition background-color 0.2s, border-color 0.2s, opacity 0.2s;
  cursor pointer
  &:hover
    background-color $colors.gray-lighter
  &:focus
    background-color $colors.gray-lighter
  &:active
    background-color $colors.gray-light
</style>

<script>
export default {
  methods: {
    closeModal() {
      location.reload()
    }
  }
}
</script>
