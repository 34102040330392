<template>
  <div class="brands__list">
    <not-permit-by-tariff
      v-if="!isPermitByTariff"
    />
    <template v-else>
      <div class="tooltip__container">
        <tooltip ref="tooltip" class="brands__List__tooltip" :style="{ left: `${tooltipLeft}px` }">{{ tooltipText }}</tooltip>
      </div>
      <div ref="mainContainer" class="list__wrapper">
        <div ref="scrollingContainer" class="list__container">
          <div class="list">
            <div class="list__header">
              <div
                v-for="item in headerList"
                :key="item.id"
                class="list__header__title"
                :class="{ disabled: !brandsData.data.length }"
                @click="$emit('sort', item.id)"
              >
                <div class="list__header__title__text" @mouseenter="showTooltip($event, item)" @mouseleave="hideTooltip()">
                  {{ item.name }}
                  <div
                    v-if="brandsData.data.length"
                    class="sort__icon"
                    :class="{ sort__icon_active: item.id === sortOptions.id, sort__icon_asc: sortOptions.direction === '0' }"
                  >
                    <x-icon name="arrow-down" size="xs"></x-icon>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="item in brandsData.data" :key="item.brand_id" class="list__item">
              <div class="list__item__section" />
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ (+item.products_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value" :class="{ dropped__profit: +item.products_delta < 0 }">
                  <span>{{ isValue(item.products_delta) }}</span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ (+item.sellers_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value" :class="{ dropped__profit: +item.sellers_delta < 0 }">
                  <span>{{ isValue(item.sellers_delta) }}</span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ Math.floor(+item.avg_check).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value" :class="{ dropped__profit: +item.avg_delta < 0 }">
                  <span>{{ isValue(item.avg_delta) }}</span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ (+item.sales_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value" :class="{ dropped__profit: +item.sales_delta < 0 }">
                  <span>{{ isValue(item.sales_delta) }}</span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ (+item.quantity_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value"></div>
                <!-- <div class="profit__value" :class="{ dropped__profit: +item.quantity_delta < 0 }">
                  <span>{{ isValue(item.quantity_delta) }}</span>
                </div> -->
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ Math.floor(+item.proceeds).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value" :class="{ dropped__profit: +item.proceeds_delta < 0 }">
                  <span>{{ isValue(item.proceeds_delta) }}</span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ Math.floor(+item.product_with_sales).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value">
                  <span></span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ formatValue(+item.product_with_sales_percent) }}%</span>
                </div>
                <div class="profit__value">
                  <span></span>
                </div>
              </div>
              <!-- <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ +item.lost_proceeds ? Math.floor(+item.lost_proceeds).toLocaleString('ru') : 0 }}</span>
                </div>
                <div class="profit__value" :class="{ dropped__profit: +item.lost_proceeds_delta < 0 }">
                  <span>{{ isValue(item.lost_proceeds_delta) }}</span>
                </div>
              </div> -->
              <!-- <div class="list__item__section">
                <div class="list__item__value">
                  <span>{{ item.rating }}</span>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
        <x-placeholder
          v-if="!+brandsData.total && !preloader"
          title="Запрашиваемые данные не найдены"
          icon="no-data"
          class="list__nodata"
        />
        <div class="names__container">
          <div class="names__container__content">
            <div class="names__container__title">Бренд</div>
            <div v-for="item in brandsData.data" :key="item.brand_id" class="name__item">
              <div @click.capture="sendEvent(item)">
                <x-link
                  class="name__link"
                  :to="{
                    name: 'brand_page',
                    params: { id: item.brand_id },
                    query: {
                      mp_code: code,
                      default_picker_action: defaultPickerAction,
                      is_fbs: fbs.toString(),
                      date_from: formatDate(period.start, '-'),
                      date_to: formatDate(period.end, '-'),
                      category_id: category.external_id,
                      category_name: category.name
                    }
                  }"
                >
                  {{ item.brand_name }}
                </x-link>
              </div>
            </div>
          </div>
        </div>
        <div class="tracked__container">
          <div class="tracked__buttons">
            <!-- <div class="tracked__item" />
            <div
              v-for="item in brandsData.data"
              :key="item.id"
              class="tracked__item"
              :class="{ tracked__item__active: item.is_favorite }"
              @click="toggleTrackItem(item)"
            >
              <x-icon name="bookmark" size="xs"></x-icon>
            </div> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.brands__list
  position relative
  flex-grow 1
  min-height 370px

.tooltip__container
  position absolute
  width 100%
  left: 0
  top 0

.brands__List__tooltip
  bottom 0 !important
  z-index 3
  text-align center
  transform translateX(0) !important

.list__wrapper
  position relative
  overflow hidden
  user-select none
  padding-bottom 20px
  height 100%

.list__container
  position relative
  overflow-x auto
  display flex
  height 100%
  &::-webkit-scrollbar
    height 8px
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    box-shadow 0px 2px 4px rgba(0, 0, 0, 0.05)
    border-radius 4px

.list
  position relative
  padding-bottom 12px
  flex-grow 1

.list__header
  position relative
  display grid
  // grid-template-columns  165px minmax(92px, 1fr) minmax(92px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) minmax(130px, 1fr) minmax(220px, 1fr) minmax(92px, .85fr) 62px
  grid-template-columns  165px repeat(6, minmax(130px, 1fr)) repeat(2, minmax(180px, 1fr)) 10px
  grid-auto-rows 34px
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light

.list__header__title
  cursor pointer
  display flex
  justify-content flex-end
  align-items center
  color $colors.gray-darker
  font-size $font-size-s
  padding 0 8px

.list__header__title__text
  position relative

.sort__icon
  position absolute
  top 50%
  right -18px
  transform translateY(-38%)
  display none

.sort__icon_active
  display block

.sort__icon_asc
  transform translateY(-53%) rotateZ(-180deg)

.list__item
  position relative
  display grid
  // grid-template-columns  165px minmax(92px, 1fr) minmax(92px, 1fr) minmax(140px, 1fr) minmax(120px, 1fr) minmax(130px, 1fr) minmax(220px, 1fr) minmax(92px, .85fr) 62px
  grid-template-columns  165px repeat(6, minmax(130px, 1fr)) repeat(2, minmax(180px, 1fr)) 10px
  grid-auto-rows 52px
  border-bottom 1px solid $colors.gray-light
  line-height 18px
  font-size $font-size-s
  color $colors.gray-darkest-2

.list__item__section
  display flex
  flex-direction column
  justify-content center
  padding 0 8px

.list__item__value
  width 100%
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.profit__value
  color $colors.success
  font-size 12px
  height 18px
  width 100%
  font-weight 600
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden
  & span
    font-family Arial, Helvetica, sans-serif

.dropped__profit
  color $colors.error

.names__container
  position absolute
  top 0
  left 0
  width 165px
  height calc(100% - 37px)
  background #fff
  &:after
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    background #fff
    top 0
    right  0
    box-shadow 6px 0 18px rgba(0,0,0,.15)

.names__container__content
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff
  z-index 1

.names__container__title
  display flex
  align-items center
  height 36px
  font-size $font-size-s
  color: $colors.gray-darkest-2
  font-weight $font-weights.semibold
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light
  padding 0 8px

.name__item
  height 53px
  border-bottom 1px solid $colors.gray-light
  padding 8px 0
  font-size $font-size-s
  color $colors.gray-darkest-2
  -webkit-box-orient vertical

.name__link
  display -webkit-box
  -webkit-line-clamp 2
  overflow hidden
  -webkit-box-orient vertical
  height 100%
  padding 0 8px
  line-height 18px
  color $colors.gray-darkest-2

.tracked__container
  position absolute
  top 0
  // right 0
  // width 48px
  right -24px
  width 24px
  height calc(100% - 37px)
  &:before
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    top 0
    left 0
    box-shadow -6px 0 18px rgba(0,0,0,.15)

.tracked__buttons
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff

.tracked__item
  display flex
  align-items center
  justify-content center
  border-bottom 1px solid $colors.gray-light
  height 53px
  cursor pointer
  color: $colors.gray-dark
  fill: transparent
  transition .2s
  &:nth-child(1)
    height 36px
    cursor default
    background none
    border-top 1px solid $colors.gray-light

.tracked__item__active
  color: $colors.primary
  fill: $colors.primary

.list__nodata
  position absolute
  top 50%
  left 50%
  transform: translate(-50%, -50%)

.x-loader--overlay
  z-index 4

.disabled
  pointer-events none
</style>

<script>
import scrollList from '@/mixins/scrollList.js'
import permissions from '@/mixins/permissions.js'

export default {
  model: {
    prop: 'sortOptions',
    event: 'input'
  },
  mixins: [scrollList, permissions],
  props: {
    brandsData: {
      type: Object
    },
    preloader: {
      type: Boolean,
      default: false
    },
    fbs: Boolean,
    code: {
      type: String,
      default: 'wb'
    },
    sortOptions: {
      type: Object
    },
    period: {
      type: Object
    },
    category: {
      type: Object,
      default: () => ({
        external_id: '',
        name: ''
      })
    },
    defaultPickerAction: {
      type: String
    }
  },
  data() {
    return {
      tooltipLeft: 0,
      tooltipText: '',
      headerList: [
        {
          id: 'empty_first',
          name: ''
        },
        {
          id: 'products_cnt',
          name: 'Товаров',
          tooltip_text: 'Количество товаров',
          width: 'auto'
        },
        {
          id: 'sellers_cnt',
          name: 'Продавцы',
          tooltip_text: 'Количество продавцов',
          width: 'auto'
        },
        {
          id: 'avg_check',
          name: 'Ср. чек, руб',
          tooltip_text: 'Средний чек за ',
          width: 'auto'
        },
        {
          id: 'sales_cnt',
          name: 'Продажи, шт',
          tooltip_text: 'Количество проданных единиц за ',
          width: 'auto'
        },
        {
          id: 'quantity_cnt',
          name: 'Остатки, шт',
          tooltip_text: 'Количество товарных остатков на текущий момент',
          width: '354px'
        },
        {
          id: 'proceeds',
          name: 'Выручка, руб',
          tooltip_text: 'Выручка за ',
          width: 'auto'
        },
        {
          id: 'product_with_sales',
          name: 'Товаров с продажами',
          tooltip_text: 'Количество товаров, у которых была хотя бы одна продажа за ',
          width: '270px'
        },
        {
          id: 'product_with_sales_percent',
          name: '% Товаров с продажами',
          tooltip_text: 'Отношение товаров с продажами ко всем товарам в группе',
          width: '250px'
        }
        // {
        //   id: 'lost_proceeds',
        //   name: 'Упущенная выручка, руб'
        // },
        // {
        //   id: 'rating',
        //   name: 'Рейтинг',
        //   tooltip_text: 'Рейтинг',
        //   width: 'auto'
        // }
        // {
        //   id: 'empty_last',
        //   name: ''
        // }
      ]
    }
  },
  computed: {
    datePeriod() {
      switch (this.defaultPickerAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${this.formatDate(this.period?.start, '.')} - ${this.formatDate(this.period?.end, '.')}`
      }
      return ''
    }
  },
  methods: {
    sendEvent(item) {
      if (this.$route.meta.metricsEvents.brand_select) {
        const payload = {
          marketplace_code: this.code,
          brand: item.brand_id,
          category: this.category.external_id
        }

        this.$metrics.hit(this.$route.meta.metricsEvents.brand_select, payload)
      }
    },
    showTooltip(e, item) {
      const tooltip = this.$refs.tooltip.$el
      const title = e.currentTarget
      tooltip.classList.add('tooltip_active')

      if (item.id === 'avg_check' || item.id === 'sales_cnt' || item.id === 'proceeds' || item.id === 'product_with_sales') {
        tooltip.classList.add('no__wrapp')
        this.tooltipText = `${item.tooltip_text}${this.datePeriod}`
      } else {
        this.tooltipText = item.tooltip_text
      }

      if (item.id === 'product_with_sales' || item.id === 'product_with_sales_percent') {
        tooltip.classList.remove('no__wrapp')
      }

      tooltip.style.width = item.width

      setTimeout(() => {
        this.tooltipLeft =
          title.offsetLeft - this.$refs.scrollingContainer.scrollLeft + title.offsetWidth / 2 - tooltip.offsetWidth / 2
      })

      setTimeout(() => {
        if (tooltip.getBoundingClientRect().right > document.querySelector('.x-card').getBoundingClientRect().right) {
          this.tooltipLeft =
            this.tooltipLeft -
            (tooltip.getBoundingClientRect().right - document.querySelector('.x-card').getBoundingClientRect().right) -
            12
          tooltip.classList.add('arrow_right')
        }
      })
    },
    hideTooltip() {
      const tooltip = this.$refs.tooltip.$el
      tooltip.classList.remove('tooltip_active')
      tooltip.classList.remove('arrow_right')
    },
    toggleTrackItem(item) {
      item.is_favorite = !item.is_favorite
    },
    isValue(value) {
      if (value === 0) return ''
      if (value > 0) return `+${value.toLocaleString('ru')}%`

      return `${value.toLocaleString('ru')}%`
    },
    formatValue(value) {
      if (value === 'undefined') return 0
      return /\./g.test(value) ? value.toFixed(2).replace('.', ',') : value
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    }
  }
}
</script>
