<template>
  <div class="sellers__content">
    <Sellers v-if="isVIP" />
    <sellers-plug v-else></sellers-plug>
  </div>
</template>

<style lang="stylus" scoped>
.sellers__content
  display flex
  flex-direction column
  flex-grow 1
</style>

<script>
import Sellers from './Sellers.vue'
import SellersPlug from './SellersPlug.vue'
import changeDocumentTitle from '@/mixins/changeDocumentTitle.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

export default {
  mixins: [changeDocumentTitle],
  components: {
    Sellers,
    SellersPlug
  },
  computed: mapState(['isVIP']),
  activated() {
    this.$metrics.hit('orm_sellers_view')
  }
}
</script>
