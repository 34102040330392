<template>
  <div class="category__brands">
    <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
      <div class="row__item">
        <x-label :size="'xs'">Показать</x-label>
        <date-select
          :preloader="preloader"
          :period-action="defaultPickerAction"
          :period="filters.period"
          @selectPeriod="onSelectPeriod"
        ></date-select>
        <!-- <div :class="{ disabled: preloader }" @click="$refs.datePicker.$modal.show('datepicker-modal')">
          <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
        </div> -->
      </div>
      <div class="row__item">
        <x-label :size="'xs'">Поиск</x-label>
        <x-input v-model.trim="searchValue" prepend-icon="search" placeholder="По наименованию" />
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <filters-button
          :opened="isOpened"
          :changes="slidersChanged"
          @click.native="openFiltersToggle"
          @resetFilters="resetRangeFilters"
        />
      </div>
    </div>
    <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
      <div class="range__grid" :class="{ disabled: !isPermitByTariff }">
        <range-slider
          v-for="item in rangesFilters"
          :key="item.key"
          :label="item.label"
          :filter-key="item.key"
          :disabled="item.disabled || preloader"
          :max="item.max"
          :value="item.value"
          @rangefilterchanged="onRangeFiltersValue"
        />
        <div class="apply__button__row__item">
          <div class="apply__button__row">
            <div class="label__fake"></div>
            <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
              Применить
            </x-btn>
          </div>
        </div>
      </div>
      <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
    </div>
    <!-- <div class="banners">
      <statistic-banner v-for="item in banners" :key="item.id" :data="item"></statistic-banner>
    </div> -->
    <div v-if="isPermitByTariff" class="tracked__container">
      <div class="total">
        Всего: <span>{{ brandsData.total.toLocaleString('ru') }}</span>
      </div>
      <!-- <div class="tracked">
        <Switcher v-model="filters.favoritesOnly" :disabled="preloader || !brandsData.data.length" />
        <div class="tracked__label">Только отслеживаемые: 1</div>
      </div> -->
      <selected-period :period="filters.period"></selected-period>
      <!-- <x-btn
        class="download__btn"
        color="gray"
        :disabled="preloader || !brandsData.data.length || downloadPreloader"
        @click="downloadXLSX"
      >
        <x-icon class="download__icon" name="download" size="s"></x-icon>
        Скачать в XLSX
        <x-loader v-if="downloadPreloader" size="xs"></x-loader>
      </x-btn> -->
    </div>
    <!-- :prev-page-name="$route.query.title" -->
    <brands-list
      :brands-data="brandsData"
      :preloader="preloader"
      :code="filters.code"
      :sort-options="sortOptions"
      :period="filters.period"
      :fbs="filters.fbs"
      :category="{ external_id: filters.category.external_id, name: $route.query.title }"
      :default-picker-action="defaultPickerAction"
      @sort="sortList"
    >
    </brands-list>
    <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" />
    <!-- <date-picker-modal ref="datePicker" :value="filters.period" :default-period="defaultPickerAction" @change="onSelectPeriod" /> -->
  </div>
</template>

<style lang="stylus" scoped>
.category__brands
  display flex
  flex-direction column
  flex-grow 1

.apply__button
  width 120px
  margin-left auto

.label__fake
  margin-bottom 7px
  height 14px

.grid__row
  display grid
  grid-template-columns calc(25% - 7px) 1fr auto auto
  grid-gap 12px
  grid-auto-rows auto
  margin-bottom 12px

@media (max-width 1660px)
  .x-content--collapsed .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) 1fr auto auto

@media (max-width 1500px)
  .grid__row
    grid-template-columns calc(100% / 12 * 4 - 7px) 1fr auto auto

@media (max-width 1300px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed .row__item:last-of-type
    display flex
    flex-direction column
    align-items end

@media (max-width 1150px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

  .row__item:last-of-type
    display flex
    flex-direction column
    align-items end

@media (max-width 992px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

  .x-content--collapsed .row__item:last-of-type
    align-items start

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

  .row__item:last-of-type
    align-items start

.filters__range__animate
  position relative
  overflow hidden
  transition .3s
  border-bottom 1px solid $colors.gray-light
  // margin-bottom 12px

.apply__button__row__item
  display flex
  justify-content flex-end
  grid-column span 2

.range__grid
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 12px
  padding 0 2px 12px

@media (max-width 1660px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 3

@media (max-width 1500px)
  .range__grid
    grid-template-columns repeat(3, 1fr)

  .apply__button__row__item
    grid-column span 3

@media (max-width 1300px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 2

@media (max-width 1150px)
  .range__grid
    grid-template-columns repeat(2, 1fr)

  .apply__button__row__item
    grid-column span 2

@media (max-width 1050px)
  .x-content--collapsed .range__grid
    grid-template-columns 1fr

  .x-content--collapsed .apply__button__row__item
    grid-column span 1

@media (max-width 850px)
  .range__grid
    grid-template-columns repeat(1, 1fr)

  .apply__button__row__item
    grid-column span 1

.x-loader--overlay
  z-index 10

// .banners
//   display grid
//   grid-template-columns repeat(3, 1fr)
//   grid-gap 40px
//   margin-bottom 29px

// @media (max-width 1200px)
//   .banners
//     grid-template-columns repeat(auto-fill, minmax(300px, 1fr))

// @media (max-width 1350px)
//   .x-content--collapsed .banners
//     grid-template-columns repeat(auto-fill, minmax(300px, 1fr))

.tracked__container
  display flex
  align-items center
  height 36px
  // margin-top 8px
  // margin-bottom 12px
  margin 12px 0

.total
  border-right 1px solid #ededed
  padding-right 12px
  height 100%
  font-size $font-size-s
  color $colors.gray-darkest-2
  line-height 36px
  & span
    font-weight bold

.tracked
  display flex
  align-items center
  padding-left 16px

.tracked__label
  color $colors.gray-darkest-1
  font-size $font-size-s
  margin-left 4px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  position relative
  margin-left auto
  &:disabled .download__icon
    color inherit

.download__btn .x-loader
  transform translateX(6px)

.disabled
  pointer-events none
  opacity .5
</style>

<script>
import filtersToggle from '@/mixins/filtersToggle.js'
import permissions from '@/mixins/permissions.js'
// import StatisticBanner from './StatisticBanner.vue'
import BrandsList from '@/components/BrandsList.vue'
import { getBrandsRangeFiltersMaxValue, getBrandsList } from '@/api/brands/index.js'

export default {
  mixins: [filtersToggle, permissions],
  components: {
    // StatisticBanner,
    BrandsList
  },
  props: {
    filters: {
      type: Object
    },
    defaultPickerAction: {
      type: String
    }
  },
  data() {
    return {
      preloader: false,
      rangePreloader: false,
      downloadPreloader: false,
      searchTimeout: null,
      searchValue: this.$route.query.name || '',
      // banners: [
      //   {
      //     id: 1,
      //     title: 'Продажи, шт',
      //     list: [
      //       {
      //         id: 1,
      //         color: '#ED5051',
      //         name: 'Apple',
      //         value: '156000',
      //         delta: '15'
      //       },
      //       {
      //         id: 2,
      //         color: '#FD8E39',
      //         name: 'Bosh',
      //         value: '143000',
      //         delta: '14'
      //       },
      //       {
      //         id: 3,
      //         color: '#2CC445',
      //         name: 'Citilink Official Store',
      //         value: '121000',
      //         delta: '13'
      //       },
      //       {
      //         id: 4,
      //         color: '#55D0F5',
      //         name: 'Home Mega Store',
      //         value: '98000',
      //         delta: '11'
      //       },
      //       {
      //         id: 5,
      //         color: '#3E66FB',
      //         name: 'Multi-Pulti',
      //         value: '75000',
      //         delta: '9'
      //       },
      //       {
      //         id: 6,
      //         color: '#E5E5E9',
      //         name: 'Другое',
      //         value: '',
      //         delta: '43'
      //       }
      //     ]
      //   },
      //   {
      //     id: 2,
      //     title: 'Выручка, руб',
      //     list: [
      //       {
      //         id: 1,
      //         color: '#ED5051',
      //         name: 'Apple',
      //         value: '156000',
      //         delta: '15'
      //       },
      //       {
      //         id: 2,
      //         color: '#FD8E39',
      //         name: 'Bosh',
      //         value: '143000',
      //         delta: '14'
      //       },
      //       {
      //         id: 3,
      //         color: '#2CC445',
      //         name: 'Citilink Official Store',
      //         value: '121000',
      //         delta: '13'
      //       },
      //       {
      //         id: 4,
      //         color: '#55D0F5',
      //         name: 'Home Mega Store',
      //         value: '98000',
      //         delta: '11'
      //       },
      //       {
      //         id: 5,
      //         color: '#3E66FB',
      //         name: 'Multi-Pulti',
      //         value: '75000',
      //         delta: '9'
      //       },
      //       {
      //         id: 6,
      //         color: '#E5E5E9',
      //         name: 'Другое',
      //         value: '',
      //         delta: '43'
      //       }
      //     ]
      //   },
      //   {
      //     id: 3,
      //     title: 'Упущенная выручка, руб',
      //     list: [
      //       {
      //         id: 1,
      //         color: '#ED5051',
      //         name: 'Бриджи и капри',
      //         value: '156000',
      //         delta: '15'
      //       },
      //       {
      //         id: 2,
      //         color: '#FD8E39',
      //         name: 'Горнолыжные брюки',
      //         value: '143000',
      //         delta: '14'
      //       },
      //       {
      //         id: 3,
      //         color: '#2CC445',
      //         name: 'Джоггеры',
      //         value: '121000',
      //         delta: '13'
      //       },
      //       {
      //         id: 4,
      //         color: '#55D0F5',
      //         name: 'Карго',
      //         value: '98000',
      //         delta: '11'
      //       },
      //       {
      //         id: 5,
      //         color: '#3E66FB',
      //         name: 'Классические брюки',
      //         value: '75000',
      //         delta: '9'
      //       },
      //       {
      //         id: 6,
      //         color: '#E5E5E9',
      //         name: 'Другое',
      //         value: '',
      //         delta: '43'
      //       }
      //     ]
      //   }
      // ],
      brandsData: {
        data: [],
        total: 0
      },
      defaultRangeFilters: {},
      rangeFiltersPrevVal: {},
      rangesFilters: {
        products: {
          label: 'Товары',
          key: 'products',
          value: [+this.$route.query.products_cnt_from || 0, +this.$route.query.products_cnt_to || 0],
          max: +this.$route.query.products_max || 0,
          disabled: !+this.$route.query.products_max
        },
        sellers: {
          label: 'Продавцы',
          key: 'sellers',
          value: [+this.$route.query.sellers_cnt_from || 0, +this.$route.query.sellers_cnt_to || 0],
          max: +this.$route.query.sellers_max || 0,
          disabled: !+this.$route.query.sellers_max
        },
        cheque: {
          label: 'Средний чек, руб',
          key: 'cheque',
          value: [+this.$route.query.avg_check_from || 0, +this.$route.query.avg_check_to || 0],
          max: +this.$route.query.cheque_max || 0,
          disabled: !+this.$route.query.cheque_max
        },
        sales: {
          label: 'Кол-во продаж, шт',
          key: 'sales',
          value: [+this.$route.query.sales_cnt_from || 0, +this.$route.query.sales_cnt_to || 0],
          max: +this.$route.query.sales_max || 0,
          disabled: !+this.$route.query.sales_max
        },
        remains: {
          label: 'Остатки, шт',
          key: 'remains',
          value: [+this.$route.query.quantity_cnt_from || 0, +this.$route.query.quantity_cnt_to || 0],
          max: +this.$route.query.remains_max || 0,
          disabled: !+this.$route.query.remains_max
        },
        revenue: {
          label: 'Выручка, руб',
          key: 'revenue',
          value: [+this.$route.query.proceeds_cnt_from || 0, +this.$route.query.proceeds_cnt_to || 0],
          max: +this.$route.query.revenue_max || 0,
          disabled: !+this.$route.query.revenue_max
        }
        // lostRevenue: {
        //   label: 'Упущенная выручка, руб',
        //   key: 'lostRevenue',
        //   value: [0, 40000],
        //   max: 50000,
        //   disabled: false
        // },
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: +this.$route.query.limit || 10,
        offset: 0,
        total: 0,
        page: +this.$route.query.page || 1
      }
    }
  },
  watch: {
    filters(filters) {
      this.paginationOptions.page = 1
      this.loadSliders({ ...filters, search: this.searchValue })
    },
    searchValue(val) {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        if (val.length > 2 || !val.length) {
          this.paginationOptions.page = 1
          this.loadSliders({ ...this.filters, search: val })
        }
      }, 700)
    },
    paginationOptions() {
      this.setUrlParams()
      this.loadBrands({
        ...this.filters,
        search: this.searchValue,
        sortOptions: this.sortOptions,
        ...this.rangeFiltersPrevVal,
        ...this.paginationOptions
      })
    }
  },
  computed: {
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultRangeFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
  },
  created() {
    this.saveDefaultRangeFilters()
    if (this.isPermitByTariff) {
      this.saveRangeFiltersValues()

      if (!this.rangesFilters.products.max) {
        this.loadSliders({ ...this.filters, search: this.searchValue })
      } else {
        this.rangePreloader = false
        this.loadBrands({
          ...this.filters,
          search: this.searchValue,
          ...this.rangesFilters,
          sortOptions: this.sortOptions,
          ...this.paginationOptions
        })
      }
    }
  },
  activated() {
    this.setUrlParams()
  },
  methods: {
    onSelectPeriod(val) {
      this.$emit('periodChanged', val)
    },
    switchFbs(fbs) {
      this.$emit('switchFbs', fbs)
    },
    onRangeFiltersValue({ val, key }) {
      if (JSON.stringify(this.rangesFilters[key].value) !== JSON.stringify(val)) {
        this.paginationOptions.page = 1
        this.rangesFilters[key].value = val
        this.rangesFilters = { ...this.rangesFilters }
      }
    },
    resetRangeFilters() {
      this.rangesFilters = JSON.parse(JSON.stringify(this.defaultRangeFilters))
      this.paginationOptions.page = 1
    },
    saveDefaultRangeFilters() {
      this.defaultRangeFilters = {
        ...this.rangesFilters,
        ...JSON.parse(
          JSON.stringify({
            products: {
              label: 'Товары',
              key: 'products',
              value: [0, this.rangesFilters.products.max],
              max: this.rangesFilters.products.max,
              disabled: this.rangesFilters.products.max === 0
            },
            sellers: {
              label: 'Продавцы',
              key: 'sellers',
              value: [0, this.rangesFilters.sellers.max],
              max: this.rangesFilters.sellers.max,
              disabled: this.rangesFilters.sellers.max === 0
            },
            cheque: {
              label: 'Средний чек, руб',
              key: 'cheque',
              value: [0, this.rangesFilters.cheque.max],
              max: this.rangesFilters.cheque.max,
              disabled: this.rangesFilters.cheque.max === 0
            },
            sales: {
              label: 'Кол-во продаж, шт',
              key: 'sales',
              value: [0, this.rangesFilters.sales.max],
              max: this.rangesFilters.sales.max,
              disabled: this.rangesFilters.sales.max === 0
            },
            remains: {
              label: 'Остатки, шт',
              key: 'remains',
              value: [0, this.rangesFilters.remains.max],
              max: this.rangesFilters.remains.max,
              disabled: this.rangesFilters.remains.max === 0
            },
            revenue: {
              label: 'Выручка, руб',
              key: 'revenue',
              value: [0, this.rangesFilters.revenue.max],
              max: this.rangesFilters.revenue.max,
              disabled: this.rangesFilters.revenue.max === 0
            }
          })
        )
      }
    },
    saveRangeFiltersValues() {
      this.rangeFiltersPrevVal = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    applyRangeFilters() {
      this.saveRangeFiltersValues()
      this.paginationOptions.page = 1
      this.setUrlParams()
      this.loadBrands({
        ...this.filters,
        search: this.searchValue,
        ...this.rangesFilters,
        sortOptions: this.sortOptions,
        ...this.paginationOptions
      })
    },
    sortList(titleId) {
      if (this.sortOptions.id === titleId) {
        this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
      } else {
        this.sortOptions.direction = '1'
      }
      this.sortOptions.id = titleId
      this.setUrlParams()
      this.loadBrands({
        ...this.filters,
        search: this.searchValue,
        ...this.rangesFilters,
        sortOptions: this.sortOptions,
        ...this.paginationOptions
      })
    },
    async loadSliders(filters) {
      this.$emit('loadingStarted')
      this.preloader = true
      this.rangePreloader = true

      try {
        const { data } = await this.$axios.get(...getBrandsRangeFiltersMaxValue(filters))
        const res = data.data

        if (res) {
          for (const k in res) res[k] = Math.ceil(res[k])
        }

        this.rangesFilters.products.value = [0, res.products_max]
        this.rangesFilters.products.disabled = res.products_max === 0

        this.rangesFilters.sellers.value = [0, res.sellers_max]
        this.rangesFilters.sellers.disabled = res.sellers_max === 0

        this.rangesFilters.cheque.value = [0, res.avg_check_max]
        this.rangesFilters.cheque.disabled = res.avg_check_max === 0

        this.rangesFilters.sales.value = [0, res.sales_max]
        this.rangesFilters.sales.disabled = res.sales_max === 0

        this.rangesFilters.remains.value = [0, res.quantity_max]
        this.rangesFilters.remains.disabled = res.quantity_max === 0

        this.rangesFilters.revenue.value = [0, res.proceeds_max]
        this.rangesFilters.revenue.disabled = res.proceeds_max === 0

        // this.rangesFilters.lostRevenue.value = [0, res.lost_proceeds_max]
        // this.rangesFilters.lostRevenue.disabled = true

        setTimeout(() => {
          this.rangesFilters.products.max = res.products_max
          this.rangesFilters.sellers.max = res.sellers_max
          this.rangesFilters.cheque.max = res.avg_check_max
          this.rangesFilters.sales.max = res.sales_max
          this.rangesFilters.remains.max = res.quantity_max
          this.rangesFilters.revenue.max = res.proceeds_max
          // this.rangesFilters.lostRevenue.max = Math.ceil(res.lost_proceeds_max)
          this.saveDefaultRangeFilters()
          this.saveRangeFiltersValues()
          this.loadBrands({
            ...this.filters,
            search: this.searchValue,
            ...this.rangesFilters,
            sortOptions: this.sortOptions,
            ...this.paginationOptions
          })
          this.setUrlParams()
        })
      } catch (err) {
        this.preloader = false
        console.log(err)
      } finally {
        this.$emit('loadingFinished')
        this.rangePreloader = false
      }
    },
    async loadBrands(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getBrandsList(filters))
        this.brandsData.data = data.data || []
        this.brandsData.total = data.paging?.total || 0

        this.paginationOptions.total = data.paging?.total
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    // downloadXLSX() {}
    setUrlParams() {
      if (this.$route.name === 'category_page') {
        this.$router
          .replace(
            `?title=${this.$route.query.title}&child=${this.$route.query.child}&state=${
              this.$route.query.state
            }&default_picker_action=${this.defaultPickerAction}&mp_code=${this.filters.code}&${getBrandsList({
              ...this.filters,
              search: this.searchValue,
              ...this.rangesFilters,
              sortOptions: this.sortOptions,
              ...this.paginationOptions
            })[1].params.toString()}`
          )
          .catch(() => {})
      }
    }
  }
}
</script>
