<template>
  <div class="brand__dynamics">
    <div class="grid__row">
      <div class="row__item">
        <x-label :size="'xs'">Категория</x-label>
        <div :class="{ disabled: preloader }" @click="$emit('categoriesModalOpen')">
          <category-button :value="filters.category" @input="clearCategory" />
        </div>
      </div>
      <div class="row__item">
        <x-label :size="'xs'">Показать</x-label>
        <date-select
          :preloader="preloader"
          :period-action="defaultPickerAction"
          :period="filters.period"
          @selectPeriod="onSelectPeriod"
        >
        </date-select>
        <!-- <div :class="{ disabled: preloader }" @click="$refs.datePicker.$modal.show('datepicker-modal')">
              <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
            </div> -->
      </div>
      <div class="row__item">
        <div class="label__fake"></div>
        <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
      </div>
    </div>
    <div class="row__container">
      <chart-labels :chart-data="chartData.datasets" :class="{ disabled: preloader }" @hideChart="hideChart"></chart-labels>
      <selected-period :period="filters.period"></selected-period>
    </div>
    <Chart ref="chart" :chart-data="chartDataFiltered" :options="options" :preloader="preloader" />
    <div class="title__row">
      <h3 class="statistics__title">Анализ динамики</h3>
      <x-btn class="download__btn" color="gray" :disabled="preloader || downloadPreloader" @click="downloadXLSX">
        <x-icon class="download__icon" name="download" size="s"></x-icon>
        Скачать в XLSX
        <x-loader v-if="downloadPreloader" size="xs"></x-loader>
      </x-btn>
    </div>
    <dynamics-list :period-list="chartData.labels" :datasets="chartData.datasets" :preloader="preloader"></dynamics-list>
  </div>
</template>

<style lang="stylus" scoped>

.grid__row
  display grid
  grid-template-columns repeat(2, calc(25% - 9px)) 1fr
  grid-auto-rows auto
  grid-gap 12px
  margin-bottom 24px

.label__fake
  margin-bottom 7px
  height 14px

@media (max-width 1280px)
  .grid__row
    grid-template-columns repeat(3, 1fr)

@media (max-width 1470px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(3, 1fr)

@media (max-width 1050px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

@media (max-width 900px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 992px)
  .grid__row
    grid-template-columns repeat(2, minmax(254px, 1fr))

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

.row__container
  display flex
  align-items center
  margin-bottom 5px

.chart__labels
  border-right 1px solid #ededed
  padding-right 12px
  height 26px

@media (max-width 1200px)
  .x-content--collapsed .row__container
    display block

  .x-content--collapsed .chart__labels
    border none

  .x-content--collapsed .current__period
    padding 0 !important

@media (max-width 992px)
  .row__container
    display block

  .chart__labels
    border none

  .current__period
    padding 0 !important

@media (max-width 960px)
  .x-content--collapsed .chart__labels
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-auto-rows 36px
    height auto

@media (max-width 768px)
  .x-content--collapsed .chart__labels
    grid-template-columns repeat(2, 1fr)

@media (max-width 740px)
  .chart__labels
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-auto-rows 36px
    height auto

@media (max-width 670px)
  .x-content--collapsed .chart__labels
    grid-template-columns 1fr

@media (max-width 570px)
  .chart__labels
    grid-template-columns repeat(2, 1fr)

@media (max-width 420px)
  .chart__labels
    grid-template-columns 1fr

.title__row
  display flex
  align-items center
  margin 20px 0 12px

.statistics__title
  color rgba(44,44,55,0.898)
  font-size $font-size-l

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit
    cursor inherit

.disabled
  opacity .5
  pointer-events none
</style>

<script>
import Chart from '@/ui/chart/Chart'
import DynamicsList from '@/components/DynamicsList.vue'
import ChartLabels from '@/ui/chart/ChartLabels.vue'
// import { getDynamicsData, getDynamicsFile } from '@/api/sellerPage/index.js'
import calcPeriod from '@/mixins/calcPeriod.js'

export default {
  mixins: [calcPeriod],
  components: {
    ChartLabels,
    Chart,
    DynamicsList
  },
  props: {
    filters: {
      type: Object
    },
    preloader: {
      type: Boolean,
      default: false
    },
    defaultPickerAction: {
      type: String
    },
    headerData: {
      type: Object
    }
  },
  data() {
    return {
      downloadPreloader: false,
      chartData: {
        type: 'line',
        labels: [],
        datasets: [
          {
            id: 'sellers',
            label: 'Продавцы, шт',
            selected: true,
            data: [],
            backgroundColor: '#FD8E39',
            borderColor: ['#FD8E39'],
            borderWidth: 2,
            yAxisID: 'sellers'
          },
          {
            id: 'products',
            label: 'Товары, шт',
            selected: true,
            data: [],
            backgroundColor: '#2CC545',
            borderColor: ['#2CC545'],
            borderWidth: 2,
            yAxisID: 'products'
          },
          {
            id: 'sales',
            label: 'Продажи, шт',
            selected: true,
            data: [],
            backgroundColor: '#43B2D4',
            borderColor: ['#43B2D4'],
            borderWidth: 2,
            yAxisID: 'sales'
          },
          {
            id: 'leftovers',
            label: 'Остатки, шт',
            selected: true,
            data: [],
            backgroundColor: '#ED5051',
            borderColor: ['#ED5051'],
            borderWidth: 2,
            yAxisID: 'leftovers'
          },
          {
            id: 'proceeds',
            label: 'Выручка, руб',
            selected: true,
            data: [],
            backgroundColor: '#3E66FB',
            borderColor: ['#3E66FB'],
            borderWidth: 2,
            yAxisID: 'proceeds'
          }
          // {
          //   id: 'lost_proceeds',
          //   label: 'Упущенная выручка, руб',
          //   data: [],
          //   backgroundColor: ['#ff6a00'],
          //   borderColor: ['#ff6a00'],
          //   borderWidth: 2
          // }
        ]
      },
      options: {
        spanGaps: true,
        interaction: {
          intersect: false
        },
        plugins: {
          tooltip: {
            boxPadding: 5,
            interaction: {
              mode: 'index'
            }
          },
          legend: {
            display: false
            // labels: {
            //   usePointStyle: true,
            //   pointStyle: 'rect'
            // }
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3,
        scales: {
          proceeds: {
            id: 'proceeds',
            type: 'linear',
            display: true,
            position: 'right',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#3E66FB'
            }
          },
          leftovers: {
            id: 'leftovers',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#ED5051'
            }
          },
          sales: {
            id: 'sales',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#43B2D4'
            }
          },
          products: {
            id: 'products',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: false
            },
            ticks: {
              color: '#2CC545'
            }
          },
          sellers: {
            id: 'sellers',
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: true
            },
            ticks: {
              color: '#FD8E39'
            }
          }
        }
      }
    }
  },
  watch: {
    filters(filters) {
      this.loadDynamics(filters)
      this.setUrlParams()
    }
  },
  computed: {
    chartDataFiltered() {
      return { ...this.chartData, datasets: this.chartData.datasets.filter(el => el.selected) }
    }
  },
  created() {
    this.loadDynamics(this.filters)
  },
  methods: {
    hideChart(item) {
      this.options.scales[item.id].display = item.selected
    },
    clearCategory(category) {
      this.$emit('clearCategory', category)
    },
    onSelectPeriod(val) {
      this.$emit('periodChanged', val)
    },
    switchFbs(fbs) {
      this.$emit('switchFbs', fbs)
    },
    loadDynamics(filters) {
      this.$emit('startLoading')
      try {
        // const { data } = await this.$axios.get(...getRemainsSales(filters))
        this.chartData.labels = this.calcPeriod

        // if (data && data.data) {
        // if (data.data.length > this.calcPeriod.length) data.data.pop()
        this.chartData.datasets.forEach(el => {
          el.rows = this.chartData.labels.map(item => {
            return {
              value: Math.floor(Math.random() * 10000),
              difference: Math.random() * 100
            }
          })

          el.data = this.chartData.labels.map(el => Math.floor(Math.random() * 10000))
        })
        // }
      } catch (err) {
        console.log(err)
      } finally {
        this.$emit('endLoading')
      }
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    },
    downloadXLSX() {},
    setUrlParams() {
      if (this.$route.name === 'brand_page') {
        const { fbs, period, category } = this.filters

        const query = {
          ...this.$route.query,
          is_fbs: fbs,
          category_id: category.external_id,
          category_name: category.name,
          default_picker_action: this.defaultPickerAction,
          date_from: this.formatDate(period.start, '-'),
          date_to: this.formatDate(period.end, '-')
        }

        this.$router.replace(`?${new URLSearchParams(query).toString()}`).catch(() => {})
      }
    }
  }
}
</script>
