import Vue from 'vue'
import VueRouter from 'vue-router'
import SellersPage from '@/views/sellersPage/SellersPage.vue'
import SellerPage from '@/views/sellerPage/SellerPage.vue'
import Summary from '@/views/summary/Summary.vue'
import Categories from '@/views/Сategories.vue'
import CategoryPage from '@/views/categoryPage/CategoryPage.vue'
import Brands from '@/views/Brands.vue'
import BrandPage from '@/views/brandPage/BrandPage.vue'
import Products from '@/views/Products.vue'
import ProductPage from '@/views/productPage/ProductPage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: { name: 'summary' }
  },
  {
    path: '/',
    name: 'summary',
    component: Summary,
    meta: {
      keepAlive: false,
      title: 'Панель управления',
      metrics: {
        screen: 'orm_tools'
      },
      metricsEvents: {
        add_seller_to_favorites: 'orm_tools_sellers_select',
        remove_seller_from_favorites: 'orm_tools_sellers_unselect'
      },
      permissions: {
        permissionKey: 'orm.analyse_sellers'
      }
    }
  },
  {
    path: '/sellers',
    name: 'sellers',
    component: SellersPage,
    meta: {
      keepAlive: true,
      title: 'Продавцы',
      metrics: {
        screen: 'orm_sellers'
      },
      metricsEvents: {
        seller_select: 'orm_sellers_seller'
      },
      permissions: {
        permissionKey: 'orm.analyse_sellers'
      }
    }
  },
  {
    path: '/sellers/:id(\\d+)',
    name: 'seller_page',
    component: SellerPage,
    meta: {
      keepAlive: false,
      metrics: {
        screen: 'orm_sellers_seller'
      },
      metricsEvents: {
        product_select: 'orm_sellers_seller_product'
      },
      permissions: {
        permissionKey: 'orm.analyse_sellers'
      }
    }
  },
  {
    path: '/categories',
    name: 'categories',
    component: Categories,
    meta: {
      keepAlive: true,
      title: 'Категории',
      metrics: {
        screen: 'orm_categories'
      },
      metricsEvents: {
        category_select: 'orm_categories_category'
      },
      permissions: {
        permissionKey: 'orm.analyse_categories'
      }
    }
  },
  {
    path: '/categories/:id(\\d+)',
    name: 'category_page',
    component: CategoryPage,
    meta: {
      keepAlive: false,
      metrics: {
        screen: 'orm_categories_category'
      },
      metricsEvents: {
        product_select: 'orm_categories_category_products_product',
        seller_select: 'orm_categories_category_sellers_seller',
        brand_select: 'orm_categories_category_brands_brand'
      },
      permissions: {
        permissionKey: 'orm.analyse_categories'
      }
    }
  },
  {
    path: '/brands',
    name: 'brands',
    component: Brands,
    meta: {
      keepAlive: true,
      title: 'Бренды',
      metrics: {
        screen: 'orm_brands'
      },
      metricsEvents: {
        brand_select: 'orm_brands_brand'
      },
      permissions: {
        permissionKey: 'orm.analyse_brands'
      }
    }
  },
  {
    path: '/brands/:id(\\d+)',
    name: 'brand_page',
    component: BrandPage,
    meta: {
      keepAlive: false,
      metrics: {
        screen: 'orm_brands_brand'
      },
      metricsEvents: {
        product_select: 'orm_brands_brand_products_product',
        seller_select: 'orm_brands_brand_sellers_seller'
      },
      permissions: {
        permissionKey: 'orm.analyse_brands'
      }
    }
  },
  {
    path: '/products',
    name: 'products',
    component: Products,
    meta: {
      keepAlive: true,
      title: 'Товары',
      metrics: {
        screen: 'orm_products'
      },
      metricsEvents: {
        product_select: 'orm_products_product'
      },
      permissions: {
        permissionKey: 'orm.analyse_products'
      }
    }
  },
  {
    path: '/products/:id(\\d+)',
    name: 'product_page',
    component: ProductPage,
    meta: {
      keepAlive: false,
      metrics: {
        screen: 'orm_products_product'
      },
      permissions: {
        permissionKey: 'orm.analyse_products'
      }
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
