<template>
  <div class="product__list">
    <not-permit-by-tariff
      v-if="!isPermitByTariff"
    />
    <template v-else>
      <div class="tooltip__container">
        <tooltip ref="tooltip" class="product__List__tooltip" :style="{ left: `${tooltipLeft}px` }">{{ tooltipText }}</tooltip>
      </div>
      <div ref="mainContainer" class="list__wrapper">
        <div ref="scrollingContainer" class="list__container">
          <div class="list" :class="{ list_morecolumns: moreColumns }">
            <div class="list__header">
              <div
                v-for="item in headerList"
                :key="item.id"
                class="list__header__title"
                :class="{
                  disabled: item.id === 'dynamics' || item.id === 'empty_first' || !productsData.data.length,
                  cursor__default: item.id === 'seller_name'
                }"
                @click="$emit('sort', item.id)"
              >
                <div class="list__header__title__text" @mouseenter="showTooltip($event, item)" @mouseleave="hideTooltip()">
                  {{ item.name }}
                  <div
                    v-if="productsData.data.length"
                    class="sort__icon"
                    :class="{ sort__icon_active: item.id === sortOptions.id, sort__icon_asc: sortOptions.direction === '0' }"
                  >
                    <x-icon name="arrow-down" size="xs"></x-icon>
                  </div>
                </div>
              </div>
            </div>
            <div v-for="item in productsData.data" :key="item.product_id" class="list__item">
              <div class="list__item__section" />
              <div v-if="moreColumns" class="list__item__section">
                <div class="list__item__value list__item__value_multiple">
                  <x-link
                    class="name__link"
                    :to="{
                      name: 'seller_page',
                      params: { id: item.seller_id },
                      query: {
                        mp_code: item.mp_code,
                        default_picker_action: defaultPickerAction,
                        is_fbs: fbs.toString(),
                        date_from: formatDate(period.start, '-'),
                        date_to: formatDate(period.end, '-'),
                        category_id: category.external_id,
                        category_name: category.name
                      }
                    }"
                  >
                    {{ item.seller_name }}
                  </x-link>
                </div>
              </div>
              <!-- <div class="list__item__section">
                <div class="list__item__value list__item__value_multiple">
                  <span>{{ item.brand_name }}</span>
                </div>
              </div> -->
              <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ (+item.reviews_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value number__value" :class="{ dropped__profit: +item.reviews_delta < 0 }">
                  <span>
                    {{ formatValue(item.reviews_delta) }}
                  </span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ item.rating }}</span>
                </div>
                <div class="stars__rating">
                  ★★★★★
                  <div class="stars__rating__active" :style="{ width: `${calcWidth(item.rating)}%` }">★★★★★</div>
                </div>
              </div>
              <!-- <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ (+item.in_favorites_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value number__value"><span>{{ isValue(item.in_favorites_delta) }}</span></div>
              </div> -->
              <div class="list__item__section">
                <div class="list__item__value list__item__value_price number__value">
                  <span>{{ Math.floor(+item.price).toLocaleString('ru') }}</span>
                </div>
                <div class="price_discount">
                  со скидкой <span>{{ item.discount }}</span
                  >%:
                </div>
                <div class="price_discount__value number__value">
                  {{ Math.floor(+item.regular_price).toLocaleString('ru') }}
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ (+item.sales_cnt).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value number__value" :class="{ dropped__profit: +item.sales_delta < 0 }">
                  <span>{{ isValue(item.sales_delta) }}</span>
                </div>
              </div>
              <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ (+item.quantity_cnt).toLocaleString('ru') }}</span>
                </div>
                <!-- <div class="profit__value" :class="{ dropped__profit: +item.quantity_delta < 0 }">
                  <span>{{ isValue(item.quantity_delta) }}</span>
                </div> -->
              </div>
              <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ Math.floor(+item.proceeds).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value number__value" :class="{ dropped__profit: +item.proceeds_delta < 0 }">
                  <span>{{ isValue(item.proceeds_delta) }}</span>
                </div>
              </div>
              <!-- <div class="list__item__section">
                <div class="list__item__value number__value">
                  <span>{{ Math.floor(+item.lost_proceeds).toLocaleString('ru') }}</span>
                </div>
                <div class="profit__value number__value" :class="{ dropped__profit: +item.lost_proceeds_delta < 0 }">
                  <span>{{ isValue(item.lost_proceeds_delta) }}</span>
                </div>
              </div> -->
              <!-- <div class="list__item__section">
                <img class="dynamics" src="@/assets/img/dynamics.svg" />
              </div> -->
            </div>
          </div>
        </div>
        <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
        <x-placeholder
          v-if="!+productsData.total && !preloader"
          title="Запрашиваемые данные не найдены"
          icon="no-data"
          class="list__nodata"
        />
        <div class="names__container">
          <div class="names__container__content">
            <div class="names__container__title">Товар</div>
            <div v-for="item in productsData.data" :key="item.product_id" class="item__container">
              <div class="image">
                <x-img :src="imageUrl(item.product_url)"></x-img>
              </div>
              <div class="text__container">
                <div @click.capture="sendEvent(item)">
                  <x-link
                    :title="item.product_name"
                    :to="{
                      name: 'product_page',
                      params: { id: item.product_id },
                      query: {
                        mp_code: item.mp_code,
                        default_picker_action: defaultPickerAction,
                        is_fbs: fbs.toString(),
                        date_from: formatDate(period.start, '-'),
                        date_to: formatDate(period.end, '-')
                      }
                    }"
                    class="item__name"
                  >
                    {{ item.product_name }}
                  </x-link>
                </div>
                <div class="item__article">
                  <x-icon :name="marketPlaceValues[item.mp_code].icon" size="xxs" />
                  Артикул: <span>{{ item.product_id }}</span>
                </div>
                <div class="item__category">
                  <!-- <x-icon :name="marketPlaceValues[item.mp_code].icon" size="xxs" /> -->
                  <span>{{ item.category_path && item.category_path.split('/')[1] }}</span>
                  <tooltip v-if="item.category_path" class="tooltip__toright">
                    <div class="tooltip__path">{{ removeLast(item.category_path) }}</div>
                  </tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="tracked__container">
          <div class="tracked__buttons">
            <div class="tracked__item" />
            <div
              v-for="item in productsData.data"
              :key="item.id"
              class="tracked__item"
              :class="{ tracked__item__active: item.is_favorite_product }"
              @click="trackedToggle(item)"
            >
              <!-- <x-icon name="bookmark" size="xs"></x-icon> -->
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<style lang="stylus" scoped>
.product__list
  position relative
  flex-grow 1
  min-height 370px

.tooltip__container
  position absolute
  width 100%
  left: 0
  top 0

.product__List__tooltip
  bottom 0 !important
  z-index 3
  transform translateX(0) !important

.list__wrapper
  position relative
  overflow hidden
  user-select none
  padding-bottom 20px
  min-height 370px
  height 100%

.list__container
  position relative
  overflow-x auto
  display flex
  height 100%
  &::-webkit-scrollbar
    height 8px
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    box-shadow 0px 2px 4px rgba(0, 0, 0, 0.05)
    border-radius 4px

.list
  position relative
  padding-bottom 12px
  flex-grow 1
  height 100%

.list__header
  position relative
  display grid
  // grid-template-columns  274px repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr) minmax(112px, 1fr) 50px
  // grid-template-columns  274px repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr)
  // grid-auto-rows 34px
  // grid-template-columns  274px minmax(100px, 1fr) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) 10px
  grid-template-columns  274px repeat(2, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) 10px
  grid-auto-rows 34px
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light

.list_morecolumns .list__header
  // grid-template-columns  minmax(274px, 1fr)  minmax(150px, 1fr) repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr) minmax(112px, 1fr) 50px
  // grid-template-columns  minmax(274px, 1fr)  minmax(150px, 1fr) repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr)
  grid-template-columns minmax(274px, 1fr)  minmax(150px, 1fr) minmax(100px, 100px) minmax(100px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) 10px

.list__header__title
  display flex
  justify-content flex-end
  align-items center
  color $colors.gray-darker
  font-size $font-size-s
  padding 0 8px
  cursor pointer
  // &:nth-child(2)
  //   justify-content flex-start
  &:last-of-type
    padding-right 10px

.cursor__default
  cursor default

// .list_morecolumns  .list__header__title:nth-child(3)
//   justify-content flex-start

.list_morecolumns  .list__header__title:nth-child(2)
  justify-content flex-start

.list__header__title__text
  position relative

.sort__icon
  position absolute
  top 50%
  right -18px
  transform translateY(-38%)
  display none
  pointer-events none

.sort__icon_active
  display block

.sort__icon_asc
  transform translateY(-53%) rotateZ(-180deg)

.list__item
  position relative
  display grid
  // grid-template-columns  minmax(274px, 1fr) repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr) minmax(112px, 1fr) 50px
  // grid-template-columns  274px repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr)
  // grid-template-columns  274px minmax(100px, 1fr) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) 10px
  grid-template-columns 274px repeat(2, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) 10px
  grid-auto-rows 89px
  border-bottom 1px solid $colors.gray-light
  line-height 18px
  font-size $font-size-s
  color $colors.gray-darkest-2

.list_morecolumns .list__item
  // grid-template-columns  minmax(274px, 1fr)  minmax(150px, 1fr) repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr) minmax(112px, 1fr) 50px
  // grid-template-columns  minmax(274px, 1fr)  minmax(150px, 1fr) repeat(3, minmax(100px, 1fr)) minmax(120px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) minmax(160px, 1fr)
  grid-template-columns  minmax(274px, 1fr)  minmax(150px, 1fr)  minmax(100px, 100px) minmax(100px, 1fr) repeat(3, minmax(115px, 1fr)) minmax(120px, 1fr) 10px

.list__item__section
  padding 8px
  &:last-of-type
    padding-right 10px

.dynamics
  width 56px
  height 28px
  display block
  margin-left auto

.stars__rating
  position relative
  width 64px
  height 18px
  font-size 15px
  color #ccc
  margin-left auto
  margin-top 1px
  margin-right -2px
  // background url('../assets/icons/star.svg') repeat-x

.stars__rating__active
  position absolute
  top 0
  left 0
  height 100%
  overflow hidden
  color #FFC539
  // background url('../assets/icons/star_active.svg') repeat-x

.list__item__value
  width 100%
  text-align right
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.list__item__value_multiple
  & span
    text-align left
    white-space normal
    text-overflow inherit
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-decoration underline
  & a
    text-align left
    white-space normal
    text-overflow inherit
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    -webkit-box-orient: vertical;
    text-decoration underline

.name__link
  display -webkit-box
  -webkit-line-clamp 3
  overflow hidden
  -webkit-box-orient vertical
  height 100%
  padding 0
  line-height 18px
  color $colors.gray-darkest-2

.list__item__value_price
  color: $colors.primary-dark

.number__value
  font-family Arial, Helvetica, sans-serif
  & span
    font-family Arial, Helvetica, sans-serif

.price_discount
  color $colors.primary-dark
  font-size 12px
  line-height 17px
  font-weight normal
  text-align right
  & span
    font-family Arial, Helvetica, sans-serif

.price_discount__value
  color: $colors.gray-darker
  font-weight $font-weights.semibold
  text-align right

.profit__value
  width 100%
  text-align right
  color $colors.success
  height 18px
  font-weight 600
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.dropped__profit
  color $colors.error

.names__container
  position absolute
  top 0
  left 0
  width 254px
  height calc(100% - 37px)
  background #fff
  &:after
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    background #fff
    top 0
    right  0
    box-shadow 6px 0 18px rgba(0,0,0,.15)

.names__container__content
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff
  z-index 1

.names__container__title
  display flex
  align-items center
  height 36px
  font-size $font-size-s
  color: $colors.gray-darkest-2
  font-weight $font-weights.semibold
  border-top 1px solid $colors.gray-light
  border-bottom 1px solid $colors.gray-light
  padding 0 8px

.item__container
  display flex
  height 90px
  border-bottom 1px solid $colors.gray-light
  padding 8px 0
  font-size $font-size-s
  color $colors.gray-darkest-2
  -webkit-box-orient: vertical;

.image
  margin-right 12px

.text__container
  flex-grow 1
  width 1px

.item__name
  text-decoration underline
  color $colors.gray-darkest-2
  font-size $font-size-s
  line-height 18px
  display -webkit-box
  -webkit-line-clamp 2
  overflow hidden
  -webkit-box-orient vertical
  margin-right 4px

.item__article
  color $colors.gray-darker
  display flex
  align-items center
  font-size 12px
  line-height 16px
  & span
    display block
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  & svg
    margin-right 3px

.item__category
  position relative
  color $colors.gray-darker
  font-size 12px
  display flex
  align-items center
  line-height 16px
  & span
    text-decoration underline
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
  &:hover .tooltip
    visibility visible
    opacity 1

.item__category .tooltip
  left -20px
  bottom 24px
  transform none

.tooltip__path
  white-space nowrap

.tracked__container
  position absolute
  top 0
  // right 0
  // width 48px
  right -24px
  width 24px
  height calc(100% - 37px)
  &:before
    content ''
    position absolute
    width 10px
    height calc(100% - 12px)
    top 0
    left 0
    box-shadow -6px 0 18px rgba(0,0,0,.15)

.tracked__buttons
  position absolute
  top 0
  left 0
  width 100%
  height 100%
  background #fff

.tracked__item
  position relative
  // border-bottom 1px solid $colors.gray-light
  display flex
  align-items center
  justify-content center
  height 90px
  color: $colors.gray-dark
  fill: transparent
  transition .2s
  // cursor pointer
  pointer-events none
  &:nth-child(1)
    height 36px
    cursor default
    background none
    // border-top 1px solid $colors.gray-light

.tracked__item__active
  color: $colors.primary
  fill: $colors.primary

.list__nodata
  position absolute
  top 50%
  left 50%
  transform: translate(-50%, -50%)

.x-loader--overlay
  z-index 5

.disabled
  pointer-events none
</style>

<script>
import scrollList from '@/mixins/scrollList.js'
import { marketPlaceValues } from '@/constants/index.js'
import permissions from '@/mixins/permissions.js'

export default {
  mixins: [scrollList, permissions],
  props: {
    productsData: {
      type: Object
    },
    preloader: {
      type: Boolean,
      default: false
    },
    moreColumns: {
      type: Boolean
    },
    sortOptions: {
      type: Object
    },
    period: {
      type: Object
    },
    fbs: {
      type: Boolean
    },
    category: {
      type: Object,
      default: () => ({
        external_id: '',
        name: ''
      })
    },
    defaultPickerAction: {
      type: String
    },
    screenId: {
      type: String,
      default: ''
    },
    eventName: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      marketPlaceValues,
      tooltipText: '',
      tooltipLeft: 0,
      headerList: [
        {
          id: 'empty_first',
          name: ''
        },
        {
          id: 'seller_name',
          name: 'Продавец',
          tooltip_text: 'Название продавца',
          width: 'auto'
        },
        // {
        //   id: 'brand_name',
        //   name: 'Бренд'
        // },
        {
          id: 'reviews_cnt',
          name: 'Отзывы',
          tooltip_text: 'Кол-во отзывов на сегодня, и кол-во добавленных отзывов за прошедшие сутки',
          width: '310px'
        },
        {
          id: 'rating',
          name: 'Оценка',
          tooltip_text: 'Рейтинг товара',
          width: '125px'
        },
        // {
        //   id: 'in_favorites_cnt',
        //   name: 'В избранном'
        // },
        {
          id: 'price',
          name: 'Цена, руб',
          tooltip_text: 'Цена и цена со скидкой',
          width: '180px'
        },
        {
          id: 'sales_cnt',
          name: 'Продажи, шт',
          tooltip_text: 'Количество проданных единиц за ',
          width: 'auto'
        },
        {
          id: 'quantity_cnt',
          name: 'Остатки, шт',
          tooltip_text: 'Количество товарных остатков на текущий момент',
          width: '354px'
        },
        {
          id: 'proceeds',
          name: 'Выручка, руб',
          tooltip_text: 'Выручка за ',
          width: 'auto'
        }
        // {
        //   id: 'lost_proceeds',
        //   name: 'Упущ. выручка, руб'
        // }
        // {
        //   id: 'dynamics',
        //   name: 'Динамика'
        // },
        // {
        //   id: 'empty_last',
        //   name: ''
        // }
      ]
    }
  },
  computed: {
    datePeriod() {
      switch (this.defaultPickerAction) {
        case 'SEVEN':
          return '7 дней'
        case 'FOURTEEN':
          return '14 дней'
        case 'THIRTY':
          return '30 дней'
        case 'NINETY':
          return '90 дней'
        case 'OTHER':
          return `${this.formatDate(this.period?.start, '.')} - ${this.formatDate(this.period?.end, '.')}`
      }
      return ''
    }
  },
  created() {
    if (!this.moreColumns) this.headerList.splice(1, 1)
  },
  methods: {
    sendEvent(item) {
      if (this.$route.meta.metricsEvents.product_select) {
        const payload = {
          category: this.category.external_id,
          marketplace_code: item.mp_code,
          item_id: item.product_id
        }

        if (this.$route.name === 'seller_page') payload.seller_id = item.seller_id

        if (this.$route.name === 'brand_page') payload.brand = item.brand_id

        this.$metrics.hit(this.$route.meta.metricsEvents.product_select, payload)
      }
    },
    showTooltip(e, item) {
      const ids = ['seller_name', 'reviews_cnt', 'rating', 'price', 'sales_cnt', 'quantity_cnt', 'proceeds']
      if (ids.includes(item.id)) {
        const tooltip = this.$refs.tooltip.$el
        const title = e.currentTarget
        tooltip.classList.add('tooltip_active')

        if (item.id === 'reviews_cnt') tooltip.classList.add('centered__text')

        if (item.id === 'sales_cnt' || item.id === 'proceeds') {
          tooltip.classList.add('no__wrapp')
          this.tooltipText = `${item.tooltip_text}${this.datePeriod}`
        } else {
          this.tooltipText = item.tooltip_text
        }

        tooltip.style.width = item.width

        setTimeout(() => {
          this.tooltipLeft =
            title.offsetLeft - this.$refs.scrollingContainer.scrollLeft + title.offsetWidth / 2 - tooltip.offsetWidth / 2
        })

        setTimeout(() => {
          if (tooltip.getBoundingClientRect().right > document.querySelector('.x-card').getBoundingClientRect().right) {
            this.tooltipLeft =
              this.tooltipLeft -
              (tooltip.getBoundingClientRect().right - document.querySelector('.x-card').getBoundingClientRect().right) -
              12

            tooltip.classList.add('arrow_right')
          }
        })
      }
    },
    hideTooltip() {
      const tooltip = this.$refs.tooltip.$el
      tooltip.classList.remove('tooltip_active')
      tooltip.classList.remove('arrow_right')
      tooltip.classList.remove('no__wrapp')
    },
    trackedToggle(item) {
      item.is_favorite_product = !item.is_favorite_product
    },
    imageUrl(url) {
      return url?.replace(/"/g, '')
    },
    isValue(value) {
      if (value === 0) return ''
      if (value > 0) return `+${value.toLocaleString('ru')}%`

      return `${value.toLocaleString('ru')}%`
    },
    formatValue(value) {
      if (value === 0) return ''
      if (value > 0) return `+${value.toLocaleString('ru')}`

      return `${value.toLocaleString('ru')}`
    },
    removeLast(path) {
      return path
        .split('/')
        .splice(0, 2)
        .join('/')
    },
    calcWidth(rating) {
      return (100 / 5) * rating
    },
    formatDate(date, separator) {
      return Intl.DateTimeFormat('ru')
        .format(date)
        .replace(/\./g, separator)
    }
  }
}
</script>
