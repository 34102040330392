<template>
  <div>
    <ul
      class="radiolist"
    >
      <li
        v-for="item in items"
        :key="`${name}_${item.value}`"
        class="radiolist__item"
      >
        <radio
          v-model="checked"
          :name="name"
          :value="item.value"
          @change="$emit('change', checked)"
        >
          <template slot>
            <span v-html="item.label" />
          </template>
        </radio>
      </li>
    </ul>

    <form-error
      v-if="errors"
      :text="errors[0]"
    />
  </div>
</template>

<script>
import Radio from './Radio.vue'
import FormError from './FormError.vue'

export default {
  model: {
    prop: 'checked',
    event: 'change'
  },

  components: {
    Radio,
    FormError
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: 'radio'
    },
    value: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: undefined
    }
  },

  data () {
    return {
      checked: this.value
    }
  },

  watch: {
    value () {
      this.checked = this.value
    }
  }
}
</script>

<style lang="stylus" scoped>
  .radiolist
    margin 0
    padding 0
    list-style none

    &__item
      margin-bottom 7px

      &:last-child
        margin-bottom 0
</style>
