import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getFavoritesSellers = ({ code, period, sortOptions, fbs, page, limit }) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)
  params.append('page', page)
  params.append('limit', limit)

  return [`${base}${code}/favourites_competitors_section/`, { params }]
}

export const getServices = () => `${base}service_template/list`
export const getBookedServices = () => `${base}booked_service/list`
export const orderService = () => `${base}booked_service/`
