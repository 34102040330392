var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"mainContainer",staticClass:"list__wrapper"},[_c('div',{ref:"scrollingContainer",staticClass:"list__container"},[_c('div',{staticClass:"list"},[_c('div',{staticClass:"list__header",style:({
          gridTemplateColumns: (_vm.width + "px repeat(" + (_vm.periodList.length) + ", " + (_vm.periodList.length > 1 ? ("minmax(" + _vm.calcWidth + ", 1fr)") : '100px'))
        })},[_c('div',{staticClass:"list__header__title"}),_vm._l((_vm.periodList),function(item){return _c('div',{key:item,staticClass:"list__header__title"},[_vm._v(_vm._s(item))])})],2),_vm._l((_vm.datasets),function(item){return _c('div',{key:item.label,staticClass:"list__item",style:({
          gridTemplateColumns: (_vm.width + "px repeat(" + (_vm.periodList.length) + ", " + (_vm.periodList.length > 1 ? ("minmax(" + _vm.calcWidth + ", 1fr)") : '100px'))
        })},[_c('div',{staticClass:"list__item__section"}),_vm._l((item.rows),function(row){return _c('div',{key:row.value + Math.random(),staticClass:"list__item__section"},[_c('div',{staticClass:"list__item__value"},[(row.value !== null)?_c('span',[_vm._v(_vm._s((+row.value).toLocaleString('ru')))]):_c('div',{staticClass:"list__item__icon"},[_c('x-icon',{attrs:{"name":"minus"}})],1)]),(row.value !== null)?_c('div',{staticClass:"profit__value",class:{
              profit__value_hidden: !row.difference,
              profit__down: +row.difference < 0
            }},[_c('span',[_vm._v(_vm._s(_vm.formatDelta(row.difference))+"%")])]):_vm._e()])})],2)})],2)]),(_vm.preloader)?_c('x-loader',{attrs:{"size":"s","overlay":true}}):_vm._e(),_c('div',{ref:"namesContainer",staticClass:"names__container"},[_c('div',{staticClass:"names__container__content"},[_c('div',{staticClass:"names__container__title"},[_vm._v("Показатели")]),_vm._l((_vm.datasets),function(item){return _c('div',{key:item.label,staticClass:"name__item"},[_c('div',{staticClass:"name__link"},[_vm._v(_vm._s(item.label))])])})],2)]),_vm._m(0)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shadow__container"},[_c('div',{staticClass:"shadow__content"})])}]

export { render, staticRenderFns }