<template>
  <div class="chart">
    <canvas ref="canvas" />
    <x-loader v-if="preloader" size="s" :overlay="true"></x-loader>
  </div>
</template>

<style lang="stylus" scoped>
.chart
  position relative
  height 300px
</style>

<script>
import Chart from './chart/dist/chart'

// import { Chart, registerables } from 'chart.js';
// Chart.register(...registerables);

export default {
  props: {
    preloader: {
      type: Boolean,
      default: false
    },
    chartData: {
      type: Object
    },
    options: {
      type: Object
    }
  },
  watch: {
    'chartData.datasets': {
      handler(val) {
        // this.$data._chart.update()
        this.renderChart(this.chartData, this.options, this.chartData.type)
      },
      deep: true
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options, this.chartData.type)
  },
  methods: {
    renderChart(data, options, type) {
      if (this.$data._chart) this.$data._chart.destroy()
      if (!this.$refs.canvas) {
        throw new Error(
          'Please remove the <template></template> tags from your chart component. See https://vue-chartjs.org/guide/#vue-single-file-components'
        )
      }
      this.$data._chart = new Chart(this.$refs.canvas.getContext('2d'), {
        type,
        data,
        options,
        plugins: this.$data._plugins
      })
    }
  }
}
</script>
