import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getHeaderData = ({ code, brand, period, fbs }) => {
  const params = new URLSearchParams()

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('is_fbs', fbs)

  return [`${base}${code}/brands/${brand.brand_id}/summary/`, { params }]
}
