import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getSellersRangeFiltersMaxValue = ({ code, category, brand, period, search, fbs, favoritesOnly }) => {
  const params = new URLSearchParams()

  params.append('mp_code', code)
  if (search) params.append('name', search)
  if (category.external_id) params.append('category_id', category.external_id)
  if (brand && brand.brand_id) params.append('brand_id', brand.brand_id)
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}competitors/sliders`, { params }]
}

export const getSellers = ({ code, search, favoritesOnly, fbs, category, brand, period, products, brands, cheque, sales, remains, revenue, lostRevenue, sortOptions, page, limit }) => {
  const params = new URLSearchParams()

  params.append('mp_code', code)
  if (search) params.append('name', search)
  if (category && category.external_id) params.append('category_id', category.external_id)
  if (brand && brand.brand_id) params.append('brand_id', brand.brand_id)
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)

  if (products && products.max) {
    params.append('products_cnt_from', Math.ceil(products.value[0]))
    params.append('products_cnt_to', Math.ceil(products.value[1]))
    params.append('products_max', Math.ceil(products.max))
  }

  if (/\/brands\/\d{1,50}/g.test(location.pathname)) {
    params.append('brands_cnt_from', 1)
    params.append('brands_cnt_to', 1)
  } else if (brands && brands.max) {
    params.append('brands_cnt_from', Math.ceil(brands.value[0]))
    params.append('brands_cnt_to', Math.ceil(brands.value[1]))
    params.append('brands_max', Math.ceil(brands.max))
  }

  if (cheque && cheque.max) {
    params.append('avg_check_from', Math.ceil(cheque.value[0]))
    params.append('avg_check_to', Math.ceil(cheque.value[1]))
    params.append('cheque_max', Math.ceil(cheque.max))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
    params.append('sales_max', Math.ceil(sales.max))
  }

  if (remains && remains.max) {
    params.append('quantity_cnt_from', Math.ceil(remains.value[0]))
    params.append('quantity_cnt_to', Math.ceil(remains.value[1]))
    params.append('remains_max', Math.ceil(remains.max))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_cnt_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_cnt_to', Math.ceil(revenue.value[1]))
    params.append('revenue_max', Math.ceil(revenue.max))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
    // params.append('lost_revenue_max', lostRevenue.max)
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)

  if (page) params.append('page', page)
  if (limit) params.append('limit', limit)

  return [`${base}competitors/sellers`, { params }]
}

// СКАЧАТЬ в XLSX

export const getFile = ({ code, search, favoritesOnly, fbs, category, period, products, brands, cheque, sales, remains, revenue, lostRevenue, sortOptions }) => {
  const params = new URLSearchParams()

  params.append('mp_code', code)
  if (search) params.append('name', search)
  if (category && category.external_id) params.append('category_id', category.external_id)
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)

  if (products && products.max) {
    params.append('products_cnt_from', Math.ceil(products.value[0]))
    params.append('products_cnt_to', Math.ceil(products.value[1]))
  }

  if (brands && brands.max) {
    params.append('brands_cnt_from', Math.ceil(brands.value[0]))
    params.append('brands_cnt_to', Math.ceil(brands.value[1]))
  }

  if (cheque && cheque.max) {
    params.append('avg_check_from', Math.ceil(cheque.value[0]))
    params.append('avg_check_to', Math.ceil(cheque.value[1]))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
  }

  if (remains && remains.max) {
    params.append('quantity_cnt_from', Math.ceil(remains.value[0]))
    params.append('quantity_cnt_to', Math.ceil(remains.value[1]))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_cnt_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_cnt_to', Math.ceil(revenue.value[1]))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)

  return `${base}${code}/competitors/xlsx?${params.toString()}`
}

//  Для того что бы зажигать флажки(находится ли продавец в избранном или нет) в таблицах продавцов
export const getFavoritesSellers = code => `${base}competitors/fovourites/${code}`

export const removeSellersFromFavorites = (code, sellerId) => `${base}competitors/sellers/${code}/${sellerId}`

export const addSellerToFavorites = () => `${base}competitors/sellers`
