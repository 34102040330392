import * as Types from './mutation-types'

export default {
  [Types.UPDATE_USER] (state, user) {
    state.user = user
  },

  [Types.ALERT_MODAL] (state, payload) {
    state.showAlertModal = payload
  },

  [Types.SET_STATUS_IS_LOADING] (state, payload) {
    state.isLoading = payload
  },

  [Types.SET_USER_STATUS] (state, payload) {
    state.isVIP = true
  },

  // [Types.GET_MARKETPLACES] (state, payload) {
  //   state.marketplacesTabs = payload
  // },

  [Types.UPDATE_SELLERS_FAVORITES_LIST] (state, payload) {
    state.sellersFavoritesList = payload
  }
}
