import { createNamespacedHelpers } from 'vuex'
const { mapState } = createNamespacedHelpers('ui')

export default {
  computed: {
    ...mapState(['showTariffRestriction', 'user']),
    isPermitByTariff () {
      return this.user.active_subscription?.permission_keys.includes(this.$route.meta.permissions.permissionKey)
    }
  }
}
