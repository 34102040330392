<template>
  <x-page title="Категории" card>
    <div class="page__categories">
      <div class="tabs__container">
        <x-tabs :tabs="marketplacesTabs" size="l" @change="selectTab" />
      </div>
      <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
        <div class="row__item">
          <x-label :size="'xs'">Показать</x-label>
          <date-select
            :preloader="preloader"
            :period-action="defaultPickerAction"
            :period="filters.period"
            @selectPeriod="onSelectPeriod"
          ></date-select>
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <fbs-switcher v-model="filters.fbs" :disabled="preloader" @input="sendEvent" />
        </div>
      </div>
      <!-- <div class="grid__row">
        <div class="row__item">
          <x-label :size="'xs'">Показать</x-label>
          <date-select
            :preloader="preloader"
            :period-action="defaultPickerAction"
            :period="filters.period"
            @selectPeriod="onSelectPeriod"
          ></date-select>
          <div :class="{ disabled: preloader }" @click="$refs.sellerPicker.$modal.show('datepicker-modal')">
            <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
          </div>
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <filters-button
            :opened="isOpened"
            :changes="slidersChanged"
            @click.native="openFiltersToggle"
            @resetFilters="resetFilters"
          />
        </div>
      </div> -->
      <!-- <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
        <div class="range__grid">
          <range-slider
            v-for="item in rangesFilters"
            :key="item.key"
            :label="item.label"
            :filter-key="item.key"
            :max="item.max"
            :disabled="item.disabled || preloader"
            :value="item.value"
            @rangefilterchanged="onRangeFiltersValue"
          />
          <div class="apply__button__row__item">
            <div class="apply__button__row">
              <div class="label__fake"></div>
              <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
                Применить
              </x-btn>
            </div>
          </div>
        </div>
        <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
      </div> -->
      <div v-if="isPermitByTariff" class="tracked__container__row">
        <div class="total">
          Всего: <span>{{ categoriesData.total.toLocaleString('ru') }}</span>
        </div>
        <selected-period :period="filters.period"></selected-period>
        <!-- <x-btn class="download__btn" color="gray" :disabled="preloader">
          <x-icon class="download__icon" name="download" size="s"></x-icon>
          Скачать в XLSX
        </x-btn> -->
      </div>
      <categories-list
        :categories-data="categoriesData"
        :preloader="preloader"
        :code="filters.code"
        :period="filters.period"
        :fbs="filters.fbs"
        :sort-options="sortOptions"
        :default-picker-action="defaultPickerAction"
        @startLoading="preloader = true"
        @endLoading="preloader = false"
        @sort="sortList"
      />
      <!-- <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" /> -->
      <!-- <date-picker-modal
        ref="sellerPicker"
        :value="filters.period"
        :default-period="defaultPickerAction"
        @change="onSelectPeriod"
      /> -->
    </div>
  </x-page>
</template>

<style lang="stylus" scoped>
.page__categories
  flex-grow 1
  display flex
  flex-direction column

.apply__button
  width 120px
  margin-left auto

.grid__row
  display grid
  grid-template-columns minmax(250px, 350px) auto
  grid-gap 12px
  grid-auto-rows auto
  margin-bottom 12px

.label__fake
  margin-bottom 7px
  height 14px

@media (max-width 868px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

// @media (max-width 1660px )
//   .x-content--collapsed .grid__row
//     grid-template-columns calc(100% / 12 * 4 - 6px) auto auto 1fr

// @media (max-width 1500px)
//   .grid__row
//     grid-template-columns calc(100% / 12 * 4 - 6px) auto auto 1fr

// @media (max-width 1350px )
//   .x-content--collapsed .grid__row
//     grid-template-columns calc(50% - 6px) auto auto 1fr

// @media (max-width 1200px)
//   .grid__row
//     grid-template-columns calc(50% - 6px) auto auto 1fr

// @media (max-width 1050px)
//   .x-content--collapsed .grid__row
//     grid-template-columns 1fr

// @media (max-width 768px)
//   .grid__row
//     grid-template-columns 1fr

// .label__fake
//   margin-bottom 7px
//   height 14px

// .filters__range__animate
//   position relative
//   overflow hidden
//   transition .3s
//   border-bottom 1px solid $colors.gray-light

// .apply__button__row__item
//   display flex
//   justify-content flex-end
//   grid-column span 2

// .range__grid
//   position relative
//   display grid
//   grid-template-columns repeat(4, calc(25% - 9px))
//   grid-gap 12px
//   padding 0 2px 12px

// .x-loader--overlay
//   z-index 10

// @media (max-width 1500px)
//   .range__grid
//     grid-template-columns repeat(3, minmax(200px, 1fr))

//   .apply__button__row__item
//     grid-column span 3

// @media (max-width 1660px)
//   .x-content--collapsed .range__grid
//     grid-template-columns repeat(3, minmax(200px, 1fr))

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 3

// @media (max-width 1350px)
//   .x-content--collapsed .range__grid
//     grid-template-columns repeat(2, 1fr)

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 2

// @media (max-width 1200px)
//   .range__grid
//     grid-template-columns repeat(2, minmax(200px, 1fr))

//   .apply__button__row__item
//     grid-column span 2

// @media (max-width 1050px)
//   .x-content--collapsed .range__grid
//     grid-template-columns 1fr

//   .x-content--collapsed .apply__button__row__item
//     grid-column span 1

// @media (max-width 768px)
//   .range__grid
//     grid-template-columns repeat(1, 1fr)

//   .apply__button__row__item
//     grid-column span 1

.x-label
  pointer-events none

.tracked__container__row
  display flex
  align-items center
  height 36px
  margin 16px 0 12px

.total
  border-right 1px solid #ededed
  font-size $font-size-s
  color: $colors.gray-darkest-2
  padding-right 12px
  height 100%
  line-height 36px
  & span
    font-weight bold

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit

.disabled
  pointer-events none
  opacity .5
</style>

<script>
// import filtersToggle from '@/mixins/filtersToggle.js'
import permissions from '@/mixins/permissions.js'
import CategoriesList from '@/components/categoriesList/CategoriesList'
import changeDocumentTitle from '@/mixins/changeDocumentTitle.js'
// import { getCategoriesRangeFiltersMaxValue } from '@/api/categories/index.js'
import { getCategoriesList } from '@/api/categories/index.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

const start = new Date()
const end = new Date()
start.setMonth(
  start.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(start)
    .replace(/\./g, '-')
    .split('-')[0] - 7
)

end.setMonth(
  end.getMonth(),
  Intl.DateTimeFormat('ru')
    .format(end)
    .replace(/\./g, '-')
    .split('-')[0] - 1
)

export default {
  // mixins: [filtersToggle, changeDocumentTitle],
  mixins: [changeDocumentTitle, permissions],
  components: {
    CategoriesList
  },
  data() {
    return {
      defaultPickerAction: this.$route.query.default_picker_action || 'SEVEN',
      preloader: false,
      rangePreloader: false,
      categoriesData: {
        data: [],
        total: 0
      },
      filters: {
        code: this.$route.query.mp_code || 'wb',
        period: {
          start: (() => {
            const date = this.$route.query.date_from
            if (!date) return start

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : start
          })(),
          end: (() => {
            const date = this.$route.query.date_to
            if (!date) return end

            const arr = date.split('-').reverse()
            const fixedDate = new Date(+arr[0], +arr[1] - 1, arr[2])
            return fixedDate !== 'Invalid Date' ? fixedDate : end
          })()
        },
        fbs: this.$route.query.is_fbs === 'true'
      },
      defaultRangsFilters: {},
      rangeFiltersPrevVal: {},
      rangesFilters: {
        sellers: {
          label: 'Продавцы',
          key: 'sellers',
          value: [0, 0],
          max: 0,
          disabled: true
        },
        brands: {
          label: 'Бренды',
          key: 'brands',
          value: [0, 0],
          max: 0,
          disabled: true
        },
        products: {
          label: 'Товары',
          key: 'products',
          value: [0, 0],
          max: 0,
          disabled: true
        },
        avgCheck: {
          label: 'Средний чек, руб',
          key: 'avgCheck',
          value: [0, 0],
          max: 0,
          disabled: true
        },
        sales: {
          label: 'Кол-во продаж, шт',
          key: 'sales',
          value: [0, 0],
          max: 0,
          disabled: true
        },
        revenue: {
          label: 'Выручка, руб',
          key: 'revenue',
          value: [0, 0],
          max: 0,
          disabled: true
        }
        // lostRevenue: {
        //   label: 'Упущенная выручка, руб',
        //   key: 'lostRevenue',
        //   value: [0, 40000],
        //   max: 50000,
        //   disabled: false
        // }
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: 10,
        offset: 0,
        total: 5,
        page: 1
      }
    }
  },
  watch: {
    filters: {
      handler(filters) {
        // this.loadSlidersMaxValues(filters)
        this.loadCategories({
          ...filters,
          ...this.rangesFilters,
          sortOptions: this.sortOptions,
          ...this.paginationOptions
        })
        this.setUrlParams()
      },
      deep: true
    }
  },
  computed: {
    ...mapState(['marketplacesTabs']),
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultRangsFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
    // sortedCategories() {
    //   return [...this.categoriesData.data].sort((a, b) =>
    //     this.sortOptions.direction === '1'
    //       ? b[this.sortOptions.id] - a[this.sortOptions.id]
    //       : a[this.sortOptions.id] - b[this.sortOptions.id]
    //   )
    // }
  },
  created() {
    // this.saveDefaultRangeFilters()
    // this.saveRangeFiltersValues()
    // this.loadSlidersMaxValues(this.filters)
    if (this.isPermitByTariff) {
      this.loadCategories({
        ...this.filters,
        ...this.rangesFilters,
        sortOptions: this.sortOptions,
        ...this.paginationOptions
      })
    }
  },
  activated() {
    setTimeout(() => {
      const meniItem = document.querySelector('.x-nav-item--active')
      if (meniItem) meniItem.classList.add('disabled__item')
    }, 500)
    this.setUrlParams()

    this.$metrics.hit('orm_categories_view')
  },
  deactivated() {
    document.querySelector('.x-nav-item--active').classList.remove('disabled__item')
  },
  methods: {
    sendEvent() {
      this.$metrics.hit(this.filters.fbs ? 'orm_categories_fbs' : 'orm_categories_fbo', {
        marketplace_code: this.filters.code
      })
    },
    selectTab(idx) {
      this.filters.code = this.marketplacesTabs[idx].mp_code
    },
    // onSelectPeriod(period, selectedAction) {
    //   this.filters = { ...this.filters, period }
    //   this.defaultPickerAction = selectedAction
    // },
    onSelectPeriod(val) {
      const currentDate = new Date()
      currentDate.setMonth(
        currentDate.getMonth(),
        Intl.DateTimeFormat('ru')
          .format(currentDate)
          .replace(/\./g, '-')
          .split('-')[0] - val.days
      )
      this.filters.period = { ...this.filters.period, start: currentDate, end }
      this.defaultPickerAction = val.id
    },
    resetFilters() {
      this.rangesFilters = JSON.parse(JSON.stringify(this.defaultRangsFilters))
    },
    onRangeFiltersValue({ val, key }) {
      this.rangesFilters = { ...this.rangesFilters, [key]: { ...this.rangesFilters[key], value: val } }
    },
    saveRangeFiltersValues() {
      this.rangeFiltersPrevVal = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    sortList(titleId) {
      if (this.sortOptions.id === titleId) {
        this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
      } else {
        this.sortOptions.direction = '1'
      }
      this.sortOptions.id = titleId
      this.setUrlParams()
      // this.loadSellers({ ...this.filters, ...this.rangeFiltersPrevVal, sortOptions: this.sortOptions, ...this.paginationOptions })
    },
    // applyRangeFilters() {
    //   this.saveRangeFiltersValues()
    //   this.paginationOptions.page = 1
    //   // this.setUrlParams()
    //   this.loadCategories()
    // },
    saveDefaultRangeFilters() {
      this.defaultRangsFilters = {
        ...this.defaultRangsFilters,
        ...JSON.parse(
          JSON.stringify({
            sellers: {
              label: 'Продавцы',
              key: 'sellers',
              value: [0, this.rangesFilters.sellers.max],
              max: this.rangesFilters.sellers.max,
              disabled: this.rangesFilters.sellers.max === 0
            },
            brands: {
              label: 'Бренды',
              key: 'brands',
              value: [0, this.rangesFilters.brands.max],
              max: this.rangesFilters.brands.max,
              disabled: this.rangesFilters.brands.max === 0
            },
            products: {
              label: 'Товары',
              key: 'products',
              value: [0, this.rangesFilters.products.max],
              max: this.rangesFilters.products.max,
              disabled: this.rangesFilters.products.max === 0
            },
            avgCheck: {
              label: 'Средний чек, руб',
              key: 'avgCheck',
              value: [0, this.rangesFilters.avgCheck.max],
              max: this.rangesFilters.avgCheck.max,
              disabled: this.rangesFilters.avgCheck.max === 0
            },
            sales: {
              label: 'Кол-во продаж, шт',
              key: 'sales',
              value: [0, this.rangesFilters.sales.max],
              max: this.rangesFilters.sales.max,
              disabled: this.rangesFilters.sales.max === 0
            },
            revenue: {
              label: 'Выручка, руб',
              key: 'revenue',
              value: [0, this.rangesFilters.revenue.max],
              max: this.rangesFilters.revenue.max,
              disabled: this.rangesFilters.revenue.max === 0
            }
          })
        )
      }
    },
    // async loadSlidersMaxValues(filters) {
    //   this.preloader = true
    //   this.rangePreloader = true

    //   try {
    //     const { data } = await this.$axios.get(...getCategoriesRangeFiltersMaxValue(filters))
    //     const res = data.data

    //     if (res) {
    //       for (const k in res) res[k] = Math.ceil(res[k])
    //     }

    //     // this.rangesFilters.sellers.value = [0, res.sellers_max]
    //     // this.rangesFilters.sellers.disabled = res.sellers_max === 0

    //     this.rangesFilters.brands.value = [0, res.brands_max]
    //     this.rangesFilters.brands.disabled = res.brands_max === 0

    //     this.rangesFilters.products.value = [0, res.products_max]
    //     this.rangesFilters.products.disabled = res.products_max === 0

    //     this.rangesFilters.avgCheck.value = [0, res.avg_check_max]
    //     this.rangesFilters.avgCheck.disabled = res.avg_check_max === 0

    //     this.rangesFilters.sales.value = [0, res.sales_max]
    //     this.rangesFilters.sales.disabled = res.sales_max === 0

    //     this.rangesFilters.revenue.value = [0, res.proceeds_max]
    //     this.rangesFilters.revenue.disabled = res.proceeds_max === 0

    //     setTimeout(() => {
    //       // this.rangesFilters.sellers.max = res.sellers_max
    //       this.rangesFilters.brands.max = res.brands_max
    //       this.rangesFilters.products.max = res.products_max
    //       this.rangesFilters.avgCheck.max = res.avg_check_max
    //       this.rangesFilters.sales.max = res.sales_max
    //       this.rangesFilters.revenue.max = res.proceeds_max
    //       // this.rangesFilters.lostRevenue.max = Math.ceil(res.lost_proceeds_max)
    //       this.saveDefaultRangeFilters()
    //       this.saveRangeFiltersValues()
    //       this.loadCategories()
    //       // this.setUrlParams()
    //     })
    //   } catch (err) {
    //     this.preloader = false
    //     console.log(err)
    //   } finally {
    //     this.rangePreloader = false
    //   }
    // },
    async loadCategories(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getCategoriesList(filters))
        if (data && data.data) {
          this.categoriesData.data = data.data

          this.categoriesData.total = data.paging.total
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.preloader = false
      }
    },
    setUrlParams() {
      if (this.$route.name === 'categories') {
        this.$router
          .replace(
            `?mp_code=${this.filters.code}&default_picker_action=${this.defaultPickerAction}&${getCategoriesList({
              ...this.filters,
              ...this.rangesFilters,
              sortOptions: this.sortOptions,
              ...this.paginationOptions
            })[1].params.toString()}`
          )
          .catch(() => {})
      }
    }
  }
}
</script>
