<template>
  <div class="statistics">
    <div class="period__container">
      <selected-period :period="filters.period"></selected-period>
    </div>
    <Chart :chart-data="chartData" :options="options" />
    <div class="title__row">
      <x-btn class="download__btn" color="gray">
        <x-icon class="download__icon" name="download" size="s"></x-icon>
        Скачать в XLSX
      </x-btn>
    </div>
    <dynamics-list :period-list="chartData.labels" :datasets="chartData.datasets"></dynamics-list>
  </div>
</template>

<style lang="stylus" scoped>
.period__container
  margin-left -12px
  margin-bottom 5px

.sales__scale
  margin-top 20px
  padding 0 12px 0 8px
  border-right 1px solid $colors.gray-light

.sales__scale__value
  position relative
  font-size 10px
  line-height 13px
  margin-bottom 9.4px
  text-align right
  font-weight bold
  &:after
    content: ''
    position absolute
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    width 8px
    height 1px
    background $colors.gray-light

.title__row
  display flex
  align-items center
  margin 20px 0 12px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit
    cursor inherit
</style>

<script>
import Chart from '@/ui/chart/Chart'
import DynamicsList from '@/components/DynamicsList.vue'
import calcPeriod from '@/mixins/calcPeriod.js'
// import { getDynamicsData } from '@/api/sellerPage/index.js'

export default {
  mixins: [calcPeriod],
  components: {
    Chart,
    DynamicsList
  },
  props: {
    filters: {
      type: Object
    }
  },
  data() {
    return {
      chartData: {
        type: 'bar',
        labels: [],
        datasets: [
          {
            id: 'proceeds',
            label: 'Выручка, руб',
            data: [],
            backgroundColor: ['#3E66FA']
          },
          {
            id: 'lost_proceeds',
            label: 'Упущенная выручка, руб',
            data: [],
            backgroundColor: ['#3E66FA']
          }
        ]
      },
      options: {
        plugins: {
          tooltip: {
            displayColors: false,
            interaction: {
              mode: 'index'
            }
          },
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3
      }
    }
  },
  watch: {
    filters(filters) {
      this.loadDynamics(filters)
    }
  },
  created() {
    this.loadDynamics(this.filters)
  },
  methods: {
    async loadDynamics(filters) {
      // const { data } = await this.$axios.get(...getDynamicsData(filters))
      // console.log(data)
      const total = Math.floor(Math.random() * 90000)
      const data = {
        proceeds: {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              difference: 5
            }
          })
        },
        lost_proceeds: {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * -total),
              difference: -2
            }
          })
        }
      }

      this.chartData.labels = data.proceeds.rows.map(date => date.date_from)
      this.chartData.datasets.forEach(el => {
        el.rows = data[el.id].rows
        el.data = data[el.id].rows.map(row => row.value)
      })
    }
  }
}
</script>
