import { datesFormatting } from '../index.js'

const base = 'api/v1/'

export const getHeaderData = (code, sellerId, fbs, period) => {
  const params = new URLSearchParams()

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/competitors/${sellerId}/summary/`, { params }]
}

export const getRangeFiltersMaxValue = ({ code, sellerId, category, brand, period, search, favoritesOnly, fbs }) => {
  const params = new URLSearchParams()

  if (search) params.append('name', search)
  if (category.external_id) params.append('category_id', category.external_id)
  if (brand.brand_id) params.append('brand_id', brand.brand_id)

  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/competitors/${sellerId}/products/sliders/`, { params }]
}

export const getProducts = ({ code, sellerId, category, brand, period, search, favoritesOnly, fbs, reviews, evaluation, favorites, price, sales, remains, revenue, lostRevenue, sortOptions, page, limit }) => {
  const params = new URLSearchParams()

  if (category && category.external_id) params.append('category_id', category.external_id)
  if (brand.brand_id) params.append('brand_id', brand.brand_id)
  if (search) params.append('product_name', search)

  if (reviews && reviews.max) {
    params.append('reviews_cnt_from', Math.ceil(reviews.value[0]))
    params.append('reviews_cnt_to', Math.ceil(reviews.value[1]))
    params.append('reviews_max', Math.ceil(reviews.max))
  }

  if (evaluation && evaluation.max) {
    params.append('rating_from', Math.ceil(evaluation.value[0]))
    params.append('rating_to', Math.ceil(evaluation.value[1]))
    params.append('rating_max', Math.ceil(evaluation.max))
  }

  if (favorites && favorites.max) {
    params.append('in_favorites_cnt_from', Math.ceil(favorites.value[0]))
    params.append('in_favorites_cnt_to', Math.ceil(favorites.value[1]))
    params.append('in_favorites_max', Math.ceil(favorites.max))
  }

  if (price && price.max) {
    params.append('price_from', Math.ceil(price.value[0]))
    params.append('price_to', Math.ceil(price.value[1]))
    params.append('price_max', Math.ceil(price.max))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
    params.append('sales_max', Math.ceil(sales.max))
  }

  if (remains && remains.max) {
    params.append('quantity_cnt_from', Math.ceil(remains.value[0]))
    params.append('quantity_cnt_to', Math.ceil(remains.value[1]))
    params.append('quantity_max', Math.ceil(remains.max))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_to', Math.ceil(revenue.value[1]))
    params.append('proceeds_max', Math.ceil(revenue.max))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
    params.append('lost_proceeds_max', Math.ceil(lostRevenue.max))
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)
  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)
  params.append('page', page)
  params.append('limit', limit)

  return [`${base}${code}/competitors/${sellerId}/products/`, { params }]
}

export const getFile = ({ code, sellerId, category, brand, period, search, favoritesOnly, fbs, reviews, evaluation, favorites, price, sales, remains, revenue, lostRevenue, sortOptions }) => {
  const params = new URLSearchParams()

  if (category && category.external_id) params.append('category_id', category.external_id)
  if (brand.brand_id) params.append('brand_id', brand.brand_id)
  if (search) params.append('product_name', search)

  if (reviews && reviews.max) {
    params.append('reviews_cnt_from', Math.ceil(reviews.value[0]))
    params.append('reviews_cnt_to', Math.ceil(reviews.value[1]))
  }

  if (evaluation && evaluation.max) {
    params.append('rating_from', Math.ceil(evaluation.value[0]))
    params.append('rating_to', Math.ceil(evaluation.value[1]))
  }

  if (favorites && favorites.max) {
    params.append('in_favorites_cnt_from', Math.ceil(favorites.value[0]))
    params.append('in_favorites_cnt_to', Math.ceil(favorites.value[1]))
  }

  if (price && price.max) {
    params.append('price_from', Math.ceil(price.value[0]))
    params.append('price_to', Math.ceil(price.value[1]))
  }

  if (sales && sales.max) {
    params.append('sales_cnt_from', Math.ceil(sales.value[0]))
    params.append('sales_cnt_to', Math.ceil(sales.value[1]))
  }

  if (remains && remains.max) {
    params.append('quantity_cnt_from', Math.ceil(remains.value[0]))
    params.append('quantity_cnt_to', Math.ceil(remains.value[1]))
  }

  if (revenue && revenue.max) {
    params.append('proceeds_from', Math.ceil(revenue.value[0]))
    params.append('proceeds_to', Math.ceil(revenue.value[1]))
  }

  if (lostRevenue && lostRevenue.max) {
    params.append('lost_proceeds_from', Math.ceil(lostRevenue.value[0]))
    params.append('lost_proceeds_to', Math.ceil(lostRevenue.value[1]))
  }

  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))
  params.append('favorites_only', favoritesOnly)
  params.append('is_fbs', fbs)
  params.append('field', sortOptions.id)
  params.append('direction', sortOptions.direction)

  return `${base}${code}/competitors/${sellerId}/products/xlsx?${params.toString()}`
}

export const getDynamicsData = ({ code, sellerId, category, brand, period, fbs }) => {
  const params = new URLSearchParams()

  if (category.external_id) params.append('category_id', category.external_id)
  if (brand.brand_id) params.append('brand_id', brand.brand_id)

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return [`${base}${code}/competitors/${sellerId}/dynamics/`, { params }]
}

export const getDynamicsFile = ({ code, sellerId, category, brand, period, fbs }) => {
  const params = new URLSearchParams()

  if (category && category.external_id) params.append('category_id', category.external_id)
  if (brand.brand_id) params.append('brand_id', brand.brand_id)

  params.append('is_fbs', fbs)
  params.append('date_from', datesFormatting(period.start))
  params.append('date_to', datesFormatting(period.end))

  return `${base}${code}/competitors/${sellerId}/dynamics/xlsx?${params.toString()}`
}
