<template>
  <div class="brand__sellers">
    <template v-if="isVIP">
      <div class="grid__row" :class="{ disabled: !isPermitByTariff }">
        <div class="row__item">
          <x-label :size="'xs'">Категория</x-label>
          <div :class="{ disabled: preloader }" @click="$emit('categoriesModalOpen')">
            <category-button :value="filters.category" @input="clearCategory" />
          </div>
        </div>
        <div class="row__item">
          <x-label :size="'xs'">Показать</x-label>
          <date-select
            :preloader="preloader"
            :period-action="defaultPickerAction"
            :period="filters.period"
            @selectPeriod="onSelectPeriod"
          ></date-select>
          <!-- <div :class="{ disabled: preloader }" @click="$refs.datePicker.$modal.show('datepicker-modal')">
              <periods-picker :period="filters.period" :period-action="defaultPickerAction" />
            </div> -->
        </div>
        <div class="row__item">
          <x-label :size="'xs'">Поиск</x-label>
          <x-input v-model.trim="searchValue" prepend-icon="search" placeholder="По наименованию" />
        </div>
        <div class="row__item"></div>
        <div class="row__item">
          <div class="label__fake"></div>
          <fbs-switcher :value="filters.fbs" :disabled="preloader" @input="switchFbs" />
        </div>
        <div class="row__item">
          <div class="label__fake"></div>
          <filters-button
            :opened="isOpened"
            :changes="slidersChanged"
            @click.native="openFiltersToggle"
            @resetFilters="resetRangeFilters"
          />
        </div>
      </div>
      <div class="filters__range">
        <div ref="animateBlock" class="filters__range__animate" style="height: 0;">
          <div class="range__grid" :class="{ disabled: !isPermitByTariff }">
            <range-slider
              v-for="item in rangesFilters"
              :key="item.key"
              :label="item.label"
              :filter-key="item.key"
              :disabled="item.disabled || preloader"
              :max="item.max"
              :value="item.value"
              @rangefilterchanged="onRangeFiltersValue"
            />
            <div class="apply__button__row__item">
              <div class="apply__button__row">
                <div class="label__fake"></div>
                <x-btn class="apply__button" :disabled="slidersChangedForApply || preloader" @click="applyRangeFilters">
                  Применить
                </x-btn>
              </div>
            </div>
          </div>
          <x-loader v-if="rangePreloader" size="s" :overlay="true"></x-loader>
        </div>
      </div>
      <div v-if="isPermitByTariff" class="tracked__container">
        <div class="total">
          Всего: <span>{{ sellersData.total.toLocaleString('ru') }}</span>
        </div>
        <!-- <div class="tracked">
          <Switcher v-model="filters.favoritesOnly" :disabled="preloader || !sellersData.data.length" />
          <div class="tracked__label">Только отслеживаемые: 1</div>
        </div> -->
        <selected-period :period="filters.period"></selected-period>
        <!-- <div class="download__btn__container">
          <x-btn
            class="download__btn"
            color="gray"
            :disabled="preloader || !sellersData.data.length || downloadPreloader"
            @click="downloadXLSX"
          >
            <x-icon class="download__icon" name="download" size="s"></x-icon>
            Скачать в XLSX
            <x-loader v-if="downloadPreloader" size="xs"></x-loader>
          </x-btn>
          <tooltip class="arrow__offset">Максимальный размер скачивания 1 000 строк.</tooltip>
        </div> -->
      </div>
      <!-- <date-picker-modal
        ref="datePicker"
        :value="filters.period"
        :default-period="defaultPickerAction"
        @change="onSelectPeriod"
      /> -->
      <!-- :prev-page-name="headerData.brand_name" -->
      <sellers-list
        :sellers-data="sellersData"
        :preloader="preloader"
        :code="filters.code"
        :period="filters.period"
        :fbs="filters.fbs"
        :category="filters.category"
        :default-picker-action="defaultPickerAction"
        :sort-options="sortOptions"
        @sort="sortList"
      />
      <div class="row__container">
        <x-paginator v-model="paginationOptions" :class="{ disabled: preloader }" size="xs" :max-page-count="5" />
      </div>
    </template>
    <div v-else class="plug">
      <x-placeholder title="Доступно для VIP пользователей" icon="table-vip">
        <template #desc>
          Для получения доступа пишите <br />на <x-link href="mailto:support@xway.ru" primary>support@xway.ru</x-link>
        </template>
        <template #bottom>
          <x-btn>Подключить VIP опции</x-btn>
        </template>
      </x-placeholder>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
.brand__sellers
  display flex
  flex-direction column
  flex-grow 1

.grid__row
  display grid
  grid-template-columns repeat(2, calc(25% - 9px)) 1fr auto auto
  grid-auto-rows auto
  grid-gap 12px
  margin-bottom 12px

.row__item
  &:nth-child(4)
    display none

@media (max-width 1280px)
  .grid__row
    grid-template-columns repeat(3, minmax(254px, 1fr))

  .row__item:last-of-type
    grid-column span 2
    display flex
    flex-direction column
    align-items flex-end

@media (max-width 1470px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .row__item:last-of-type
    grid-column span 2
    display flex
    flex-direction column
    align-items flex-end

@media (max-width 1050px)
  .x-content--collapsed .grid__row
    grid-template-columns repeat(2, 1fr)

  .x-content--collapsed  .row__item
    &:nth-child(4)
      display block

  .x-content--collapsed  .row__item:last-of-type
    grid-column span 1

@media (max-width 900px)
  .x-content--collapsed .grid__row
    grid-template-columns 1fr

  .x-content--collapsed  .row__item
    &:nth-child(4)
      display none

  .x-content--collapsed  .row__item:last-of-type
    align-items start
    grid-column span 1

@media (max-width 992px)
  .grid__row
    grid-template-columns repeat(2, 1fr)

  .row__item
    &:nth-child(4)
      display block

  .row__item:last-of-type
    grid-column span 1

@media (max-width 768px)
  .grid__row
    grid-template-columns 1fr

  .row__item:nth-child(4)
    display none

  .row__item:last-of-type
    align-items: start

.label__fake
  margin-bottom 7px
  height 14px

.filters__range
  border-bottom 1px solid $colors.gray-light

.filters__range__animate
  position relative
  overflow hidden
  transition .3s

.range__grid
  display grid
  grid-template-columns repeat(4, 1fr)
  grid-gap 12px
  padding 0 2px 12px

.apply__button__row__item
  grid-column span 3
  display flex

@media (max-width 1470px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(3, 1fr)

  .x-content--collapsed .apply__button__row__item
    grid-column span 1

@media (max-width 1200px)
  .x-content--collapsed .range__grid
    grid-template-columns repeat(2, 1fr)

@media (max-width 992px)
  .x-content--collapsed .range__grid
    grid-template-columns 1fr

@media (max-width 1280px)
  .range__grid
    grid-template-columns repeat(3, 1fr)

  .apply__button__row__item
    grid-column span 1

@media (max-width 992px)
  .range__grid
    grid-template-columns repeat(2, 1fr)

@media (max-width 768px)
  .range__grid
    grid-template-columns 1fr

  .apply__button__row__item
    grid-column span 1

.apply__button__row
  margin-left auto

.apply__button
  width 120px

.tracked__container
  display flex
  align-items center
  height 36px
  margin 12px 0

.total
  border-right 1px solid #ededed
  padding-right 12px
  height 100%
  font-size $font-size-s
  color $colors.gray-darkest-2
  line-height 36px
  & span
    font-weight bold

.tracked
  display flex
  align-items center
  padding-left 16px

.tracked__label
  color $colors.gray-darkest-1
  font-size $font-size-s
  margin-left 4px

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn__container
  position relative
  margin-left auto
  &:hover
    .tooltip
      visibility visible
      opacity 1

.download__btn__container .tooltip
  left -7px
  bottom 46px
  white-space nowrap

.download__btn
  position relative
  &:disabled .download__icon
    color inherit

.x-btn:disabled + .tooltip
  visibility hidden
  opacity 0

.download__btn .x-loader
  transform translateX(6px)

.row__container
  display flex
  align-items center

.plug
  min-height 300px

.disabled
  pointer-events none
  opacity .5
</style>

<script>
import filtersToggle from '@/mixins/filtersToggle.js'
import permissions from '@/mixins/permissions.js'
import SellersList from '@/components/SellersList.vue'
import { getSellers, getSellersRangeFiltersMaxValue } from '@/api/sellers/index.js'
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('ui')

export default {
  mixins: [filtersToggle, permissions],
  components: {
    SellersList
  },
  props: {
    filters: {
      type: Object
    },
    defaultPickerAction: {
      type: String
    },
    headerData: {
      type: Object
    }
  },
  data() {
    return {
      preloader: false,
      rangePreloader: false,
      downloadPreloader: false,
      searchTimeout: null,
      searchValue: this.$route.query.name || '',
      sellersData: {
        data: [],
        total: 0
      },
      search: '',
      defaultRangeFilters: {},
      rangeFiltersPrevVal: {},
      rangesFilters: {
        products: {
          label: 'Товары',
          key: 'products',
          value: [+this.$route.query.products_cnt_from || 0, +this.$route.query.products_cnt_to || 0],
          max: +this.$route.query.products_max || 0,
          disabled: !+this.$route.query.products_max
        },
        // brands: {
        //   label: 'Бренды',
        //   key: 'brands',
        //   value: [+this.$route.query.brands_cnt_from || 1, +this.$route.query.brands_cnt_to || 1],
        //   max: +this.$route.query.brands_max || 1,
        //   disabled: !+this.$route.query.brands_max
        // },
        cheque: {
          label: 'Средний чек, руб',
          key: 'cheque',
          value: [+this.$route.query.avg_check_from || 0, +this.$route.query.avg_check_to || 0],
          max: +this.$route.query.cheque_max || 0,
          disabled: !+this.$route.query.cheque_max
        },
        sales: {
          label: 'Кол-во продаж, шт',
          key: 'sales',
          value: [+this.$route.query.sales_cnt_from || 0, +this.$route.query.sales_cnt_to || 0],
          max: +this.$route.query.sales_max || 0,
          disabled: !+this.$route.query.sales_max
        },
        remains: {
          label: 'Остатки, шт',
          key: 'remains',
          value: [+this.$route.query.quantity_cnt_from || 0, +this.$route.query.quantity_cnt_to || 0],
          max: +this.$route.query.remains_max || 0,
          disabled: !+this.$route.query.remains_max
        },
        revenue: {
          label: 'Выручка, руб',
          key: 'revenue',
          value: [+this.$route.query.proceeds_cnt_from || 0, +this.$route.query.proceeds_cnt_to || 0],
          max: +this.$route.query.revenue_max || 0,
          disabled: !+this.$route.query.revenue_max
        }
        // lostRevenue: {
        //   label: 'Упущенная выручка, руб',
        //   key: 'lostRevenue',
        //   value: [0, 0],
        //   max: 0,
        //   disabled: true
        // }
      },
      sortOptions: {
        id: this.$route.query.field || 'proceeds',
        direction: this.$route.query.direction || '1'
      },
      paginationOptions: {
        limit: +this.$route.query.limit || 10,
        offset: 0,
        total: 0,
        page: +this.$route.query.page || 1
      },
      favoritesOnly: this.$route.query.favorites_only === 'true'
    }
  },
  watch: {
    filters(filters) {
      this.paginationOptions.page = 1
      this.loadSliders({ ...filters, favoritesOnly: this.favoritesOnly, search: this.searchValue })
    },
    searchValue(val) {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        if (val.length > 2 || !val.length) {
          this.paginationOptions.page = 1
          this.loadSliders({ ...this.filters, favoritesOnly: this.favoritesOnly, search: val })
        }
      }, 700)
    },
    paginationOptions() {
      this.setUrlParams()
      this.loadSellers({
        ...this.filters,
        search: this.searchValue,
        favoritesOnly: this.favoritesOnly,
        sortOptions: this.sortOptions,
        ...this.rangeFiltersPrevVal,
        ...this.paginationOptions
      })
    }
  },
  computed: {
    ...mapState(['isVIP']),
    slidersChanged() {
      return JSON.stringify(this.rangesFilters) !== JSON.stringify(this.defaultRangeFilters)
    },
    slidersChangedForApply() {
      return JSON.stringify(this.rangeFiltersPrevVal) === JSON.stringify(this.rangesFilters)
    }
  },
  created() {
    this.saveDefaultRangeFilters()
    if (this.isPermitByTariff) {
      this.saveRangeFiltersValues()

      if (!this.rangesFilters.products.max) {
        this.loadSliders({ ...this.filters, favoritesOnly: this.favoritesOnly, search: this.searchValue })
      } else {
        this.rangePreloader = false
        this.loadSellers({
          ...this.filters,
          search: this.searchValue,
          favoritesOnly: this.favoritesOnly,
          sortOptions: this.sortOptions,
          ...this.rangesFilters,
          ...this.paginationOptions
        })
      }
    }
  },
  activated() {
    this.setUrlParams()
  },
  methods: {
    clearCategory(category) {
      this.$emit('clearCategory', category)
    },
    onSelectPeriod(val) {
      this.$emit('periodChanged', val)
    },
    switchFbs(fbs) {
      this.$emit('switchFbs', fbs)
    },
    sortList(titleId) {
      if (this.sortOptions.id === titleId) {
        this.sortOptions.direction = this.sortOptions.direction === '1' ? '0' : '1'
      } else {
        this.sortOptions.direction = '1'
      }
      this.sortOptions.id = titleId
      this.setUrlParams()
      this.loadSellers({
        ...this.filters,
        search: this.searchValue,
        favoritesOnly: this.favoritesOnly,
        sortOptions: this.sortOptions,
        ...this.rangesFilters,
        ...this.paginationOptions
      })
    },
    saveDefaultRangeFilters() {
      this.defaultRangeFilters = {
        ...this.rangesFilters,
        ...JSON.parse(
          JSON.stringify({
            products: {
              label: 'Товары',
              key: 'products',
              value: [0, this.rangesFilters.products.max],
              max: this.rangesFilters.products.max,
              disabled: this.rangesFilters.products.max === 0
            },
            // brands: {
            //   label: 'Бренды',
            //   key: 'brands',
            //   value: [1, this.rangesFilters.brands.max],
            //   max: this.rangesFilters.brands.max,
            //   disabled: this.rangesFilters.brands.max === 1
            // },
            cheque: {
              label: 'Средний чек, руб',
              key: 'cheque',
              value: [0, this.rangesFilters.cheque.max],
              max: this.rangesFilters.cheque.max,
              disabled: this.rangesFilters.cheque.max === 0
            },
            sales: {
              label: 'Кол-во продаж, шт',
              key: 'sales',
              value: [0, this.rangesFilters.sales.max],
              max: this.rangesFilters.sales.max,
              disabled: this.rangesFilters.sales.max === 0
            },
            remains: {
              label: 'Остатки, шт',
              key: 'remains',
              value: [0, this.rangesFilters.remains.max],
              max: this.rangesFilters.remains.max,
              disabled: this.rangesFilters.remains.max === 0
            },
            revenue: {
              label: 'Выручка, руб',
              key: 'revenue',
              value: [0, this.rangesFilters.revenue.max],
              max: this.rangesFilters.revenue.max,
              disabled: this.rangesFilters.revenue.max === 0
            }
          })
        )
      }
    },
    resetRangeFilters() {
      this.rangesFilters = JSON.parse(JSON.stringify(this.defaultRangeFilters))
      this.paginationOptions.page = 1
    },
    onRangeFiltersValue({ val, key }) {
      this.rangesFilters[key].value = val
    },
    saveRangeFiltersValues() {
      this.rangeFiltersPrevVal = JSON.parse(JSON.stringify(this.rangesFilters))
    },
    applyRangeFilters() {
      this.saveRangeFiltersValues()
      this.paginationOptions.page = 1
      this.setUrlParams()
      this.loadSellers({
        ...this.filters,
        search: this.searchValue,
        favoritesOnly: this.favoritesOnly,
        sortOptions: this.sortOptions,
        ...this.rangesFilters,
        ...this.paginationOptions
      })
    },
    async loadSliders(filters) {
      this.$emit('loadingStarted')
      this.preloader = true
      this.rangePreloader = true

      try {
        const { data } = await this.$axios.get(...getSellersRangeFiltersMaxValue(filters))
        const res = data.data

        if (res) {
          for (const k in res) res[k] = Math.ceil(res[k])
        }

        this.rangesFilters.products.value = [0, res.products_max]
        this.rangesFilters.products.disabled = res.products_max === 0

        // this.rangesFilters.brands.value = [0, res.brands_max]
        // this.rangesFilters.brands.disabled = res.brands_max === 0

        this.rangesFilters.cheque.value = [0, res.avg_check_max]
        this.rangesFilters.cheque.disabled = res.avg_check_max === 0

        this.rangesFilters.sales.value = [0, res.sales_max]
        this.rangesFilters.sales.disabled = res.sales_max === 0

        this.rangesFilters.remains.value = [0, res.quantity_max]
        this.rangesFilters.remains.disabled = res.quantity_max === 0

        this.rangesFilters.revenue.value = [0, res.proceeds_max]
        this.rangesFilters.revenue.disabled = res.proceeds_max === 0

        // this.rangesFilters.lostRevenue.value = [0, res.lost_proceeds_max]
        // this.rangesFilters.lostRevenue.disabled = true

        setTimeout(() => {
          this.rangesFilters.products.max = res.products_max
          // this.rangesFilters.brands.max = res.brands_max
          this.rangesFilters.cheque.max = res.avg_check_max
          this.rangesFilters.sales.max = res.sales_max
          this.rangesFilters.remains.max = res.quantity_max
          this.rangesFilters.revenue.max = res.proceeds_max
          // this.rangesFilters.lostRevenue.max = Math.ceil(res.lost_proceeds_max)
          this.saveDefaultRangeFilters()
          this.saveRangeFiltersValues()
          this.loadSellers({
            ...this.filters,
            favoritesOnly: this.favoritesOnly,
            search: this.searchValue,
            ...this.rangesFilters,
            sortOptions: this.sortOptions,
            ...this.paginationOptions
          })
          this.setUrlParams()
        })
      } catch (err) {
        this.preloader = false
        console.log(err)
      } finally {
        this.rangePreloader = false
        this.$emit('loadingFinished')
      }
    },
    async loadSellers(filters) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getSellers(filters))

        this.sellersData.data = data.data || []
        this.sellersData.total = data.paging?.total || 0

        this.paginationOptions.total = data.paging?.total
      } catch (err) {
        console.log(err)
      } finally {
        // this.loadSellersFavoritesList(this.filters.code)
        this.preloader = false
      }
    },
    downloadXLSX() {},
    setUrlParams() {
      if (this.$route.name === 'brand_page') {
        const params = new URLSearchParams()

        if (this.filters.category.name) {
          params.append('category_name', this.filters.category.name)
        }

        if (this.$route.query.crumbs_text) params.append('crumbs_text', this.$route.query.crumbs_text)

        params.append('state', this.$route.query.state)

        this.$router
          .replace(
            `?${params.toString()}&default_picker_action=${this.defaultPickerAction}&${getSellers({
              ...this.filters,
              search: this.searchValue,
              favoritesOnly: this.favoritesOnly,
              ...this.rangesFilters,
              sortOptions: this.sortOptions,
              ...this.paginationOptions
            })[1].params.toString()}`
          )
          .catch(() => {})
      }
    }
  }
}
</script>
