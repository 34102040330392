export const marketPlaceValues = Object.freeze({
  wb: {
    title: 'Wildberries',
    icon: 'wildberries'
  },
  oz: {
    title: 'Ozon',
    icon: 'ozon'
  },
  ae: {
    title: 'AliExpress',
    icon: 'aliexpress'
  },
  ym: {
    title: 'Яндекс.Маркет',
    icon: 'yandex-market'
  },
  smm: {
    title: 'Сбер.Мега.Маркет',
    icon: 'sber-mega-market'
  }
})
