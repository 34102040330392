<template>
  <div class="statistics">
    <div class="period__container">
      <selected-period :period="filters.period"></selected-period>
    </div>
    <div class="statistics__row">
      <Chart :chart-data="chartDataFiltered" :options="options" />
      <div class="categories">
        <div class="categories__list">
          <div v-for="item in chartData.datasets" :key="item.id" class="categories__item" @click="selectCategory(item)">
            <div
              class="categories__checkbox"
              :class="{ categories__checkbox_active: item.selected }"
              :style="{ backgroundColor: item.selected ? item.backgroundColor[0] : '' }"
            ></div>
            <div class="categories__label">
              {{ item.category }} {{ item.category ? ':' : '' }} <span>{{ item.label }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="title__row">
      <h3 class="statistics__title">Анализ позиций</h3>
      <x-btn class="download__btn" color="gray">
        <x-icon class="download__icon" name="download" size="s"></x-icon>
        Скачать в XLSX
      </x-btn>
    </div>
    <positions-sales-dynamics-list
      :period-list="chartData.labels"
      :datasets="chartDataFiltered.datasets"
    ></positions-sales-dynamics-list>
  </div>
</template>

<style lang="stylus" scoped>
.period__container
  margin-left -12px
  margin-bottom 5px

.sales__scale
  margin-top 20px
  padding 0 12px 0 8px
  border-right 1px solid $colors.gray-light

.sales__scale__value
  position relative
  font-size 10px
  line-height 13px
  margin-bottom 9.4px
  text-align right
  font-weight bold
  &:after
    content: ''
    position absolute
    top: 50%;
    right: -12px;
    transform: translateY(-50%);
    width 8px
    height 1px
    background $colors.gray-light

.title__row
  display flex
  align-items center
  margin 20px 0 12px

.statistics__row
  display flex
  align-items center

.chart
  width calc(100% - 254px)
  display flex

.categories
  height 263px
  border-top 1px solid #CACAD3
  border-bottom: 1px solid #CACAD3
  overflow-y auto
  transform translateY(-9px)
  max-width 254px
  flex-shrink: 0
  &::-webkit-scrollbar
    width 8px
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    box-shadow 0px 2px 4px rgba(0, 0, 0, 0.05)
    border-radius 4px

.categories__list
  padding 5px 10px 5px 0
  display flex
  flex-direction column

.categories__checkbox
  position relative
  width 13px
  height 13px
  margin-right 8px
  background #fff
  border 1px solid $colors.gray
  border-radius 2px
  flex-shrink 0
  transition .3s
  transform translateY(3px)
  &:after
    content ''
    position absolute
    display none
    top 2px
    left 1px
    transform rotate(-45deg)
    width 9px
    height 5px
    border-left 2px solid #fff
    border-bottom 2px solid #fff

.categories__checkbox_active
  border-color transparent
  &:after
    display block

.categories__label
  font-size $font-size-s
  font-weight $font-weights.semibold
  color: $colors.gray-darkest-2
  display -webkit-box
  -webkit-line-clamp 3
  overflow hidden
  -webkit-box-orient vertical

.categories__label span
  font-weight normal

.categories__item
  display inline-flex
  cursor pointer
  margin-bottom 8px
  align-self baseline

.statistics__title
  color rgba(44,44,55,0.898)
  font-size $font-size-l

.download__icon
  margin-right 4px
  color $colors.gray-darker

.download__btn
  margin-left auto
  &:disabled .download__icon
    color inherit
    cursor inherit

@media (max-width 1400px)
  .statistics__row
    flex-direction column-reverse

  .chart
    width 100%

  .categories
    max-width 100%
    margin 20px 0
    height auto
    padding 10px 0
    width 100%

  .categories__list
    display grid
    grid-template-columns repeat(auto-fill, minmax(200px, 1fr))
    grid-gap 20px

  .categories__label
    display block
    white-space nowrap
    text-overflow ellipsis
    overflow hidden
</style>

<script>
import Chart from '@/ui/chart/Chart'
import PositionsSalesDynamicsList from '@/components/PositionsSalesDynamicsList.vue'
import calcPeriod from '@/mixins/calcPeriod.js'
// import { getDynamicsData } from '@/api/sellerPage/index.js'

export default {
  mixins: [calcPeriod],
  components: {
    Chart,
    PositionsSalesDynamicsList
  },
  props: {
    filters: {
      type: Object
    }
  },
  data() {
    return {
      chartData: {
        type: 'line',
        labels: [],
        datasets: [
          {
            id: '1',
            label: 'Здоровье / Витамины и БАДыЗдоровье',
            category: 'Категория',
            selected: true,
            data: [],
            backgroundColor: ['#3CC13B'],
            borderColor: ['#3CC13B'],
            borderWidth: 2
          },
          {
            id: '2',
            label: 'Витамины и Бады',
            category: 'Категория',
            selected: true,
            data: [],
            backgroundColor: ['#904BE8'],
            borderColor: ['#904BE8'],
            borderWidth: 2
          },
          {
            id: '3',
            label: 'Омега 3',
            category: 'Поиск',
            selected: true,
            data: [],
            backgroundColor: ['#FD8E39'],
            borderColor: ['#FD8E39'],
            borderWidth: 2
          },
          {
            id: '4',
            label: 'Бад',
            category: 'Поиск',
            selected: true,
            data: [],
            backgroundColor: ['#5B40FF'],
            borderColor: ['#5B40FF'],
            borderWidth: 2
          },
          {
            id: '5',
            label: 'Бады',
            category: 'Поиск',
            selected: true,
            data: [],
            backgroundColor: ['#43B2D4'],
            borderColor: ['#43B2D4'],
            borderWidth: 2
          },
          {
            id: '6',
            label: 'Для иммунитета',
            category: 'Поиск',
            selected: false,
            data: [],
            backgroundColor: ['#ED5051'],
            borderColor: ['#ED5051'],
            borderWidth: 2
          },
          {
            id: '7',
            label: 'Витамины',
            category: 'Поиск',
            selected: false,
            data: [],
            backgroundColor: ['#1fe765'],
            borderColor: ['#1fe765'],
            borderWidth: 2
          },
          {
            id: '8',
            label: 'Цена',
            category: '',
            selected: false,
            data: [],
            backgroundColor: ['#e71fc5'],
            borderColor: ['#e71fc5'],
            borderWidth: 2
          },
          {
            id: '9',
            label: 'Остатки',
            category: '',
            selected: false,
            data: [],
            backgroundColor: ['#ff2f00'],
            borderColor: ['#ff2f00'],
            borderWidth: 2
          },
          {
            id: '10',
            label: 'Оценка',
            category: '',
            selected: false,
            data: [],
            backgroundColor: ['#ffd400'],
            borderColor: ['#ffd400'],
            borderWidth: 2
          },
          {
            id: '11',
            label: 'Количество отзывов',
            category: '',
            selected: false,
            data: [],
            backgroundColor: ['#0023a2'],
            borderColor: ['#0023a2'],
            borderWidth: 2
          }
        ]
      },
      options: {
        plugins: {
          tooltip: {
            // bodyFont: {
            //   size: 15
            // },
            boxPadding: 5,
            interaction: {
              mode: 'index'
            }
          },
          legend: {
            display: false
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        lineTension: 0.3
      }
    }
  },
  watch: {
    filters(filters) {
      this.loadDynamics(filters)
    }
  },
  computed: {
    chartDataFiltered() {
      return { ...this.chartData, datasets: this.chartData.datasets.filter(el => el.selected) }
    }
  },
  created() {
    this.loadDynamics(this.filters)
  },
  methods: {
    selectCategory(item) {
      item.selected = !item.selected
    },
    async loadDynamics(filters) {
      // const { data } = await this.$axios.get(...getDynamicsData(filters))
      // console.log(data)
      const total = Math.floor(Math.random() * 90000)
      const data = {
        '1': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '2': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '3': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '4': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '5': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '6': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '7': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '8': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '9': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '10': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        },
        '11': {
          units: 'str',
          color: 'str',
          rows: this.calcPeriod.map(el => {
            return {
              date_from: el,
              date_to: el,
              value: Math.floor(Math.random() * total),
              delta: 5,
              sub_value: 160000,
              difference: -140
            }
          })
        }
      }

      this.chartData.labels = data[1].rows.map(date => date.date_from)
      this.chartData.datasets.forEach(el => {
        if (el.label.length > 40) {
          el.label =
            el.label
              .split('')
              .splice(0, 40)
              .join('') + '...'
        }

        el.rows = data[el.id].rows
        el.data = data[el.id].rows.map(row => row.value)
      })
    }
  }
}
</script>
