<template>
  <div class="fbs__switcher">
    <Switcher :value="value" :disabled="disabled" @input="$emit('input', !value)" />
    <div class="fbs__switcher__label">FBS</div>
    <div class="fbs__switcher__icon" @mouseenter="showTooltip" @mouseleave="hideTooltip">
      <x-icon name="info" size="xs"></x-icon>
      <tooltip ref="tooltip">
        <div v-if="value" class="fbs__switcher__text">
          Данные по FBS включены.<br />В отчете отображаются и продажи со складов маркетплейсов (FBO), <br />
          и продажи со складов поставщиков (FBS) вместе.<br />Данные по FBS могут быть неточными, <br />
          так как остатки поставщик регулирует самостоятельно.
        </div>
        <div v-else class="fbs__switcher__text">
          Данные по FBS отключены.<br />В отчете отображаются только продажи со складов маркетплейсов (FBO).<br />Продажи со
          складов поставщиков (FBS) исключены.
        </div>
      </tooltip>
    </div>
  </div>
</template>

<style lang="stylus">
.fbs__switcher
  position relative
  display flex
  align-items center
  padding-left 12px
  border-left 1px solid $colors.gray-light
  height 36px
  color: $colors.gray

  &__label
    color: $colors.gray-darkest-2
    font-size $font-size-s
    font-weight $font-weights.normal
    margin 0 4px

  &__icon
    position relative
    height 16px
    & .tooltip
      bottom 28px
      z-index 1

.fbs__switcher__text
  white-space nowrap

@media (max-width 768px)
  .fbs__switcher__text
    white-space inherit

  .fbs__switcher__icon
    position static

  .fbs__switcher .tooltip
    width calc(100% + 20px)
    bottom 38px

  .fbs__switcher .tooltip .arrow
    left 90px

@media (max-width 900px)
  .x-content--collapsed .fbs__switcher__text
    white-space inherit

  .x-content--collapsed .fbs__switcher__icon
    position static

  .x-content--collapsed .fbs__switcher .tooltip
    width calc(100% + 20px)
    bottom 38px

  .x-content--collapsed .fbs__switcher .tooltip .arrow
    left 90px
</style>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    showTooltip() {
      const tooltip = this.$refs.tooltip.$el
      const tooltipLeftPos = tooltip.getBoundingClientRect().left
      const pageLeftPos = document.querySelector('.x-card').getBoundingClientRect().left
      const tooltipRightPos = tooltip.getBoundingClientRect().right
      const pageRightPos = document.querySelector('.x-card').getBoundingClientRect().right

      if (this.$el.classList.contains('tooltip__slightly__right')) {
        if (tooltipLeftPos < pageLeftPos) tooltip.classList.add('tooltip__not__match__right')
      } else {
        if (tooltipLeftPos < pageLeftPos) tooltip.classList.add('tooltip__adaptive_left')
      }

      if (tooltipRightPos > pageRightPos) tooltip.classList.add('fbs__tooltip__adaptive_right')

      tooltip.classList.add('tooltip_active')
    },
    hideTooltip() {
      const tooltip = this.$refs.tooltip.$el
      tooltip.classList.remove('tooltip__not__match__right')
      tooltip.classList.remove('tooltip__adaptive_left')
      tooltip.classList.remove('fbs__tooltip__adaptive_right')
      this.$refs.tooltip.$el.classList.remove('tooltip_active')
    }
  }
}
</script>
