<template>
  <div class="categories__popup" @click="closeModal">
    <div class="popup__content" @click.stop>
      <button class="close__popup" @click="closeModal">
        <x-icon name="x" size="xs"></x-icon>
      </button>
      <h3 class="popup__title">Категория</h3>
      <x-input v-model.trim="searchValue" class="popup__search" prepend-icon="search" placeholder="Поиск"></x-input>
      <div class="list__container">
        <div v-if="!preloader && categories.length" ref="listContainer" class="popup__content__container">
          <x-tree
            :selected="selected"
            :key-id="keyId"
            :key-name="keyName"
            :search-value="isSearchVal"
            :key-has-children="'has_children'"
            :code="code"
            :list="categories"
            :padding="8"
            :get-list="getList"
          />
        </div>
        <x-loader v-if="preloader" size="s"></x-loader>
      </div>
      <div class="popup__buttons">
        <x-btn color="gray" @click="closeModal">Отменить</x-btn>
        <x-btn :disabled="!selected.external_id || value.external_id === selected.external_id" @click="selectCategory">
          Выбрать
        </x-btn>
      </div>
      <div v-if="!preloader && !categories.length" class="popup__desc">Нет данных</div>
    </div>
  </div>
</template>

<style lang="stylus" scoped>
ul {
  list-style none
  padding 0
}

.categories__popup
  position fixed
  top 0
  left 0
  right 0
  bottom 0
  display flex
  align-items center
  justify-content center
  padding 10px
  z-index 110
  background rgba(0,0,0,.2)

.no__fixed
  position static

.popup__content
  position relative
  width 100%
  height 100%
  display flex
  flex-direction column
  max-width 541px
  max-height 620px
  background #fff
  box-shadow 0px 6px 16px rgba(5, 2, 55, 0.14)
  border-radius 12px
  padding 24px 32px 43px

.popup__title
  color $colors.gray-darkest-2
  line-height 34px
  font-size $font-sizes.headline-4
  margin-bottom 20px

.popup__search
  margin-bottom 12px

.close__popup
  display flex
  align-items center
  justify-content center
  background-color transparent
  outline none
  color: $colors.gray-dark
  border none
  border-radius 4px
  width 32px
  height 32px
  position absolute
  top 3px
  right 3px
  transition background-color .2s, border-color .2s, opacity 0.2s
  cursor pointer
  &:hover
    background-color $colors.gray-lighter
  &:focus
    background-color $colors.gray-lighter
  &:active
    background-color $colors.gray-light

.list__container
  position relative
  height calc(100% - 160px)

.popup__content__container
  position relative
  overflow-y auto
  height 100%
  // scroll-behavior smooth
  &::-webkit-scrollbar
    width 16px
    background #fff
  &::-webkit-scrollbar-thumb
    background $colors.gray-dark
    border 4px solid #fff
    border-radius 14px

.popup__buttons
  display flex
  align-items center
  justify-content space-between
  margin-top auto

.popup__buttons .x-btn
  flex-grow 1

.x-loader
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
  z-index 10

.popup__desc
  position absolute
  top 50%
  left 50%
  transform translate(-50%, -50%)
</style>

<script>
import XTree from './XTree.vue'
import getCategories from '@/api/marketplacesCategories/index.js'

export default {
  components: {
    XTree
  },
  props: {
    code: {
      type: String,
      default: 'wb'
    },
    keyId: {
      type: String,
      require: true
    },
    keyName: {
      type: String,
      require: true
    },
    value: {
      type: Object
    }
  },
  data() {
    return {
      searchValue: '',
      isSearchVal: '',
      searchTimeout: null,
      selected: {
        [this.keyId]: '',
        [this.keyName]: ''
      },
      categories: [],
      preloader: false
    }
  },
  watch: {
    code(code) {
      this.loadCategories(code, this.searchValue)
    },
    searchValue(val) {
      clearTimeout(this.searchTimeout)

      this.searchTimeout = setTimeout(() => {
        this.selected = { [this.keyId]: '', [this.keyName]: '' }
        this.loadCategories(this.code, val)
      }, 700)
    }
  },
  created() {
    this.stopScrollContent()
    this.loadCategories(this.code)
  },
  activated() {
    this.scrollToSelected()
    this.stopScrollContent()
    this.selected = { ...this.value }
  },
  methods: {
    selectCategory() {
      this.$emit('input', { ...this.selected })
      this.closeModal()
    },
    closeModal() {
      // this.searchValue = ''
      document.body.style.overflow = 'visible'
      this.$emit('close', false)
    },
    async getList(code, categoryId) {
      return await this.$axios.get(...getCategories(code, categoryId, null))
    },
    async loadCategories(code, search) {
      this.preloader = true
      try {
        const { data } = await this.$axios.get(...getCategories(code, null, search))
        this.categories = data.data || []
      } catch (err) {
        console.log(err)
      } finally {
        this.isSearchVal = this.searchValue
        this.preloader = false
      }
    },
    stopScrollContent() {
      document.body.style.overflow = 'hidden'
    },
    scrollToSelected() {
      setTimeout(() => {
        const container = this.$refs.listContainer
        const selectedElement = container && container.querySelector('.x-category__item__active')
        if (selectedElement) {
          container.scrollTop = selectedElement.offsetTop - selectedElement.offsetHeight - 1
        }
      }, 10)
    }
  }
}
</script>
