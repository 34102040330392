<template>
  <div class="x-category__list" :class="{ 'x-main__list': !child }">
    <div
      v-for="(item, index) in list"
      :key="index"
      class="x-category__item__container"
      :class="{ 'x-main__category__item__container': !child }"
    >
      <x-btn
        :style="{ padding: `0 10px 0 ${padding}px` }"
        class="x-category__item"
        :class="{ 'x-category__item__active': item[keyId] === selected[keyId] }"
        ghost
        color="gray"
        append="check"
        squared
        @click="getCategory(item, index)"
      >
        <div v-if="item[keyHasChildren]" class="x-category__icon" :class="{ opened: item.opened }">
          <div class="x-svg__wrapper">
            <x-icon name="expand-arrow" size="xxs" :class="{ hide__arrow: item.preloader }"></x-icon>
          </div>
          <x-loader v-if="item.preloader" size="xs"></x-loader>
        </div>
        <div class="x-category__name x-category__name__path">
          {{ item[keyName] }} {{ searchValue && item.full_path ? '(' : ''
          }}<span v-if="searchValue" class="x-category__path" :title="item.full_path">{{ item.full_path }}</span
          >{{ searchValue && item.full_path ? ')' : '' }}&nbsp;&nbsp;
        </div>
      </x-btn>
      <x-tree
        :class="{ hidden: !item.opened }"
        :selected="selected"
        :key-id="keyId"
        :key-name="keyName"
        :key-has-children="keyHasChildren"
        :code="code"
        :list="item.sublist"
        :padding="padding + 23"
        :get-list="getList"
        child
      />
    </div>
  </div>
</template>

<style lang="stylus">

.x-category__list
  overflow hidden

.x-category__list .x-btn--pressed-state:active:not(:disabled)
  transform scale(1) !important

.x-category__list .x-btn__content
  padding 0
  justify-content flex-start
  background transparent !important
  &:hover
    background transparent !important
  // &:active
  //   transform scale(1) !important
  &:focus .x-svg__wrapper
    background transparent

.x-main__list
  border 1px solid $colors.gray
  border-radius 4px

.x-category__item .x-btn__append
  width 18px
  height 18px
  flex-shrink 0
  margin-left auto
  display none
  color: $colors.primary !important

.x-category__item
  position relative
  display flex
  justify-content flex-start !important
  width 100%
  background-color #fff !important
  border-bottom 1px solid #CACAD3 !important
  &:hover, &:focus
    background-color: $colors.gray-lightest-2 !important
  &:hover .x-svg__wrapper
    color: $colors.primary !important
  &:focus .x-svg__wrapper
    color: $colors.primary !important
  &:active .x-svg__wrapper
    color: $colors.primary-dark !important

// .x-main__category__item__container:last-of-type .x-category__item
//   border none !important

.x-category__item .x-loader--size-xs
  position absolute
  top 50%
  left -2px
  transform translateY(-50%)

.x-category__icon
  position relative
  margin-right 4px
  flex-shrink 0
  transition .2s

.hide__arrow
  visibility hidden

.opened
  transform rotate(90deg)

.x-svg__wrapper
  display flex
  align-items center
  justify-content center
  color: $colors.gray-dark
  .x-icon--xxs
    width 8px
    height 8px

.x-category__name
  color $colors.gray-darkest-2
  font-size $font-size-s
  font-weight $font-weights.normal
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.x-category__name__path
  display flex
  align-items center

.x-category__path
  display block
  white-space nowrap
  text-overflow ellipsis
  overflow hidden

.x-category__item__active .x-category__name
  font-weight: $font-weights.semibold !important

.x-category__item__active .x-btn__append
  display block !important

.x-category__list .x-category__item__active
  background-color: $colors.primary-lightest-2 !important
  &:hover
    background-color: $colors.primary-lightest-2 !important
  &:active
    background-color: $colors.primary-lightest-1 !important

.hidden
  display none
</style>

<script>
export default {
  name: 'XTree',
  props: {
    list: {
      type: Array,
      default: () => []
    },
    getList: {
      type: Function,
      require: true
    },
    selected: {
      type: Object
    },
    code: {
      type: String,
      default: 'wb'
    },
    keyId: {
      type: String,
      require: true
    },
    keyName: {
      type: String,
      require: true
    },
    keyHasChildren: {
      type: String,
      require: true
    },
    padding: {
      type: Number,
      default: 8
    },
    child: {
      type: Boolean
    },
    searchValue: {
      type: String
    }
  },
  activated() {
    if (this.list.length) {
      setTimeout(() => {
        const elem = this.list.find(el => el[this.keyId] === this.selected[this.keyId])
        if (elem) this.openParent(this.$parent, elem.external_parent_id)
      }, 10)
    }
  },
  methods: {
    async getCategory(item, index) {
      this.selected[this.keyId] = item[this.keyId]
      this.selected[this.keyName] = item[this.keyName]

      if (!item.sublist && item[this.keyHasChildren]) {
        item.preloader = true
        try {
          const res = await this.getList(this.code, item[this.keyId])
          item.sublist = res?.data?.data || []
        } catch (err) {
          console.log(err)
        } finally {
          item.preloader = false
        }
      }

      if (item[this.keyHasChildren]) this.scrollToSelected()

      if (item.sublist && item.sublist.length) {
        this.list.forEach((el, k) => {
          index === k ? (item.opened = !item.opened) : (el.opened = false)
        })
      }

      this.$forceUpdate()
    },
    openParent(parent, selectedParentId) {
      if (parent.$el.classList.contains('x-main__list')) {
        parent.list.forEach(el => (el.opened = false))
        parent.$forceUpdate()
      }

      if (parent.list) {
        parent.list.forEach(item => {
          if (item[this.keyId] === selectedParentId) {
            parent.list.forEach(el => (el.opened = false))
            item.opened = true
            parent.$forceUpdate()
          }

          if (parent.$parent.list && item.opened) {
            this.openParent(parent.$parent, item.external_parent_id)
          }
        })
      }
    },
    scrollToSelected() {
      setTimeout(() => {
        const container = document.querySelector('.popup__content__container')
        const selectedElement = this.$el.querySelector('.x-category__item__active')
        if (selectedElement) {
          container.scrollTop = selectedElement.offsetTop - selectedElement.offsetHeight - 1
        }
      }, 10)
    }
  }
}
</script>
